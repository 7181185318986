<template>
  <section class="content-reporting set-position-relative">
    <h2>
      {{ td("views.params.reporting.reporting_actions.title.export_actions") }}
    </h2>

    <div class="wrapper-content-row">
      <div class="bloc-content-circle set-position-relative">
        <h3>
          {{ td("views.params.reporting.reporting_actions.period") }}
        </h3>
        <p>
          <b>{{ td("views.params.reporting.reporting_actions.from") }}</b>
          <v-date-picker
            v-model="start_date"
            :min-date="new Date(Date.now() - 1000 * 60 * 60 * 24 * 10000)"
            :popover="{ placement: 'bottom', visibility: 'click' }"
          >
            <span class="pointer">{{ getStartDate() }}</span>
          </v-date-picker>
          <b>{{ td("views.params.reporting.reporting_actions.to") }}</b>
          <v-date-picker
            v-model="end_date"
            :min-date="start_date || new Date()"
            :popover="{ placement: 'bottom', visibility: 'click' }"
          >
            <span class="pointer">{{ getEndDate() }}</span>
          </v-date-picker>
        </p>
      </div>
    </div>
    <div class="bloc-actions-footer">
      <button class="" @click.stop="getActions()">
        {{ td("views.params.reporting.reporting_actions.cta.export") }}
      </button>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { td } from "../../traductions/traductionsHelpers";
//import { sha256 } from "js-sha256";
export default {
  name: "ReportingActions",
  //components: { alertBox, newElement, selectUsers, confirmBox, updateElement,showElement },
  data: () => {
    return {
      td,
      start_date: null,
      end_date: null
    };
  },
  methods: {
    getStartDate() {
      if (this.start_date == null) {
        return "xx/xx/xxx";
      }
      let dt = this.start_date;
      return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    },
    getEndDate() {
      if (this.end_date == null) {
        return "xx/xx/xxx";
      }
      let dt = this.end_date;
      return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    },
    getActions() {
      if (this.start_date == null) {
        this.start_date = new Date(Date.now() - 1000 * 60 * 60 * 24 * 10000);
      }
      if (this.end_date == null) {
        this.end_date = new Date(Date.now() + 1000 * 60 * 60 * 24 * 10000);
      }
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        start_date: this.start_date.getTime(),
        end_date: this.end_date.getTime(),
        circle_token: this.$store.state.userData.circle_token
      };

      if (this.$store.state.userData.admin_level == "group_manager") {
        filters.group_token = this.$store.state.userData.group_token;
      }

      if (this.$store.state.userData.admin_level == "team_manager") {
        filters.team_token = this.$store.state.userData.team_token;
      }

      let sendData = { credentials, filters };

      axios
        .post("/reporting/by_actions", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            var fileURL = window.URL.createObjectURL(
              new Blob([data.response.actions])
            );

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "actions.csv");
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers(byEmail = false) {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        admin_level: "group_manager"
      };
      if (this.$store.state.userData.admin_level == "team_manager") {
        filters.admin_level = "team_manager";
      }
      if (byEmail) {
        filters.email = this.$store.state.userData.email;
      }

      let sendData = { credentials, filters };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            let key = "group_name";
            if (context.user.admin_level == "team_manager") {
              key = "team_name";
            }
            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name =
                obj.firstname + " " + obj.lastname + " - " + obj[key];
              obj.key = Math.random();
              return newObj;
            });

            /*let reduced = Array.from(
              new Set(scratchArray.map((a) => a.email))
            ).map((email) => {
              return scratchArray.find((a) => a.email === email);
            });*/

            context.new_user_options = scratchArray;

            /*setTimeout(() => {
              context.getChallenges();
            }, 150);*/
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  }
};
</script>
