<template>
  <div class="wrapper-content-column">
    <div class="wrapper-content labels">
      <div class="column-notifications">
        <h2>{{ td("views.params.params_labels.by_default") }}</h2>
        <p v-for="(item, index) in labels" :key="index">{{ item.init }}</p>
      </div>
      <div class="column-notifications">
        <h2>fr</h2>
        <input v-for="(item, index) in labels" :key="index" v-model="item.fr" />
      </div>
      <div class="column-notifications">
        <h2>en</h2>
        <input v-for="(item, index) in labels" :key="index" v-model="item.en" />
      </div>
      <div class="column-notifications">
        <h2>nl</h2>
        <input v-for="(item, index) in labels" :key="index" v-model="item.nl" />
      </div>
    </div>
    <div class="notifications-footer">
      <button class="" @click.stop="valideHandler">
        {{ td("views.params.params_labels.cta.valide") }}
      </button>
      <button class="negatif" @click.stop="cancelHandler">
        {{ td("views.params.params_labels.cta.cancel") }}
      </button>
    </div>
    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "ParamsLabels",
  components: { alertBox },
  data: () => {
    return {
      //alert box
      td,
      show_alert_box: false,
      msg_alert: td("views.params.params_labels.msg_alert"),
      alert_delay: 3000,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      labels: {},
      init_labels: {}
    };
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    valideHandler() {
      this.saveLabels();
    },
    cancelHandler() {
      this.labels = JSON.parse(JSON.stringify(this.circle.params.labels));
    },
    saveLabels() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      this.circle.params.labels = this.labels;

      this.$store.commit("circleData", this.circle);

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = { credentials, labels: this.labels, filters };

      axios
        .post("/circles/update/labels", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.show_alert_box = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    this.labels = JSON.parse(JSON.stringify(this.circle.params.labels));

    //this.init_labels = JSON.parse(JSON.stringify(this.circle.params.labels));
  }
};
</script>
