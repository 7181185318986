var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content content-column ceo-column"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("components.group.sales_column.title.sales"))+" "),_c('span',{staticClass:"plus",on:{"click":function($event){$event.stopPropagation();return _vm.clickHandler({ type: 'click-plus' })}}},[_vm._v("+")])]),_c('div',{staticClass:"body-content"},_vm._l((_vm.users),function(item,index){return _c('div',{key:index,staticClass:"user-item",on:{"click":function($event){$event.stopPropagation();return _vm.clickItemHandler(item)}}},[_c('div',{class:[
          'container-avatar-body',
          {
            'container-avatar-body-clicked':
              item.user_token == _vm.clicked_user_token
          }
        ]},[_c('div',{staticClass:"col-avatar"},[_c('img',{attrs:{"src":_vm.getProfilPicture(item),"alt":""}})]),_c('div',{staticClass:"col-body"},[_c('h2',[_vm._v(_vm._s(item.firstname + " " + item.lastname))]),_c('h3',[_vm._v(_vm._s(item.functions[0]))]),_c('h4',[_vm._v(_vm._s(item.team_name))])])]),_c('div',{staticClass:"col-buttons"},[_c('div',{class:['pen', { empty: !_vm.getIsPen(item) }],on:{"click":function($event){$event.stopPropagation();return _vm.editUser(item)}}}),_c('div',{class:['cross', { empty: !_vm.getIsCross(item) }],on:{"click":function($event){return _vm.deleteUser(item)}}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }