<template>
  <div class="wrapper-content-column catalogue">
    <section
      class="large-content top-bar"
      v-if="$store.getters.adminValue >= 2"
    >
      <div class="large-content-column col-gauche">
        <h2>{{ td("views.catalog.catalog_team.select_team") }}</h2>
      </div>
      <div class="large-content-column col-3">
        <div class="bloc-search">
          <h2>{{ td("views.catalog.catalog_team.search") }}</h2>
          <div class="bottom-line">
            <v-select
              label="name"
              v-if="editable == 'search'"
              :placeholder="td('global.topcomponent.add_name')"
              :options="new_user_options"
              :components="{ OpenIndicator }"
              v-model="new_user_selected"
              @input="
                editable = '';
                setNewUserFromVSelect();
              "
            >
              <div slot="no-options">
                {{ td("views.catalog.catalog_team.sorry_but") }}
              </div>
            </v-select>

            <p
              class="hoverable"
              :data-hover="td('global.topcomponent.add_name')"
              v-else
              @click="editable = 'search'"
            >
              {{ getSelectedUserName() }}
            </p>
            <img src="/assets/img/loupe.png" alt="" />
          </div>
        </div>
      </div>
      <div class="user-item" v-if="new_user_selected.user_token">
        <div class="container-avatar-body">
          <div class="col-avatar">
            <img :src="getUserLogo(new_user_selected)" alt="" id="user_image" />
          </div>
          <div class="col-body">
            <h2>
              {{
                new_user_selected.firstname + " " + new_user_selected.lastname
              }}
            </h2>
            <h3>{{ new_user_selected.functions[0] }}</h3>
            <h4>{{ new_user_selected.team_name }}</h4>
          </div>
        </div>
        <div class="col-buttons"></div>
      </div>
    </section>

    <div class="wrapper-content-row">
      <div class="column-items" v-if="new_user_selected.user_token">
        <h2>
          {{ td("views.catalog.catalog_team.elements")
          }}{{ new_user_selected.team_name }}
          <span class="plus" @click.stop="clickHandler({ type: 'click-plus' })"
            >+</span
          >
        </h2>
        <div
          class="wrapper-content-row element-item"
          v-for="(item, index) in elements"
          :key="index"
          @click.stop="showItem(item)"
        >
          <div class="wrapper-content-column element-s">
            <img
              src="/assets/img/action_s.png"
              alt=""
              v-if="item.undeclarative"
            />
          </div>
          <div class="wrapper-content-column bonus">
            <h2>{{ item.name }}</h2>
            <p class="pts">{{ item.points }} pts</p>
            <p class="stars">{{ item.stars }} étoiles</p>
          </div>
          <div class="wrapper-content-column comment">
            <p v-if="item.comment != ''">
              {{ item.comment }}
            </p>
            <span v-if="item.comment != ''" @click.stop="showComment(item)"
              >+</span
            >
          </div>
          <div class="wrapper-content-column buttons">
            <span
              :class="['pen', { empty: isEmptyPen(item) }]"
              @click.stop="updateItem(item)"
            ></span>
            <span
              :class="['cross', { empty: isEmptyCross(item) }]"
              @click.stop="deleteItem(item)"
            ></span>
          </div>
          <div class="avatar" v-if="isEmptyPen(item)">
            <img :src="getItemAvatar(item)" />
          </div>
        </div>
        <div
          class="transparent-lightbox-bg"
          v-if="
            plus_cliqued == true ||
              update_cliqued == true ||
              show_cliqued == true
          "
        ></div>
        <div
          class="transparent-lightbox-bg"
          v-if="comment_cliqued != null"
        ></div>
        <div
          class="wrapper-content-column show-comment"
          v-if="comment_cliqued != null"
        >
          <p>
            {{ comment_cliqued.comment }}
          </p>
          <button @click.stop="comment_cliqued = null">ok</button>
        </div>
      </div>
      <newElement
        :callback="newElementCallback"
        :level="'team_manager'"
        :group_token="getGroupToken()"
        :team_token="getTeamToken()"
        :creator_token="getCreatorToken()"
        :selectedUsers="selectedUsers"
        v-if="plus_cliqued == true"
      />
      <updateElement
        :callback="newElementCallback"
        :level="'team_manager'"
        :group_token="getGroupToken()"
        :team_token="getTeamToken()"
        :selectedUsers="selectedUsers"
        :elementParams="item_to_update"
        v-if="update_cliqued == true"
      />
      <showElement
        :callback="newElementCallback"
        :level="'team_manager'"
        :selectedUsers="selectedUsers"
        :elementParams="item_to_update"
        v-if="show_cliqued == true"
      />
    </div>

    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
    <transition name="fade">
      <div class="container-alert" v-if="show_confirm_box === true">
        <confirmBox
          :msg1="msg_confirm1"
          :msg2="msg_confirm2"
          :title="title_confirm"
          :callback="confirmCallback"
          :delay="confirm_delay"
        />
      </div>
    </transition>
    <div
      class="transparent-lightbox-bg"
      v-if="element_plus_cliqued == true"
    ></div>
    <selectUsers
      :callback="selectedUsersCallback"
      :level="'team_manager'"
      :group_token="getGroupToken()"
      :team_token="getTeamToken()"
      :old_selected_users="selectedUsers"
      v-if="element_plus_cliqued == true"
    />
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import confirmBox from "./../../components/alertBoxes/confBox";
import newElement from "./../../components/catalogue/newElement";
import updateElement from "./../../components/catalogue/updateElement";
import selectUsers from "./../../components/catalogue/selectUsers";
import showElement from "./../../components/catalogue/showElement";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "CatalogueCeo",
  components: {
    alertBox,
    newElement,
    selectUsers,
    confirmBox,
    updateElement,
    showElement
  },
  data: () => {
    return {
      td,
      comment_cliqued: null,
      plus_cliqued: false,
      update_cliqued: false,
      element_plus_cliqued: false,
      item_to_delete: null,
      show_cliqued: false,
      //alert box
      show_alert_box: false,
      msg_alert: td("views.catalog.catalog_team.msg_alert"),
      alert_delay: 3000,
      //confirm box
      show_confirm_box: false,
      msg_confirm1: td("views.catalog.catalog_team.msg_confirm1"),
      msg_confirm2: td("views.catalog.catalog_team.msg_confirm2"),
      title_confirm: td("views.catalog.catalog_team.title_confirm"),
      confirm_delay: 0,
      //
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      selectedUsers: [],
      new_user_options: [],
      new_user_selected: {},
      new_user_init: {
        name: td("views.catalog.catalog_team.new_user_init.name"),
        firstname: td("views.catalog.catalog_team.new_user_init.firstname"),
        lastname: td("views.catalog.catalog_team.new_user_init.lastname"),
        email: td("views.catalog.catalog_team.new_user_init.email"),
        confirm_email: td(
          "views.catalog.catalog_team.new_user_init.confirm_email"
        ),
        function: td("views.catalog.catalog_team.new_user_init.function"),
        phone: td("views.catalog.catalog_team.new_user_init.phone"),
        gender: td("views.catalog.catalog_team.new_user_init.gender"),
        language: td("views.catalog.catalog_team.new_user_init.language")
      },
      editable: "",
      elements: [],
      item_to_update: null,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          elem.id = "toto";
          return elem;
        }
      }
    };
  },
  methods: {
    getGroupToken() {
      if (this.new_user.group_token) {
        return this.new_user.group_token;
      }
      if (this.user.group_token) {
        return this.user.group_token;
      }
      return "";
    },
    getTeamToken() {
      if (this.new_user.team_token) {
        return this.new_user.team_token;
      }

      if (this.user.team_token) {
        return this.user.team_token;
      }
      return "";
    },
    getCreatorToken() {
      if (this.new_user.user_token) {
        return this.new_user.user_token;
      }

      if (this.user.user_token) {
        return this.user.user_token;
      }
      return "";
    },
    isGroupManager() {
      if (this.$store.getters.adminValue == 4) {
        return true;
      }
      return false;
    },
    getItemAvatar(item) {
      if (item.owner_data.picture) {
        if (item.owner_data.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + item.owner_data.picture;
      }
      return "/assets/img/profil2.png";
    },
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "click-valider") {
        this.doDeleteItem(this.item_to_delete);
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
    },
    showItem(item) {
      this.selectedUsers = item.selected_users;
      this.item_to_update = item;
      this.show_cliqued = true;
    },
    deleteItem(item) {
      if (this.isEmptyCross(item)) {
        return;
      }
      this.item_to_delete = item;
      this.show_confirm_box = true;
    },
    updateItem(item) {
      if (this.isEmptyPen(item)) {
        return;
      }
      this.selectedUsers = item.selected_users;
      this.item_to_update = item;
      this.update_cliqued = true;
    },
    showComment(item) {
      this.comment_cliqued = item;
    },
    isEmptyCross(item) {
      if (this.user.user_token != item.owner_token) {
        return true;
      }
      if (item.level != "team_manager") {
        return true;
      }
      return false;
    },
    isEmptyPen(item) {
      if (this.user.user_token != item.owner_token) {
        return true;
      }
      if (item.level != "team_manager") {
        return true;
      }
      return false;
    },
    selectedUsersCallback(obj) {
      if (obj.type == "click-valider") {
        /*if (obj.users.length) {
          for (let i = 0; i < obj.users.length; i++) {
            for (let j = 0; j < 10; j++) {
              this.selectedUsers.push(obj.users[i]);
            }
          }
        }*/
        this.selectedUsers = obj.users;
      } else {
        //this.selectedUsers = [];
      }
      this.element_plus_cliqued = false;
    },
    newElementCallback(obj) {
      this.show_cliqued = false;

      if (obj.type == "click-plus") {
        this.element_plus_cliqued = true;
        return;
      }
      if (obj.type == "cancel") {
        this.plus_cliqued = false;
        this.update_cliqued = false;
        return;
      }
      if (obj.type == "element-saved") {
        this.element_plus_cliqued = false;
        this.plus_cliqued = false;
        this.update_cliqued = false;
        this.selectedUsers = [];
        this.getElements();
        return;
      }
    },
    clickHandler(obj) {
      if (obj.type == "click-plus") {
        this.selectedUsers = [];
        this.plus_cliqued = true;
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      /*var output = document.getElementById("user_image");
      output.src = this.getUserLogo();*/
      this.getElements();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return this.td("global.topcomponent.add_name");
      }
      if (!this.new_user.user_token) {
        return this.td("global.topcomponent.add_name");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return this.td("global.topcomponent.add_name");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    getElements() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {};
      let url = "";

      if (this.user.admin_level == "team_manager") {
        filters = {
          user_tokens: [this.new_user.user_token],
          user_token: this.new_user.user_token,
          team_token: this.new_user.team_token,
          level: "team_manager",
          circle_token: this.circle.circle_token,
          user: this.user,
          with_owner_data: true
        };
        //url = "/elements/by";
        url = "/elements/of";
      } else if (this.user.admin_level == "group_manager") {
        filters = {
          user_tokens: [this.user.user_token],
          user_token: this.new_user.user_token,
          team_token: this.new_user.team_token,
          level: "team_manager",
          circle_token: this.circle.circle_token,
          with_owner_data: true
        };
        //url = "/elements/by";
        url = "/elements/of";
      } else {
        //ceo manager
        filters = {
          user_tokens: [this.new_user.user_token, this.user.user_token],
          user_token: this.new_user.user_token,
          team_token: this.new_user.team_token,
          level: "team_manager",
          circle_token: this.circle.circle_token,
          with_owner_data: true
        };
        url = "/elements/of";
      }

      let sendData = { credentials, filters };

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            context.elements = data.response.elements;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    doDeleteItem(item) {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        owner_token: this.user.user_token,
        element_token: item.element_token
      };

      let sendData = { credentials, filters };

      axios
        .post("/elements/delete", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            setTimeout(() => {
              context.getElements();
            }, 100);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {};

      let url = "/users/by";

      if (this.user.admin_level == "ceo_manager") {
        filters = {
          circle_token: this.circle.circle_token,
          admin_level: "team_manager"
        };
      }

      if (this.user.admin_level == "group_manager") {
        filters = {
          circle_token: this.circle.circle_token,
          //je cherche les users appartenant à cet email (en dessous de lui)
          email: this.user.email,
          //le user parent est un group_manager, on cherche dans tous ses groupes, pas uniquement celui dans lequel il est connecté
          user_admin_level: "group_manager",
          //on cherche tous ses team_managers
          target_admin_level: "team_manager",
          //on les recherche par leur group_token
          find_by_key_token: "group_token"
        };
        url = "/users/of";
      }

      if (this.user.admin_level == "team_manager") {
        filters = {
          circle_token: this.circle.circle_token,
          email: this.user.email,
          admin_level: "team_manager"
        };
      }

      let sendData = { credentials, filters };

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name =
                obj.firstname + " " + obj.lastname + " - " + obj.team_name;
              return newObj;
            });

            /*let reduced = Array.from(
              new Set(scratchArray.map((a) => a.email))
            ).map((email) => {
              return scratchArray.find((a) => a.email === email);
            });*/

            context.new_user_options = scratchArray;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    if (this.user.admin_level == "team_manager") {
      //this.new_user_selected = JSON.parse(JSON.stringify(this.user));
      this.getUsers();
      //this.getElements();
      return;
    }

    axios;
    sha256;

    //
    this.getUsers();
  }
};
</script>
ript>
