<template>
  <main>
    <div class="wrapper circles-like edit-user">
      <section class="large-content circle-body ">
        <div class="large-content-column">
          <div class="body-content set-position-relative">
            <div class="bloc-content-profil edit-user-image ">
              <img
                :src="getUserLogo()"
                alt=""
                class="header-img-profil"
                id="user_image"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
            <div class="bloc-content-profil">
              <h2>{{ new_user.firstname + " " + new_user.lastname }}</h2>
              <h3>{{ new_user.function }}</h3>
              <p>{{ new_user.team_name }}</p>
              <p>{{ new_user.maxime }}</p>
            </div>
            <div class="bloc-content-profil moyenne">
              <h2>{{ td("views.teams.edit_user_tl.average_company") }}</h2>
              <h3>
                <span class="pctage">0%</span
                >{{ td("views.teams.edit_user_tl.average_company_goal") }}
              </h3>
            </div>
            <div :class="{ 'overlay-block': new_user.user_token }"></div>
          </div>
          <div class="footer-content">
            <button
              class=""
              @click="updateTeam"
              style="width:165px;height:42px"
            >
              {{ td("views.teams.edit_user_tl.cta.valide") }}
            </button>
            <button
              class="negatif"
              @click="cancelHandler"
              style="width:105px;height:42px"
            >
              {{ td("views.teams.edit_user_tl.cta.cancel") }}
            </button>
          </div>
        </div>
        <div class="large-content-column col-3  set-position-relative">
          <div class="wrapper">
            <div class="edit-user-bottom">
              <div class="column-content-circle">
                <div class="bloc-content-circle">
                  <h3>Civilité</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.edit_user_tl.gender.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="new_user.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.edit_user_tl.gender.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="new_user.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.name") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="new_user.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_tl.name.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ new_user.lastname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.email") }}</h3>
                  <p
                    class=""
                    :data-hover="
                      td('views.teams.edit_user_tl.email.placeholder')
                    "
                  >
                    {{ new_user.email }}
                  </p>
                </div>
                <div class="bloc-content-circle for-drop-down">
                  <h3>
                    {{ td("views.teams.edit_user_tl.objectif_period") }}
                  </h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_objectifs_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_objectifs_clicked = !drop_down_objectifs_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        new_objectifs_selected.name ||
                          td("views.teams.edit_user_tl.period")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_objectifs_clicked = !drop_down_objectifs_clicked
                      "
                    >
                      {{
                        new_objectifs_selected.name ||
                          td("views.teams.edit_user_tl.period")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in new_objectifs_options"
                      :key="key"
                      @click="
                        drop_down_objectifs_clicked = false;
                        new_objectifs_selected = { ...option };
                        setPreviousContent('');
                      "
                    >
                      {{ option.name }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="column-content-circle">
                <div
                  class="bloc-content-circle"
                  v-if="$store.getters.adminValue >= 4"
                >
                  <h3>{{ td("views.teams.edit_user_tl.team_name") }}</h3>
                  <input
                    v-if="editable == 'teamname'"
                    ref="teamname"
                    type="text"
                    v-model="new_user.team_name"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('teamname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_tl.team_name.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('teamname');
                      editable = 'teamname';
                    "
                  >
                    {{ new_user.team_name }}
                  </p>
                </div>
                <div class="bloc-content-circle" v-else>
                  <h3>{{ td("views.teams.edit_user_tl.team_name") }}</h3>

                  <p class="">
                    {{ new_user.team_name }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="new_user.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_tl.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ new_user.firstname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="new_user.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_tl.phone.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ new_user.phone }}
                  </p>
                </div>
                <div class="bloc-content-circle" />
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user_tl.language")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user_tl.language")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        new_user.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.function") }}</h3>
                  <input
                    v-if="editable == 'function'"
                    ref="function"
                    type="text"
                    v-model="new_user.function"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('function');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_tl.function.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('function');
                      editable = 'function';
                    "
                  >
                    {{ new_user.function }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_tl.profiles") }}</h3>
                  <p class="profil-level" v-if="profils.ceo_manager.length">
                    {{ td("views.teams.edit_user_tl.ceos") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.ceo_manager"
                    :key="key + profil.circle_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.circle_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.group_manager.length">
                    {{ td("views.teams.edit_user_tl.resps") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.group_manager"
                    :key="key + profil.group_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.group_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.team_manager.length">
                    {{ td("views.teams.edit_user_tl.tls") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.team_manager"
                    :key="key + profil.team_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.sale.length">
                    {{ td("views.teams.edit_user_tl.sales") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.sale"
                    :key="key + profil.team_name + '_'"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                </div>
                <div class="bloc-content-circle" />
              </div>
            </div>
          </div>
          <!--<div :class="{ 'overlay-block': new_user.user_token }"></div>-->
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import alertBox from "./../../components/alertBoxes/alertBox";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { sha256 } from "js-sha256";

// eslint-disable-next-line no-unused-vars
import { valideUser } from "./../../assets/scripts/users";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "EditUserTeamManager",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      team: {},
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      new_users_options: [],
      new_user_selected: {},
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {}, //le user qui est connecté, pas celui que l'on crée
      profils: {
        ceo_manager: [],
        group_manager: [],
        team_manager: [],
        sale: []
      },
      new_user_init: {
        name: td("views.teams.edit_user_tl.new_user_init.name"),
        firstname: td("views.teams.edit_user_tl.new_user_init.firstname"),
        lastname: td("views.teams.edit_user_tl.new_user_init.lastname"),
        email: td("views.teams.edit_user_tl.new_user_init.email"),
        confirm_email: td(
          "views.teams.edit_user_tl.new_user_init.confirm_email"
        ),
        function: td("views.teams.edit_user_tl.new_user_init.function"),
        phone: td("views.teams.edit_user_tl.new_user_init.phone"),
        gender: td("views.teams.edit_user_tl.new_user_init.gender"),
        language: td("views.teams.edit_user_tl.new_user_init.language")
      },
      new_user: {},
      admin_level: "",
      previousContent: {
        new_user: {}
      },
      //objectifs
      drop_down_objectifs_clicked: false,
      new_objectifs_options: [],
      new_objectifs_selected: {}
    };
  },
  methods: {
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    cancelHandler() {
      this.$router.go("-1");
    },
    setPreviousContent(key) {
      if (this.new_user.user_token) {
        // this.cancelHandler();
        // return;
      }
      this.previousContent.new_user[key] = this.new_user[key];
      if (this.new_user[key] == this.new_user_init[key]) {
        this.new_user[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_user[key].trim() == "") {
        this.new_user[key] = this.previousContent.new_user[key];
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("user_image");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return td("views.teams.edit_user_tl.get_selected_username");
      }
      if (!this.new_user.user_token) {
        return td("views.teams.edit_user_tl.get_selected_username");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return td("views.teams.edit_user_tl.get_selected_username");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },

    getTeam() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        team_token: this.team_token,
        circle_token: this.$store.state.circleToken
      };
      let sendData = { credentials, filters };

      axios
        .post("/teams/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /teams/by ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            /*let scratchArray = data.response.users.map((obj) => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });*/

            let team = data.response.teams[0];

            /*let reduced = Array.from(
              new Set(groups.map((a) => a.group_token))
            ).map((group_token) => {
              return groups.find((a) => a.group_token === group_token);
            });*/

            context.team = team;
            switch (team.objectifs_period) {
              case "year":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.year"),
                  value: "year"
                };
                break;
              case "sem":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.semester"),
                  value: "sem"
                };
                break;
              case "quadri":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.quadri"),
                  value: "quadri"
                };
                break;
              case "trim":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.trim"),
                  value: "trim"
                };
                break;
              case "month":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.month"),
                  value: "month"
                };
                break;
              case "week":
                context.new_objectifs_selected = {
                  name: td("views.teams.edit_user_tl.week"),
                  value: "week"
                };
                break;

              default:
                break;
            }
            store.commit("authUser", authData);
            context.getUsers();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        user_token: this.$route.query.ut,
        circle_token: this.$store.state.circleToken
      };
      let options = { profils: 1 };
      let sendData = { credentials, filters, options };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /users/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });

            context.new_user = scratchArray[0];

            let prfls = context.new_user.profils;
            for (let i = 0; i < prfls.length; i++) {
              if (prfls[i].admin_level == "ceo_manager") {
                context.profils.ceo_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "group_manager") {
                context.profils.group_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "team_manager") {
                context.profils.team_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "sale") {
                context.profils.sale.push(prfls[i]);
              }
            }

            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    updateTeam() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      sha256;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      this.team.objectifs_period = this.new_objectifs_selected.value;
      this.team.name = this.new_user.team_name;

      let sendData = { credentials };
      let team = { ...this.team };

      sendData.team = team;

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/teams/update", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            /*context.msg_alert = "Le profil a été modifié";
            context.show_alert_box = true;*/

            store.dispatch("refreshTokenId", authData);

            setTimeout(() => {
              context.updateProfil();
            }, 75);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.msg_alert = td("views.teams.edit_user_tl.msg_alert2");
            context.show_alert_box = true;
          }
        })
        .catch(function(error) {
          error;
        });
    },
    updateProfil() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      sha256;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };
      let user = { ...this.new_user };

      user.functions[0] = user.function;

      sendData.user = user;

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/users/update/different_user", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.msg_alert = td("views.teams.edit_user_tl.msg_alert1");
            context.show_alert_box = true;

            store.dispatch("refreshTokenId", authData);
            setTimeout(() => {
              context.$router.go(-1);
            }, 3100);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.msg_alert = td("views.teams.edit_user_tl.msg_alert2");
            context.show_alert_box = true;
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    this.team_token = this.$route.query.team_token;
    this.group_token = this.$route.query.group_token;

    let objs = [];
    let periods = this.$store.state.circleData.params.periods;

    for (let i = 0; i < periods.length; i++) {
      if (periods[i] == "week") {
        objs.push({
          name: td("views.teams.edit_user_tl.week"),
          value: "week"
        });
      }
      if (periods[i] == "month") {
        objs.push({
          name: td("views.teams.edit_user_tl.month"),
          value: "month"
        });
      }
      if (periods[i] == "trim") {
        objs.push({
          name: td("views.teams.edit_user_tl.trim"),
          value: "trim"
        });
      }
      if (periods[i] == "quadri") {
        objs.push({
          name: td("views.teams.edit_user_tl.quadri"),
          value: "quadri"
        });
      }
      if (periods[i] == "sem") {
        objs.push({
          name: td("views.teams.edit_user_tl.semester"),
          value: "sem"
        });
      }
      if (periods[i] == "year") {
        objs.push({
          name: td("views.teams.edit_user_tl.year"),
          value: "year"
        });
      }
    }
    this.new_objectifs_options = objs;

    //this.getUsers();
    this.getTeam();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
