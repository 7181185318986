<template>
  <div class="body">
    <headerComponent :callback="headerCallbackHandler" />
    <main>
      <div class="wrapper account">
        <section class="large-content">
          <div class="large-content-column">
            <div class="body-content">
              <div class="bloc-content-profil">
                <img :src="getUserPicture()" alt="" class="header-img-profil" />
                <img
                  src="/assets/img/change_img_profil_overlay.png"
                  alt=""
                  class="header-img-profil overlay"
                  @click.prevent="changePhotoHandler"
                />
                <input
                  style="display:none"
                  type="file"
                  name="file_01"
                  id="file_01"
                  ref="file_01"
                  :accept="files_allowed"
                  class="form-control fa-file-photo-o "
                  @change="changeFileUplaod('file_01')"
                />
              </div>
              <div class="bloc-content-profil">
                <h2>{{ user.firstname + " " + user.lastname }}</h2>
                <h3>{{ user.functions[0] }}</h3>
                <p>{{ user.circle_name }}</p>
                <p>{{ user.maxime }}</p>
              </div>
            </div>
            <div class="footer-content">
              <button
                :class="{ karamba: $store.state.circleToken == '' }"
                style="width:260px;height:42px"
                @click="updateProfil"
              >
                {{ td("views.account.cta.valide") }}
              </button>
            </div>
          </div>
          <div class="large-content-column col-2">
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.gender") }}</h3>
              <div class="container-radio">
                <label class="container"
                  >{{ td("views.account.gender.m") }}
                  <input
                    type="radio"
                    id="m"
                    value="m"
                    v-model="user.gender"
                    name="m"
                  />
                  <span
                    :class="[
                      'checkmark',
                      { karamba: $store.state.circleToken == '' }
                    ]"
                  ></span>
                </label>
                <label class="container"
                  >{{ td("views.account.gender.f") }}
                  <input
                    type="radio"
                    id="f"
                    value="f"
                    v-model="user.gender"
                    name="f"
                  />
                  <span
                    :class="[
                      'checkmark',
                      { karamba: $store.state.circleToken == '' }
                    ]"
                  ></span>
                </label>
              </div>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.name") }}</h3>
              <input
                v-if="editable == 'lastname'"
                type="text"
                v-model="user.lastname"
                class="p"
                @blur="
                  editable = '';
                  testEmptyContent('lastname');
                "
              />
              <p
                class="hoverable"
                :data-hover="td('views.account.name.placeholder')"
                v-else
                @click="
                  setPreviousContent('lastname');
                  editable = 'lastname';
                "
              >
                {{ user.lastname }}
              </p>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.email") }}</h3>
              <p>{{ user.email }}</p>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.maxime") }}</h3>
              <input
                v-if="editable == 'maxime'"
                type="text"
                v-model="user.maxime"
                class="p"
                @blur="
                  editable = '';
                  testEmptyContent('maxime');
                "
              />
              <p
                class="hoverable"
                :data-hover="td('views.account.maxime.placeholder')"
                v-else
                @click="
                  setPreviousContent('maxime');
                  editable = 'maxime';
                "
              >
                {{ user.maxime }}
              </p>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.password") }}</h3>
              <p
                class="modify-password"
                @click="modify_password = !modify_password"
              >
                {{ td("views.account.change_password") }}
              </p>
            </div>
          </div>
          <div class="large-content-column col-2">
            <div class="bloc-content-profil"></div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.firstname") }}</h3>
              <input
                v-if="editable == 'firstname'"
                type="text"
                v-model="user.firstname"
                class="p"
                @blur="
                  editable = '';
                  testEmptyContent('firstname');
                "
              />
              <p
                class="hoverable"
                :data-hover="td('views.account.firstname.placeholder')"
                v-else
                @click="
                  setPreviousContent('firstname');
                  editable = 'firstname';
                "
              >
                {{ user.firstname }}
              </p>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.phone") }}</h3>
              <input
                v-if="editable == 'phone'"
                type="tel"
                v-model="user.phone"
                class="p"
                @blur="
                  editable = '';
                  testEmptyContent('phone');
                "
              />
              <p
                class="hoverable"
                :data-hover="td('views.account.phone.placeholder')"
                v-else
                @click="
                  setPreviousContent('phone');
                  editable = 'phone';
                "
              >
                {{ user.phone }}
              </p>
            </div>

            <div class="bloc-content-profil"></div>
            <div class="bloc-content-profil"></div>
          </div>
          <div class="large-content-column col-2">
            <div class="bloc-content-profil  for-drop-down">
              <div class="drop-down closed" v-if="drop_down_clicked == false">
                <h3
                  class="pointer"
                  @click="
                    drop_down_clicked = !drop_down_clicked;
                    setPreviousContent('');
                  "
                >
                  {{
                    languages_options[user.language] ||
                      td("views.account.language")
                  }}<span
                    ><img src="/assets/img/arrow_down_black.png" alt="" />
                  </span>
                </h3>
              </div>
              <div class="drop-down opened" v-else>
                <h3
                  class="pointer"
                  @click="drop_down_clicked = !drop_down_clicked"
                >
                  {{
                    languages_options[user.language] ||
                      td("views.account.language")
                  }}<span>
                    <img src="/assets/img/arrow_up_black.png" alt="" />
                  </span>
                </h3>
                <h3
                  v-for="(value, key) in languages_options"
                  :key="key"
                  @click="
                    drop_down_clicked = false;
                    user.language = key;
                    setPreviousContent('');
                  "
                >
                  {{ value }}
                </h3>
              </div>
            </div>
            <div class="bloc-content-profil">
              <h3>{{ td("views.account.function") }}</h3>
              <input
                v-if="editable == 'functions[0]'"
                type="text"
                v-model="user.functions[0]"
                class="p"
                @blur="
                  editable = '';
                  testEmptyContent('function');
                "
              />
              <p
                class="hoverable"
                :data-hover="td('views.account.function.placeholder')"
                v-else
                @click="
                  setPreviousContent('function');
                  editable = 'functions[0]';
                "
              >
                {{ user.functions[0] }}
              </p>
              <p>{{ previousContent.user.functions[0] }}</p>
            </div>
            <div class="bloc-content-circle profil-list">
              <h3>{{ td("views.account.profils") }}</h3>
              <p class="profil-level" v-if="profils.ceo_manager.length">
                {{ td("views.account.ceos") }}
              </p>
              <div
                v-for="(profil, key) in profils.ceo_manager"
                :key="key + profil.circle_name"
              >
                <p class="profil-level-name">
                  {{ profil.circle_name }}
                </p>
              </div>
              <p class="profil-level" v-if="profils.group_manager.length">
                {{ td("views.account.resps") }}
              </p>
              <div
                v-for="(profil, key) in profils.group_manager"
                :key="key + profil.group_name"
              >
                <p class="profil-level-name">
                  {{ profil.group_name }}
                </p>
              </div>
              <p class="profil-level" v-if="profils.team_manager.length">
                {{ td("views.account.tls") }}
              </p>
              <div
                v-for="(profil, key) in profils.team_manager"
                :key="key + profil.team_name"
              >
                <p class="profil-level-name">
                  {{ profil.team_name }}
                </p>
              </div>
              <p class="profil-level" v-if="profils.sale.length">
                {{ td("views.account.sales") }}
              </p>
              <div
                v-for="(profil, key) in profils.sale"
                :key="key + profil.team_name + '_'"
              >
                <p class="profil-level-name">
                  {{ profil.team_name }}
                </p>
              </div>
            </div>

            <div class="bloc-content-profil"></div>
          </div>

          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>

          <div
            class="container-change-password"
            v-if="modify_password === true"
          >
            <div class="transparent-lightbox-bg"></div>
            <div class="bloc-change-password">
              <h2>{{ td("views.account.modify_password") }}</h2>
              <div class="body-password">
                <div class="bloc-form">
                  <label
                    :class="{ error: empty_old_password }"
                    for="password"
                    >{{ td("views.account.current_password") }}</label
                  >
                  <div class="patern">
                    <input
                      :type="getIsPassword()"
                      class="input-text"
                      id="password"
                      placeholder="***********"
                      required=""
                      v-model="old_password"
                      @input="empty_old_password = false"
                    />
                    <span
                      class="patern-see"
                      @click.prevent="is_password_type = !is_password_type"
                      >{{ td("views.account.show") }}</span
                    >
                  </div>
                  <div class="lost-password">
                    {{ td("views.account.forgot_password") }}
                  </div>
                </div>
                <div class="bloc-form">
                  <label class="" for="password">{{
                    td("views.account.new_password")
                  }}</label>
                  <div class="patern">
                    <input
                      :type="getNewIsPassword()"
                      class="input-text"
                      id="password"
                      placeholder="***********"
                      required=""
                      v-model="new_password"
                      @input="testOnChange"
                    />
                    <span
                      class="patern-see"
                      @click.prevent="
                        new_password_is_password_type = !new_password_is_password_type
                      "
                      >VOIR</span
                    >
                  </div>
                </div>
                <div class="line-validate">
                  <img
                    src="/assets/img/validate-grey.png"
                    v-if="eight_caract == 'grey'"
                  />
                  <img
                    src="/assets/img/validate-green.png"
                    v-else-if="eight_caract == 'green'"
                  />
                  <img
                    src="/assets/img/validate-red.png"
                    v-else-if="eight_caract == 'red'"
                  />
                  <p>{{ td("views.account.min_height") }}</p>
                </div>
                <div class="line-validate">
                  <img
                    src="/assets/img/validate-grey.png"
                    v-if="lower_caract == 'grey'"
                  />
                  <img
                    src="/assets/img/validate-green.png"
                    v-else-if="lower_caract == 'green'"
                  />
                  <img
                    src="/assets/img/validate-red.png"
                    v-else-if="lower_caract == 'red'"
                  />
                  <p>{{ td("views.account.lowercase_mandatory") }}</p>
                </div>
                <div class="line-validate">
                  <img
                    src="/assets/img/validate-grey.png"
                    v-if="upper_caract == 'grey'"
                  />
                  <img
                    src="/assets/img/validate-green.png"
                    v-else-if="upper_caract == 'green'"
                  />
                  <img
                    src="/assets/img/validate-red.png"
                    v-else-if="upper_caract == 'red'"
                  />
                  <p>{{ td("views.account.uppercase_mandatory") }}</p>
                </div>
                <div class="line-validate">
                  <img
                    src="/assets/img/validate-grey.png"
                    v-if="number_caract == 'grey'"
                  />
                  <img
                    src="/assets/img/validate-green.png"
                    v-else-if="number_caract == 'green'"
                  />
                  <img
                    src="/assets/img/validate-red.png"
                    v-else-if="number_caract == 'red'"
                  />
                  <p>{{ td("views.account.number_mandatory") }}</p>
                </div>
              </div>
              <div class="line-buttons">
                <button
                  class=""
                  style="width:190px;height:42px"
                  @click="sendPassword"
                >
                  {{ td("views.account.cta.valide") }}
                </button>
                <button
                  class="negatif"
                  style="width:130px;height:42px"
                  @click="modify_password = false"
                >
                  {{ td("views.account.cta.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unreachable */
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import headerComponent from "./../components/header/headerComponent";
import ceoColumn from "./../components/ceo/ceoColumn";
import alertBox from "./../components/alertBoxes/alertBox";
import axios from "axios";
import sha256 from "js-sha256";
import { td } from "../traductions/traductionsHelpers";

export default {
  name: "Account",
  components: {
    headerComponent,
    ceoColumn,
    alertBox
  },
  data: () => {
    return {
      td,
      drop_down_clicked: false,
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      profils: {
        ceo_manager: [],
        group_manager: [],
        team_manager: [],
        sale: []
      },
      files_allowed: "image/png, image/jpeg, image/jpg",
      eight_caract: "grey",
      lower_caract: "grey",
      upper_caract: "grey",
      number_caract: "grey",
      empty_old_password: false,
      admin_levels: {},
      user: {},
      admin_level: "",
      editable: "",
      file_01: null,
      modify_password: false,
      show_alert_box: false,
      is_password_type: true,
      new_password_is_password_type: true,
      old_password: "",
      new_password: "",
      msg_alert: "",
      previousContent: {
        user: {
          functions: []
        }
      }
    };
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    testOnChange() {
      this.empty_old_password = false;
      this.new_password = this.new_password.trim();
      while (this.new_password.indexOf(" ") > -1) {
        this.new_password = this.new_password.replace(" ", "");
      }
      if (this.new_password.length >= 8) {
        this.eight_caract = "green";
      } else {
        this.eight_caract = "grey";
      }
      let i = 0;
      let car = "";
      this.lower_caract = "grey";
      for (i = 0; i < this.new_password.length; i++) {
        car = this.new_password.charAt(i);
        if (car == car.toLowerCase() && isNaN(Number(car))) {
          this.lower_caract = "green";
          break;
        }
      }
      this.upper_caract = "grey";
      for (i = 0; i < this.new_password.length; i++) {
        car = this.new_password.charAt(i);
        if (car == car.toUpperCase() && isNaN(Number(car))) {
          this.upper_caract = "green";
          break;
        }
      }
      this.number_caract = "grey";
      for (i = 0; i < this.new_password.length; i++) {
        car = this.new_password.charAt(i);
        if (!isNaN(Number(car))) {
          this.number_caract = "green";
          break;
        }
      }
    },
    testOnValidate() {
      let result = true;

      if (this.old_password.trim() == "") {
        this.empty_old_password = true;
        result = false;
      } else {
        this.empty_old_password = false;
      }
      if (this.eight_caract == "grey") {
        this.eight_caract = "red";
        result = false;
      }
      if (this.lower_caract == "grey") {
        this.lower_caract = "red";
        result = false;
      }
      if (this.upper_caract == "grey") {
        this.upper_caract = "red";
        result = false;
      }
      if (this.number_caract == "grey") {
        this.number_caract = "red";
        result = false;
      }
      return result;
    },
    getIsPassword() {
      if (this.is_password_type === true) {
        return "password";
      }
      return "text";
    },
    getNewIsPassword() {
      if (this.new_password_is_password_type === true) {
        return "password";
      }
      return "text";
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      this.sendImage();
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    setPreviousContent(key) {
      if (key == "function") {
        this.previousContent.user.function = this.user.functions[0];
        return;
      }
      this.previousContent.user[key] = this.user[key];
    },
    testEmptyContent(key) {
      if (key == "function") {
        if (this.user.functions[0].trim() == "") {
          this.user.functions[0] = this.previousContent.user.function;
        }
        return;
      }
      if (this.user[key].trim() == "") {
        this.user[key] = this.previousContent.user[key];
      }
    },
    getUserPicture() {
      if (this.user.picture) {
        if (this.user.picture.trim() == "") {
          return "/assets/img/profil.png";
        }
        return axios.defaults.baseURL + "/images/" + this.user.picture;
      }
      return "/assets/img/profil.png";
    },
    headerCallbackHandler(obj) {
      if (obj.type == "click-profil") {
        this.$router.push({ path: "/edit-profil" });
      }
    },
    adminLevelValue() {
      if (this.admin_levels[this.admin_level]) {
        return this.admin_levels[this.admin_level].value;
      }
    },
    sendImage() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      } else {
        return;
      }

      axios
        .post("/users/update/profil_photo", formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.user = data.response.user;
            context.file_01 = null;

            store.dispatch("refreshTokenId", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    sendPassword() {
      let result = this.testOnValidate();
      if (!result) {
        return;
      }

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };
      let user = {};
      user.hashed_password = sha256(this.old_password);
      user.user_token = this.$store.state.userToken;
      user.new_password = this.new_password;
      user.email = this.user.email;
      sendData.user = user;

      axios
        .post("/users/update/profil_password", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.user = data.response.user;
            context.file_01 = null;
            context.msg_alert = td("views.account.msg_alert1");
            context.show_alert_box = true;
            context.modify_password = false;

            store.dispatch("refreshTokenId", authData);
          } else {
            router;
            /*store.dispatch("logout");
            router.replace("/login");*/
            context.file_01 = null;
            context.msg_alert = td("views.account.msg_alert2");
            context.show_alert_box = true;
            context.modify_password = false;

            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            store.dispatch("refreshTokenId", authData);
          }
        })
        .catch(function(error) {
          error;
        });
    },
    updateProfil() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };
      let user = { ...this.user };

      sendData.user = user;

      console.log("=== user === Account.vue === key: 535752 ===");
      console.log(user);
      console.log("=================================");

      axios
        .post("/users/update/own", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.user = data.response.user;
            context.file_01 = null;
            context.msg_alert = td("views.account.msg_alert3");
            context.show_alert_box = true;
            context.modify_password = false;

            store.dispatch("refreshTokenId", authData);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.file_01 = null;
            context.msg_alert = td("views.account.msg_alert4");
            context.show_alert_box = true;
            context.modify_password = false;
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    let prfls = this.user.profils;
    for (let i = 0; i < prfls.length; i++) {
      if (prfls[i].admin_level == "ceo_manager") {
        this.profils.ceo_manager.push(prfls[i]);
      }
      if (prfls[i].admin_level == "group_manager") {
        this.profils.group_manager.push(prfls[i]);
      }
      if (prfls[i].admin_level == "team_manager") {
        this.profils.team_manager.push(prfls[i]);
      }
      if (prfls[i].admin_level == "sale") {
        this.profils.sale.push(prfls[i]);
      }
    }
  }
};
</script>
