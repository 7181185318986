<template>
  <div class="body">
    <headerComponent :callback="headerCallbackHandler" />
    <main>
      <div class="wrapper-params">
        <div class="menu-column">
          <router-link
            to="/params/notifications"
            tag="div"
            class="menu-item"
            active-class="selected"
            ><p>{{ td("views.params.parrams.notifications") }}</p></router-link
          >
          <router-link
            to="/params/functions"
            tag="div"
            class="menu-item"
            active-class="selected"
            ><p>
              {{ td("views.params.parrams.sales_functions") }}
            </p></router-link
          >
          <router-link
            to="/params/affichage"
            tag="div"
            class="menu-item"
            active-class="selected"
            v-if="$store.getters.adminValue > 4"
            ><p>{{ td("views.params.parrams.display_params") }}</p></router-link
          >
          <router-link
            to="/params/labels"
            tag="div"
            class="menu-item"
            active-class="selected"
            v-if="$store.getters.adminValue > 4"
            ><p>{{ td("views.params.parrams.vocabulary") }}</p></router-link
          >
          <router-link
            to="/params/push"
            tag="div"
            class="menu-item"
            active-class="selected"
            v-if="$store.getters.adminValue > 1"
            ><p>{{ td("views.params.parrams.push_messages") }}</p></router-link
          >
        </div>
        <router-view />

        <transition name="fade">
          <div class="container-alert" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import headerComponent from "./../../components/header/headerComponent";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "Teams",
  components: {
    headerComponent
  },
  data: () => {
    return {
      td,
      show_alert_box: false,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {}
    };
  },
  methods: {
    headerCallbackHandler(obj) {
      obj;

      /*if (obj.type == "click-profil") {
        this.$router.push({ path: "/edit-profil" });
      }*/
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
  }
};
</script>
