<template>
  <div class="wrapper-content-column">
    <div class="wrapper-content affichage">
      <div class="bloc-periodes">
        <h2>{{ td("views.params_affichage.select_periods") }}</h2>
        <h3>{{ td("views.params_affichage.select_4_periods") }}</h3>
        <div class="bloc-content">
          <div class="col col-gauche">
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('week') },
                  { grey: isGrey('week') }
                ]"
                @click.stop="clickPeriod('week')"
              ></span
              >{{ td("views.params_affichage.week") }}
            </h4>
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('trim') },
                  { grey: isGrey('trim') }
                ]"
                @click.stop="clickPeriod('trim')"
              ></span
              >{{ td("views.params_affichage.trim") }}
            </h4>
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('sem') },
                  { grey: isGrey('sem') }
                ]"
                @click.stop="clickPeriod('sem')"
              ></span
              >{{ td("views.params_affichage.semester") }}
            </h4>
          </div>

          <div class="col col-droite">
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('month') },
                  { grey: isGrey('month') }
                ]"
                @click.stop="clickPeriod('month')"
              ></span
              >{{ td("views.params_affichage.month") }}
            </h4>
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('quadri') },
                  { grey: isGrey('quadri') }
                ]"
                @click.stop="clickPeriod('quadri')"
              ></span
              >{{ td("views.params_affichage.quadri") }}
            </h4>
            <h4>
              <span
                :class="[
                  'circle',
                  { clicked: getPeriodCliced('year') },
                  { grey: isGrey('year') }
                ]"
                @click.stop="clickPeriod('year')"
              ></span
              >{{ td("views.params_affichage.year") }}
            </h4>
          </div>
        </div>
        <div class="bloc-periodes-footer">
          <button
            class=""
            v-if="circle.params.periods.length == 4"
            @click.stop="validePeriods"
          >
            {{ td("views.params_affichage.cta.valide") }}
          </button>
          <button class="negatif" @click.stop="resetPeriods">
            {{ td("views.params_affichage.cta.cancel") }}
          </button>
        </div>
      </div>
      <div class="bloc-stars">
        <h2>{{ td("views.params_affichage.stars_option") }}</h2>
        <div class="bloc-content-stars">
          <div class="col col-gauche">
            <h3>{{ td("views.params_affichage.using_stars") }}</h3>
            <h4>
              <span
                :class="['circle', { clicked: circle.params.use_stars == 1 }]"
                @click.stop="circle.params.use_stars = 1"
              ></span
              >{{ td("views.params_affichage.yes") }}
            </h4>
            <h4>
              <span
                :class="['circle', { clicked: circle.params.use_stars == 0 }]"
                @click.stop="circle.params.use_stars = 0"
              ></span
              >{{ td("views.params_affichage.no") }}
            </h4>
            <h3 class="last">{{ td("views.params_affichage.stars_name") }}</h3>
            <p
              v-if="star_name_clicked == false"
              @click.stop="star_name_clicked = true"
            >
              {{ td("views.params_affichage.stars_name_sing") }}
            </p>
            <input
              type="text"
              v-model="circle.params.stars_name.sing"
              v-else
              @change="star_name_clicked = true"
            />
            <p
              v-if="stars_name_clicked == false"
              @click.stop="stars_name_clicked = true"
            >
              {{ td("views.params_affichage.stars_name_plur") }}
            </p>
            <input
              type="text"
              v-model="circle.params.stars_name.plur"
              v-else
              @change="stars_name_clicked = true"
            />
          </div>
          <div class="col col-droite">
            <h4>{{ td("views.params_affichage.upload_stars_icon") }}</h4>
            <div class="bloc-image">
              <div class="square-image">
                <img
                  :src="getStarURL()"
                  id="icon-star"
                  alt=""
                  @click.prevent="changePhotoHandler"
                />
                <input
                  style="display:none"
                  type="file"
                  name="file_01"
                  id="file_01"
                  ref="file_01"
                  :accept="files_allowed"
                  class="form-control fa-file-photo-o "
                  @change="changeFileUplaod('file_01')"
                />
              </div>
            </div>
            <div class="bloc-periodes-footer stars">
              <button class="" @click.stop="valideStars">
                {{ td("views.params_affichage.cta.valide") }}
              </button>
              <button class="negatif" @click.stop="resetStars">
                {{ td("views.params_affichage.cta.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sélection de l'anonymisation -->
    <div class="wrapper-content affichage">
      <div class="bloc-classement">
        <h2>{{ td("views.params_affichage.ranking_choice.title") }}</h2>
        <div class="bloc-content">
          <div
            :class="[
              'col',
              'col-left',
              { clicked: circle.params.ranking_type == 'none' }
            ]"
            @click.stop="circle.params.ranking_type = 'none'"
          >
            <h2>
              {{ td("views.params_affichage.ranking_choice.anon_none.title") }}
            </h2>
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_1")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_none.p1") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_2")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_none.p2") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_3")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_none.p3a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_none.p3b") }}
            </p>
          </div>
          <div
            :class="[
              'col',
              'col-right',
              { clicked: circle.params.ranking_type == 'group' }
            ]"
            @click.stop="circle.params.ranking_type = 'group'"
          >
            <h2>
              {{ td("views.params_affichage.ranking_choice.anon_group.title") }}
            </h2>
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_1")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_group.p1a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_group.p1b") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_2")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_group.p2") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_3")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_group.p3a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_group.p3b") }}
            </p>
          </div>
        </div>

        <div class="bloc-content">
          <div
            :class="[
              'col',
              'col-left',
              { clicked: circle.params.ranking_type == 'team' }
            ]"
            @click.stop="circle.params.ranking_type = 'team'"
          >
            <h2>
              {{ td("views.params_affichage.ranking_choice.anon_team.title") }}
            </h2>
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_1")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p1a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p1b") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p1c") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_2")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p2a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p2b") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p2c") }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_3")
              }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p3a") }}
            </p>
            <p>
              {{ td("views.params_affichage.ranking_choice.anon_team.p3b") }}
            </p>
          </div>
          <div
            :class="[
              'col',
              'col-right',
              { clicked: circle.params.ranking_type == 'complete' }
            ]"
            @click.stop="circle.params.ranking_type = 'complete'"
          >
            <h2>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.title")
              }}
            </h2>
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_1")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p1a")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p1b")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p1c")
              }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_2")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p2a")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p2b")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p2c")
              }}
            </p>
            <br />
            <p class="bold">
              {{
                td("views.params_affichage.ranking_choice.general_subtitle_3")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p3a")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p3b")
              }}
            </p>
            <p>
              {{
                td("views.params_affichage.ranking_choice.anon_complete.p3c")
              }}
            </p>
          </div>
        </div>
        <div class="bloc-periodes-footer" @click.stop="valideHandler">
          <button class="" @click.stop="valideRanking">
            {{ td("views.params_affichage.cta.valide") }}
          </button>
          <button class="negatif" @click.stop="cancelHandler">
            {{ td("views.params_affichage.cta.cancel") }}
          </button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "ParamsAffichage",
  components: { alertBox },
  data: () => {
    return {
      td,
      used_periods: [],
      star_name_clicked: false,
      stars_name_clicked: false,
      is_changed: false,
      files_allowed: "image/png, image/jpeg, image/jpg",
      init_periods: [],
      init_use_stars: 0,
      file_01: null,
      init_stars_name: {},
      //alert box
      show_alert_box: false,
      msg_alert: td("views.params_affichage.msg_alert"),
      alert_delay: 3000,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      functions: [],
      plus_clicked: false,
      editable: "",
      functions_loaded: false,
      used_functions: [],
      new_function: {
        name: td("views.params_affichage.new_function.name")
      },
      new_function_init: {
        name: td("views.params_affichage.new_function_init.name")
      },
      previousContent: {
        new_function: {}
      }
    };
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    valideHandler() {
      this.saveNotifications();
    },
    cancelHandler() {
      this.$router.go(-1);
    },
    getStarURL() {
      let url = "/assets/img/icon_star.png";
      if (this.circle.params.stars_picture == "") {
        url = "/assets/img/icon_star.png";
      } else {
        url =
          axios.defaults.baseURL +
          "/images/" +
          this.circle.params.stars_picture;
      }
      return url;
    },
    changeFileUplaod(key) {
      if (!this.$refs[key].files[0]) {
        return;
      }
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("icon-star");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
      this.is_changed = true;
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getPeriodCliced(key) {
      if (!this.circle) {
        return false;
      }

      key;
      let periods = this.circle.params.periods;
      if (periods.indexOf(key) > -1) {
        return true;
      }
      return false;
    },
    isGrey(key) {
      if (this.used_periods.indexOf(key) > -1) {
        return true;
      }
      return false;
    },
    clickPeriod(key) {
      if (!this.circle.params) {
        return;
      }
      if (this.used_periods.indexOf(key) > -1) {
        return;
      }
      let periods = this.circle.params.periods;
      if (periods.indexOf(key) > -1) {
        periods.splice(periods.indexOf(key), 1);
      } else {
        if (this.circle.params.periods.length >= 4) {
          return;
        }
        periods.push(key);
      }
    },
    validePeriods() {
      this.savePeriods();
    },
    valideStars() {
      this.saveStars();
    },
    valideRanking() {
      this.saveRanking();
    },
    resetPeriods() {
      this.circle.params.periods = [];
      this.init_periods.forEach(element => {
        this.circle.params.periods.push(element);
      });
    },
    resetStars() {
      this.circle.params.stars_name = { ...this.init_stars_name };
      this.circle.params.use_stars = this.init_use_stars;
      this.file_01 = null;
      this.is_changed = false;
      var output = document.getElementById("icon-star");
      output.src = "/assets/img/icon_star.png";
      this.star_name_clicked = false;
      this.stars_name_clicked = false;
    },
    getUsedPeriods() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = {
        credentials,
        filters
      };

      axios
        .post("/teams/used_periods", sendData)
        .then(function(result) {
          let data = result.data;

          ("========== /teams/used_periods ======= let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.used_periods = data.response.periods;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    savePeriods() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = {
        credentials,
        periods: this.circle.params.periods,
        filters
      };

      axios
        .post("/circles/update/periods", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.show_alert_box = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveRanking() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = {
        credentials,
        ranking_type: this.circle.params.ranking_type,
        filters
      };

      axios
        .post("/circles/update/ranking", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.show_alert_box = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveStars() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = JSON.stringify({ circle_token: this.circle.circle_token });

      /*let sendData = {
        credentials,
        use_stars: this.circle.params.use_stars,
        stars_name: this.circle.params.use_stars,
        filters,
      };*/

      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      const use_stars = JSON.stringify(this.circle.params.use_stars);
      formData.append("use_stars", use_stars);

      const stars_name = JSON.stringify(this.circle.params.stars_name);
      formData.append("stars_name", stars_name);

      formData.append("filters", filters);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      }

      axios
        .post("/circles/update/stars", formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.show_alert_box = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    this.circle.params.periods.forEach(element => {
      this.init_periods.push(element);
    });

    this.init_use_stars = this.circle.params.use_stars;
    this.init_stars_name = { ...this.circle.params.stars_name };

    this.getUsedPeriods();
  }
};
</script>
