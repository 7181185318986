<template>
  <div class="wrapper-content-column challenges">
    <section
      class="large-content top-bar "
      v-if="$store.getters.adminValue >= 2"
    >
      <div class="large-content-column col-gauche">
        <h2>
          {{ td("views.params.push.select_profile") }}
        </h2>
      </div>
      <div class="large-content-column col-3">
        <div class="bloc-search">
          <h2>{{ td("views.params.push.search") }}</h2>
          <div class="bottom-line">
            <v-select
              label="name"
              v-if="editable == 'search'"
              :placeholder="td('global.topcomponent.add_name')"
              :options="new_user_options"
              :components="{ OpenIndicator }"
              v-model="new_user_selected"
              @input="
                editable = '';
                setNewUserFromVSelect();
              "
            >
              <div slot="no-options">
                {{ td("views.params.push.sorry_but") }}
              </div>
            </v-select>

            <p
              class="hoverable"
              :data-hover="td('global.topcomponent.add_name')"
              v-else
              @click="editable = 'search'"
            >
              {{ getSelectedUserName() }}
            </p>
            <img src="/assets/img/loupe.png" alt="" />
          </div>
        </div>
      </div>
      <div class="user-item" v-if="new_user_selected.user_token">
        <div class="container-avatar-body">
          <div class="col-avatar">
            <img :src="getUserLogo(new_user_selected)" alt="" id="user_image" />
          </div>
          <div class="col-body">
            <h2>
              {{
                new_user_selected.firstname + " " + new_user_selected.lastname
              }}
            </h2>
            <h3>{{ new_user_selected.functions[0] }}</h3>
            <h4>
              {{ new_user_selected.team_name || new_user_selected.group_name }}
            </h4>
          </div>
        </div>
        <div class="col-buttons"></div>
      </div>
    </section>

    <div class="push-wrapper-content-row" style="border:solid 0px blue">
      <div
        class="push-column-users check-round"
        v-if="new_user_selected.user_token"
      >
        <h2 v-if="new_user_selected.admin_level == 'ceo_manager'">
          {{ td("views.params.push.select_groups") }}
          <!--<span
            class="plus"
            @click.stop="clickHandler({ type: 'click-plus' })"
            v-if="$store.getters.adminValue > 1"
            >+</span
          >-->
          <span
            :class="['circle', { clicked: getClickAllUsers() }]"
            @click.stop="clickAllUsers()"
          ></span>
        </h2>
        <h2 v-if="new_user_selected.admin_level == 'group_manager'">
          {{ td("views.params.push.select_teams") }}
          <!--<span
            class="plus"
            @click.stop="clickHandler({ type: 'click-plus' })"
            v-if="$store.getters.adminValue > 1"
            >+</span
          >-->
          <span
            :class="['circle', { clicked: getClickAllUsers() }]"
            @click.stop="clickAllUsers()"
          ></span>
        </h2>
        <h2 v-if="new_user_selected.admin_level == 'team_manager'">
          {{ td("views.params.push.select_sales") }}
          <!--<span
            class="plus"
            @click.stop="clickHandler({ type: 'click-plus' })"
            v-if="$store.getters.adminValue > 1"
            >+</span
          >-->
          <span
            :class="['circle', { clicked: getClickAllUsers() }]"
            @click.stop="clickAllUsers()"
          ></span>
        </h2>

        <div class="scrollable">
          <div
            class="user-item"
            v-for="(item, index) in users"
            :key="index"
            @click.stop="clickItemHandler(item)"
          >
            <div :class="['container-avatar-body']">
              <div class="col-avatar" style="width:91px">
                <img :src="getItemAvatar(item)" alt="" />
              </div>
              <div class="col-body">
                <h2>{{ item.firstname + " " + item.lastname }}</h2>
                <h3>{{ item.functions[0] }}</h3>
                <h4>{{ item.team_name || item.group_name || "" }}</h4>
              </div>
            </div>
            <div class="col-buttons">
              <div class="check-round-tiny">
                <h2>
                  <span
                    :class="['circle', { clicked: item.clicked === true }]"
                    @click.stop="clickUser(item)"
                  ></span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="new_user_selected.user_token"
        class="bloc-text check-round-tiny "
        style=""
      >
        <h2
          style="margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 15px;
                line-height: 3.2rem;
                background-color: #f2f3f4;
                height: 51px;
                padding: 0 0 0 20px;
                position: relative;
                text-transform: uppercase;"
        >
          {{ td("views.params.push.title_message") }}{{ getLengthDispo() }}
        </h2>
        <textarea
          maxlength="240"
          name=""
          id=""
          style="margin: 0px 15px 0px 20px;padding:10px;max-width:'100%';color:#666;border:unset;height:80px"
          v-model="message"
          @click.stop="clickMessage"
          @blur="blurMessage"
        ></textarea>
        <h2
          v-if="new_user_selected.admin_level == 'ceo_manager'"
          class="check-managers"
          style="margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 3.2rem;
                background-color: #f2f3f4;
                height: 36px;
                padding: 0 0 0 3rem;
                position: relative;"
        >
          <span
            :class="['circle', { clicked: sendToGroupManagers == 1 }]"
            @click.stop="sendToGroupManagers = sendToGroupManagers == 1 ? 0 : 1"
          ></span>
          {{ td("views.params.push.send_to_resps") }}
        </h2>
        <h2
          v-if="
            new_user_selected.admin_level == 'ceo_manager' ||
              new_user_selected.admin_level == 'group_manager'
          "
          class="check-managers"
          style="margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 3.2rem;
                background-color: #f2f3f4;
                height: 36px;
                padding: 0 0 0 3rem;
                position: relative;"
        >
          <span
            :class="['circle', { clicked: sendToTeamManagers == 1 }]"
            @click.stop="sendToTeamManagers = sendToTeamManagers == 1 ? 0 : 1"
          ></span>
          {{ td("views.params.push.send_to_teams") }}
        </h2>
        <h2
          v-if="
            new_user_selected.admin_level == 'ceo_manager' ||
              new_user_selected.admin_level == 'group_manager'
          "
          class="check-managers"
          style="margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 3.2rem;
                background-color: #f2f3f4;
                height: 36px;
                padding: 0 0 0 3rem;
                position: relative;"
        >
          <span
            :class="['circle', { clicked: sendToSales == 1 }]"
            @click.stop="sendToSales = sendToSales == 1 ? 0 : 1"
          ></span>
          {{ td("views.params.push.send_to_sales") }}
        </h2>
        <div
          class="bloc-periodes-footer"
          style="min-height:80px;position:absolute;bottom:0px"
        >
          <button
            class=""
            @click.stop="sendMessage"
            v-if="messageIsValide()"
            style="opacity:1"
          >
            {{ td("views.params.push.cta.valide") }}
          </button>
          <button
            class=""
            @click.stop=""
            v-if="!messageIsValide()"
            style="opacity:0.3"
          >
            {{ td("views.params.push.cta.valide") }}
          </button>
          <button class="negatif" @click.stop="cancelMessage">
            {{ td("views.params.push.cta.cancel") }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "MessagePush",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      //alert box
      message: td("views.params.push.message"),
      init_message: td("views.params.push.init_message"),
      show_alert_box: false,
      msg_alert: td("views.params.push.msg_alert"),
      alert_delay: 3000,
      //confirm box
      show_confirm_box: false,
      //
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      selectedUsers: [],
      new_user_options: [],
      new_user_selected: {},
      new_user_init: {
        name: td("views.params.push.new_user_init.name"),
        firstname: td("views.params.push.new_user_init.firstname"),
        lastname: td("views.params.push.new_user_init.lastname"),
        email: td("views.params.push.new_user_init.email"),
        confirm_email: td("views.params.push.new_user_init.confirm_email"),
        function: td("views.params.push.new_user_init.function"),
        phone: td("views.params.push.new_user_init.phone"),
        gender: td("views.params.push.new_user_init.gender"),
        language: td("views.params.push.new_user_init.language")
      },
      users: [],
      editable: "",
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          elem.id = "toto";
          return elem;
        }
      },
      sendToGroupManagers: 0,
      sendToTeamManagers: 0,
      sendToSales: 1
    };
  },
  methods: {
    cancelMessage() {
      this.message = this.init_message;
      this.users = [];
      this.sendToGroupManagers = 0;
      this.sendToTeamManagers = 0;
      this.sendToSales = 1;
      this.new_user_selected = {};
    },
    messageIsValide() {
      if (
        this.sendToGroupManagers == 0 &&
        this.sendToTeamManagers == 0 &&
        this.sendToSales == 0
      ) {
        return false;
      }
      if (this.message == this.init_message || this.message == "") {
        return false;
      }
      let i = 0;
      for (i = 0; i < this.users.length; i++) {
        const element = this.users[i];
        if (element.clicked === true) {
          break;
        }
      }
      if (i >= this.users.length) {
        return false;
      }
      return true;
    },
    getLengthDispo() {
      if (this.message == this.init_message) {
        return "  (240)";
      }
      if (this.message == "") {
        return "  (240)";
      }
      return "  (" + (240 - this.message.length) + ")";
    },
    clickMessage() {
      if (this.message == this.init_message) {
        this.message = "";
      }
    },
    blurMessage() {
      if (this.message == "") {
        this.message = this.init_message;
      }
    },
    clickAllUsers() {
      if (this.getClickAllUsers() == true) {
        for (let i = 0; i < this.users.length; i++) {
          const element = this.users[i];
          element.clicked = false;
        }
      } else {
        for (let i = 0; i < this.users.length; i++) {
          const element = this.users[i];
          element.clicked = true;
        }
      }
    },
    getClickAllUsers() {
      // eslint-disable-next-line no-unreachable
      for (let i = 0; i < this.users.length; i++) {
        const element = this.users[i];
        if (element.clicked !== true) {
          return false;
        }
      }
      return true;
    },
    clickUser(item) {
      item.clicked = item.clicked === true ? false : true;
      console.log("================ clicked ====================");
      console.log(item.clicked);
      console.log("====================================");
    },
    getGroupToken() {
      // eslint-disable-next-line no-unreachable
      if (this.new_user.group_token) {
        return this.new_user.group_token;
      }
      if (this.user.group_token) {
        return this.user.group_token;
      }
      return "";
    },
    getTeamToken() {
      // eslint-disable-next-line no-unreachable
      if (this.new_user.team_token) {
        return this.new_user.team_token;
      }

      if (this.user.team_token) {
        return this.user.team_token;
      }
      return "";
    },
    getCreatorToken() {
      return "";
      // eslint-disable-next-line no-unreachable
      if (this.new_user.user_token) {
        return this.new_user.user_token;
      }

      if (this.user.user_token) {
        return this.user.user_token;
      }
      return "";
    },
    isGroupManager() {
      if (this.$store.getters.adminValue == 4) {
        return true;
      }
      return false;
    },
    getItemAvatar(user) {
      if (!user) {
        return "/assets/img/profil2.png";
      }
      if (user.picture) {
        if (user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + user.picture;
      }
      return "/assets/img/profil2.png";
    },
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "click-valider") {
        this.doDeleteItem(this.item_to_delete);
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      /*var output = document.getElementById("user_image");
      output.src = this.getUserLogo();*/

      this.sendToGroupManagers = 0;
      this.sendToTeamManagers = 0;
      this.sendToSales = 1;

      this.getUsers();
    },
    sendMessage() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let circle_token = this.new_user_selected.circle_token || "///";
      let filters = {
        circle_token
      };

      let selected_users = this.users.filter(user => {
        return user.clicked == true;
      });

      console.log("================= selected_users ===================");
      console.log(selected_users);
      console.log("====================================");

      let message_data = {
        message: this.message,
        send_to_group_managers: this.sendToGroupManagers,
        send_to_team_managers: this.sendToTeamManagers,
        send_to_sales: this.sendToSales,
        selected_users,
        new_user_selected: this.new_user_selected
      };

      const sendData = {
        credentials,
        filters,
        message_data
      };
      axios
        .post("/users/pushmessage", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.dispatch("refreshTokenId", authData);
            console.log("================== data.response ==================");
            console.log(data.response);
            console.log("====================================");
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
          context.cancelMessage();
          context.show_alert_box = true;
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let admin_level = "";
      let circle_token = this.new_user_selected.circle_token || "///";
      let filters = {
        circle_token
      };

      if (this.new_user_selected.admin_level == "ceo_manager") {
        admin_level = "group_manager";
        filters = { ...filters, admin_level };
      }

      if (this.new_user_selected.admin_level == "group_manager") {
        admin_level = "team_manager";
        filters = {
          ...filters,
          admin_level,
          group_token: this.new_user_selected.group_token
        };
      }

      if (this.new_user_selected.admin_level == "team_manager") {
        admin_level = "sale";
        filters = {
          ...filters,
          admin_level,
          team_token: this.new_user_selected.team_token
        };
      }

      let options = {
        has_team_manager: 0,
        childs_email: 0
      };

      const sendData = {
        credentials,
        filters,
        options
      };
      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            let tmpUsers = data.response.users;
            for (let i = 0; i < tmpUsers.length; i++) {
              const element = tmpUsers[i];
              element.clicked = false;
            }
            let alreadySelected = [];
            context.users = [];
            console.log(
              "================ context.new_user_selected ===================="
            );
            console.log(context.new_user_selected);
            console.log("====================================");
            if (context.new_user_selected.admin_level == "ceo_manager") {
              for (let i = 0; i < tmpUsers.length; i++) {
                const element = tmpUsers[i];
                console.log("====================================");
                console.log(element.group_token);
                console.log("====================================");
                if (alreadySelected.indexOf(element.group_token) == -1) {
                  alreadySelected.push(element.group_token);
                  context.users.push(element);
                }
              }
            } else if (
              context.new_user_selected.admin_level == "group_manager"
            ) {
              for (let i = 0; i < tmpUsers.length; i++) {
                const element = tmpUsers[i];
                if (alreadySelected.indexOf(element.team_token) == -1) {
                  alreadySelected.push(element.team_token);
                  context.users.push(element);
                }
              }
            } else {
              context.users = [...tmpUsers];
            }
            //context.users = [...tmpUsers];

            store.dispatch("refreshTokenId", authData);
            console.log("================== context.users ==================");
            console.log(data.response);
            console.log(context.users);
            console.log("====================================");
            /*if (context.user.admin_level == "team_manager") {
              for (let i = 0; i < context.users.length; i++) {
                const u = context.users[i];
                
                
                
                if(u.group_token == context.user.group_token){
                  context.clicked_user_token = u.user_token;
                }
              }
            }*/
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return this.td("global.topcomponent.add_name");
      }
      if (!this.new_user.user_token) {
        return this.td("global.topcomponent.add_name");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return this.td("global.topcomponent.add_name");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    //this.new_user = this.user;

    let key = "team_name";
    key;

    let profils = [];
    for (let i = 0; i < this.user.profils.length; i++) {
      const prfls = this.user.profils[i];
      if (prfls.admin_level != "sale") {
        profils.push(prfls);
      }
    }

    console.log("=============== profils =====================");
    console.log(profils);
    console.log("====================================");

    let scratchArray = profils.map(obj => {
      let newObj = { ...obj };
      newObj.function = obj.functions[0];
      newObj.name =
        obj.firstname +
        " " +
        obj.lastname +
        " - " +
        //obj["admin_level"] +
        //" " +
        (obj["team_name"] || obj["group_name"] || obj["circle_name"]);
      obj.key = Math.random();
      return newObj;
    });

    this.new_user_options = scratchArray;

    /*if (
      this.user.admin_level == "group_manager" ||
      this.user.admin_level == "team_manager"
    ) {
      this.getUsers(true);
      return;
    }*/

    axios;
    sha256;

    //
    //this.getChallenges();
  }
};
</script>
