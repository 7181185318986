<template>
  <div class="body">
    <headerComponent :callback="headerCallbackHandler" />
    <router-view />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import headerComponent from "./../../components/header/headerComponent";
import ceoColumn from "./../../components/ceo/ceoColumn";

export default {
  name: "Teams",
  components: {
    headerComponent,
    ceoColumn
  },
  data: () => {
    return {
      admin_levels: {},
      user: {},
      admin_level: "",
      do_new_ceo: false
    };
  },
  methods: {
    headerCallbackHandler(obj) {
      obj;

      /*if (obj.type == "click-profil") {
        this.$router.push({ path: "/edit-profil" });
      }*/
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
  }
};
</script>
