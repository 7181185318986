<template>
  <div class="container-alert">
    <div class="transparent-lightbox-bg"></div>
    <div class="bloc-alert">
      <p class="title-content">
        {{ title }}
      </p>
      <p class="error-content first">
        {{ msg1 }}
      </p>
      <p class="error-content">
        {{ msg2 }}
      </p>
      <p class="footer-content">
        <button @click.stop="clickValider">
          {{ td("components.alertbox.valide_cta.valide") }}
        </button>
        <button class="negatif" @click.stop="clickAnnuler">
          {{ td("components.alertbox.cancel_cta.cancel") }}
        </button>
      </p>
    </div>
  </div>
</template>
<script>
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "alertBox",
  props: {
    msg1: {
      type: String,
      required: true
    },
    msg2: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    delay: {
      type: Number,
      default: 2000
    },
    callback: {
      type: Function,
      default: null
    }
  },
  methods: {
    td,
    clickAnnuler() {
      this.callback({ type: "click-annuler" });
    },
    clickValider() {
      this.callback({ type: "click-valider" });
    }
  },
  mounted() {
    if (this.callback) {
      let context = this;
      if (this.delay && !isNaN(this.delay)) {
        setTimeout(() => {
          context.callback({ type: "end_delay" });
        }, this.delay);
      }
    }
  }
};
</script>
<style scoped>
.container-alert {
  position: absolute;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  right: 0;
  border: solid 0px cyan;
}

.bloc-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 390px;
  height: 225px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}
.error-content {
  display: flex;
  flex: 1;
  width: 100%;
  font-family: "Lato", serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  padding: 0 0 0 3.5rem;
  margin: 0;
  text-align: center;
  border: solid 0px red;
}

.first {
  align-items: flex-end;
  padding-bottom: 1rem;
}

.footer-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;

  padding: 0;
  margin: 0;
  border: solid 0px red;
}

.title-content {
  display: flex;
  flex: 1;
  width: 100%;
  height: 40px;
  max-height: 51px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #efefef;
  align-items: center;
  flex-direction: row;
  padding: 0 0 0 3.5rem;
  margin: 0;
  border: solid 0px red;
}

.footer-content button {
  width: 184px;
  height: 42px;
  border: 0;
  border-radius: 2rem;
  background: var(--orange);
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-content button:hover {
  background: var(--bleu-cyan);
}

.footer-content button.negatif {
  width: 130px;
  height: 42px;
  border: solid 2px var(--orange);
  border-radius: 2rem;
  background: #fff;
  font-size: 13px;
  color: var(--orange);
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.footer-content button.negatif:hover {
  background-color: #fff;
  color: var(--bleu-cyan);
  border-color: var(--bleu-cyan);
}
</style>
