var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-content-column"},[_c('div',{staticClass:"wrapper-content"},[(_vm.$store.getters.adminValue == 8)?_c('div',{staticClass:"column-notifications"},[_c('h2',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(_vm.td('views.params.params_notifications.title_ceo'))}}),_c('span',{class:['circle', { clicked: _vm.getClickAllCeo() }],on:{"click":function($event){$event.stopPropagation();return _vm.clickAllCeo()}}})]),_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.td('views.params.params_notifications.ceo.select_your_notifs')
        )}}),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.ceo.objectifs")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.more_than_half"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.objectif2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.objectif2 =
                _vm.notifications.ceo_manager.objectif2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.more_than_75pct"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.objectif3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.objectif3 =
                _vm.notifications.ceo_manager.objectif3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.more_than_all"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.objectif4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.objectif4 =
                _vm.notifications.ceo_manager.objectif4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.perso"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.objectif1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.objectif1 =
                _vm.notifications.ceo_manager.objectif1 == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif",staticStyle:{"min-height":"178px"}},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.ceo.challenges")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.challenges.machin"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.challenges_name == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.challenges_name =
                _vm.notifications.ceo_manager.challenges_name == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.ceo.records")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.records_team"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.record2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.record2 =
                _vm.notifications.ceo_manager.record2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.records_group"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.record3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.record3 =
                _vm.notifications.ceo_manager.record3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.records_company"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.record4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.record4 =
                _vm.notifications.ceo_manager.record4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.ceo.records_perso"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.ceo_manager.record1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.ceo_manager.record1 =
                _vm.notifications.ceo_manager.record1 == 1 ? 0 : 1}}})])])]):_vm._e(),(
        (_vm.$store.getters.adminValue == 8 && _vm.group_profils.length) ||
          _vm.$store.getters.adminValue == 4
      )?_c('div',{staticClass:"column-notifications"},[_c('h2',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(_vm.td('views.params.params_notifications.title_group'))}}),_c('span',{class:['circle', { clicked: _vm.getClickAllGroup() }],on:{"click":function($event){$event.stopPropagation();return _vm.clickAllGroup()}}})]),_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.td('views.params.params_notifications.group.select_your_notifs')
        )}}),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.group.objectifs")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.more_than_half"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.objectif2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.objectif2 =
                _vm.notifications.group_manager.objectif2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.more_than_75pct"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.objectif3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.objectif3 =
                _vm.notifications.group_manager.objectif3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.more_than_all"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.objectif4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.objectif4 =
                _vm.notifications.group_manager.objectif4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.perso"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.objectif1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.objectif1 =
                _vm.notifications.group_manager.objectif1 == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.challenges"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.challenges.machin"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.challenges_name == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.challenges_name =
                _vm.notifications.group_manager.challenges_name == 1 ? 0 : 1}}})]),_c('div',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(
              _vm.td(
                'views.params.params_notifications.group.challenges.new_challenge'
              ) + 'toto'
            )}}),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.challenges_new == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.challenges_new =
                _vm.notifications.group_manager.challenges_new == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.challenges.won"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.challenges_won == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.challenges_won =
                _vm.notifications.group_manager.challenges_won == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.challenges.lost"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.challenges_lost == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.challenges_lost =
                _vm.notifications.group_manager.challenges_lost == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.group.records")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.records_team"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.record2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.record2 =
                _vm.notifications.group_manager.record2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.records_group"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.record3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.record3 =
                _vm.notifications.group_manager.record3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.records_company"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.record4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.record4 =
                _vm.notifications.group_manager.record4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.group.records_perso"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.group_manager.record1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.group_manager.record1 =
                _vm.notifications.group_manager.record1 == 1 ? 0 : 1}}})])])]):_vm._e(),(
        (_vm.$store.getters.adminValue == 8 && _vm.team_profils.length) ||
          _vm.$store.getters.adminValue == 4 ||
          _vm.$store.getters.adminValue == 2
      )?_c('div',{staticClass:"column-notifications"},[_c('h2',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(_vm.td('views.params.params_notifications.title_team'))}}),_c('span',{class:['circle', { clicked: _vm.getClickAllTeam() }],on:{"click":function($event){$event.stopPropagation();return _vm.clickAllTeam()}}})]),_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.td('views.params.params_notifications.team.select_your_notifs')
        )}}),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.team.objectifs")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.more_than_half"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.objectif2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.objectif2 =
                _vm.notifications.team_manager.objectif2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.more_than_75pct"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.objectif3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.objectif3 =
                _vm.notifications.team_manager.objectif3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.more_than_all"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.objectif4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.objectif4 =
                _vm.notifications.team_manager.objectif4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.perso"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.objectif1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.objectif1 =
                _vm.notifications.team_manager.objectif1 == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.team.challenges")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.challenges.machin"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.challenges_name == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.challenges_name =
                _vm.notifications.team_manager.challenges_name == 1 ? 0 : 1}}})]),_c('div',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(
              _vm.td(
                'views.params.params_notifications.team.challenges.new_challenge'
              ) + 'tutu'
            )}}),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.challenges_new == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.challenges_new =
                _vm.notifications.team_manager.challenges_new == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.challenges.won"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.challenges_won == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.challenges_won =
                _vm.notifications.team_manager.challenges_won == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.challenges.lost"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.challenges_lost == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.challenges_lost =
                _vm.notifications.team_manager.challenges_lost == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.team.records")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.records_team"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.record2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.record2 =
                _vm.notifications.team_manager.record2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.records_group"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.record3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.record3 =
                _vm.notifications.team_manager.record3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.records_company"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.record4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.record4 =
                _vm.notifications.team_manager.record4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.team.records_perso"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.team_manager.record1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.team_manager.record1 =
                _vm.notifications.team_manager.record1 == 1 ? 0 : 1}}})])])]):_vm._e(),(_vm.$store.getters.adminValue == 8)?_c('div',{staticClass:"column-notifications"},[_c('h2',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(_vm.td('views.params.params_notifications.title_sale'))}}),_c('span',{class:['circle', { clicked: _vm.getClickAllSale() }],on:{"click":function($event){$event.stopPropagation();return _vm.clickAllSale()}}})]),_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.td('views.params.params_notifications.sale.select_your_notifs')
        )}}),_c('div',{staticClass:"bloc-notif",staticStyle:{"min-height":"206px"}},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.sale.objectifs")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.more_than_half"))),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.objectif2 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.objectif2 =
                _vm.notifications.sale.objectif2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.more_than_75pct"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.objectif3 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.objectif3 =
                _vm.notifications.sale.objectif3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.more_than_all"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.objectif4 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.objectif4 =
                _vm.notifications.sale.objectif4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.nex_objectifs"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.objectif1 == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.objectif1 =
                _vm.notifications.sale.objectif1 == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif",staticStyle:{"min-height":"178px"}},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.sale.challenges")))]),_c('div',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(
              _vm.td(
                'views.params.params_notifications.sale.challenges.new_challenge'
              ) + 'titi'
            )}}),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.challenges_new == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.challenges_new =
                _vm.notifications.sale.challenges_new == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.challenges.won"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.challenges_won == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.challenges_won =
                _vm.notifications.sale.challenges_won == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.challenges.lost"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.challenges_lost == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.challenges_lost =
                _vm.notifications.sale.challenges_lost == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(_vm._s(_vm.td("views.params.params_notifications.sale.records")))]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.records_team"))),_c('span',{class:['circle', { clicked: _vm.notifications.sale.record2 == 1 }],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.record2 =
                _vm.notifications.sale.record2 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.records_group"))+" "),_c('span',{class:['circle', { clicked: _vm.notifications.sale.record3 == 1 }],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.record3 =
                _vm.notifications.sale.record3 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.records_company"))+" "),_c('span',{class:['circle', { clicked: _vm.notifications.sale.record4 == 1 }],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.record4 =
                _vm.notifications.sale.record4 == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sale.records_perso"))+" "),_c('span',{class:['circle', { clicked: _vm.notifications.sale.record1 == 1 }],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.record1 =
                _vm.notifications.sale.record1 == 1 ? 0 : 1}}})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.overtaking.title.overtaking" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.overtaking.congrats"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.overtaking_win == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.overtaking_win =
                _vm.notifications.sale.overtaking_win == 1 ? 0 : 1}}})]),_c('div',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.overtaking.too_bad"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.notifications.sale.overtaking_lost == 1 }
            ],on:{"click":function($event){$event.stopPropagation();_vm.notifications.sale.overtaking_lost =
                _vm.notifications.sale.overtaking_lost == 1 ? 0 : 1}}})])])]):_vm._e(),(_vm.$store.getters.adminValue < 8)?_c('div',{staticClass:"column-notifications"},[_c('h2',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(
            _vm.td('views.params.params_notifications.sales_notifications.title')
          )}}),_c('span',{class:['circle-empty', { 'circle-grey': _vm.getClickAllSale() }]})]),_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.td(
            'views.params.params_notifications.sales_notifications.selected_settings'
          )
        )}}),_c('div',{staticClass:"bloc-notif",staticStyle:{"min-height":"206px"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.objectif" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.objectif_half" ))),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.objectif2 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.objectif_75pct" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.objectif3 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.objectif_all" ))),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.objectif4 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.new_objectif" ))),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.objectif1 == 1 }
            ]})])]),_c('div',{staticClass:"bloc-notif",staticStyle:{"min-height":"178px"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.challenges" ))+" ")]),_c('div',[_c('span',{staticClass:"title-text",domProps:{"innerHTML":_vm._s(
              _vm.td(
                'views.params.params_notifications.sales_notifications.new_challenges'
              )
            )}}),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.challenges_new == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.challenge_won" ))),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.challenges_won == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.challenge_lost" ))),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.challenges_lost == 1 }
            ]})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.sales_notifications.record"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.record_team" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.record2 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.record_group" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.record3 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.record_company" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.record4 == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.record_perso" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.record1 == 1 }
            ]})])]),_c('div',{staticClass:"bloc-notif"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.overtaking" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.overtaking.congrats" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.overtaking_win == 1 }
            ]})]),_c('div',[_vm._v(" "+_vm._s(_vm.td( "views.params.params_notifications.sales_notifications.overtaking.too_bad" ))+" "),_c('span',{class:[
              'circle-empty',
              { 'circle-grey': _vm.notifications.sale.overtaking_lost == 1 }
            ]})])])]):_vm._e()]),_c('div',{staticClass:"notifications-footer",on:{"click":function($event){$event.stopPropagation();return _vm.valideHandler.apply(null, arguments)}}},[_c('button',{},[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.cta.valide"))+" ")]),_c('button',{staticClass:"negatif",on:{"click":function($event){$event.stopPropagation();return _vm.cancelHandler.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.td("views.params.params_notifications.cta.cancel"))+" ")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show_alert_box === true)?_c('div',{staticClass:"container-alert",staticStyle:{"height":"100%"}},[_c('alertBox',{attrs:{"msg":_vm.msg_alert,"callback":_vm.alertCallback,"delay":_vm.alert_delay}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }