<template>
  <header>
    <img :src="getCircleLogo" alt="" class="logo" />
    <div class="header-bloc-menu">
      <router-link
        to="/teams"
        tag="p"
        :class="{ karamba: isKaramba() }"
        active-class="selected"
        >{{ td("components.header.header_component.item.teams") }}</router-link
      >
      <router-link
        to="/catalogue"
        tag="p"
        :class="{ karamba: isKaramba() }"
        active-class="selected"
        >{{
          td("components.header.header_component.item.catalog")
        }}</router-link
      >
      <router-link
        to="/challenges"
        tag="p"
        :class="{ karamba: isKaramba() }"
        active-class="selected"
        >{{
          td("components.header.header_component.item.challenges")
        }}</router-link
      >
      <router-link
        to="/reporting"
        tag="p"
        :class="{ karamba: isKaramba() }"
        active-class="selected"
        >{{
          td("components.header.header_component.item.reporting")
        }}</router-link
      >
    </div>
    <div
      class="header-bloc-profil-bg"
      :class="[{ karamba: isKaramba() }, { selected: getAccountSelected() }]"
    >
      <div
        class="header-bloc-profil"
        @click.stop="clickProfil"
        id="bloc-profil"
      >
        <!--<div class="textes-profil">
        <p class="nom-profil">{{ user.firstname + " " + user.lastname }}</p>
        <p class="role-profil">{{ admin_level_label }}</p>
      </div>-->
        <img
          :src="getUserPicture"
          alt=""
          class="header-img-profil"
          id="img-profil"
        />
        <img
          src="/assets/img/arrow_down.png"
          alt=""
          class="header-arrow-down"
        />
      </div>
    </div>
    <div
      v-if="$store.state.circleToken != ''"
      class="header-bloc-params"
      :class="[{ karamba: isKaramba() }, { selected: getParamsSelected() }]"
      @click.stop="clickParams"
    >
      <img src="/assets/img/rouage.png" alt="" class="" id="img-params" />
    </div>
    <headerPopin
      v-if="popin_opened"
      :callback="callbackHandler"
      v-click-outside="clickOutsideHandler"
    />
  </header>
</template>
<script>
import headerPopin from "./headerPopin";
import axios from "axios";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "header",
  components: {
    headerPopin
  },
  props: ["callback"],
  data: () => {
    return {
      td,
      admin_levels: {},
      user: {},
      admin_level: "",
      admin_level_label: "",
      popin_opened: false
    };
  },
  computed: {
    getUserPicture() {
      if (this.$store.state.userData.picture) {
        if (this.$store.state.userData.picture.trim() == "") {
          return "/assets/img/profil.png";
        }
        return (
          axios.defaults.baseURL +
          "/images/" +
          this.$store.state.userData.picture
        );
      }
      return "/assets/img/profil.png";
    },
    getCircleLogo() {
      if (this.$store.state.circleData) {
        if (!this.$store.state.circleData.pictures) {
          return "/assets/img/logo_karamba.png";
        }
        if (!this.$store.state.circleData.pictures.length) {
          return "/assets/img/logo_karamba.png";
        }
        if (this.$store.state.circleData.pictures[0].trim() == "") {
          return "/assets/img/logo_karamba.png";
        }
        return (
          axios.defaults.baseURL +
          "/images/" +
          this.$store.state.circleData.pictures[0]
        );
      }
      return "/assets/img/logo_karamba.png";
    }
  },
  methods: {
    isKaramba() {
      return this.$store.state.circleToken == "";
    },
    getAccountSelected() {
      return this.$router.currentRoute.name == "Account";
    },
    getParamsSelected() {
      return this.$router.currentRoute.name == "Params";
    },
    clickOutsideHandler(evt) {
      let el1 = document.getElementById("img-profil");
      let el2 = document.getElementById("bloc-profil");

      if (evt.target == el1 || evt.target == el2) {
        return;
      }
      this.popin_opened = false;
    },
    callbackHandler(obj) {
      this.popin_opened = false;
      if (obj.type == "click-unconnect-profil") {
        this.logoutHandler();
      }
      if (obj.type == "click-unconect-circle") {
        this.$store.commit("circleLogout");
        this.$router.push({ path: "/" });
      }
      if (obj.type == "account") {
        this.$router.push({ path: "/account" });
      }
      //this.callback(obj);
    },
    clickParams() {
      this.$router.push({ path: "/params" });
    },
    clickProfil(e) {
      e;
      this.popin_opened = !this.popin_opened;
    },
    logoutHandler() {
      this.$store.dispatch("logout");
      setTimeout(() => {
        this.$router.go();
      }, 150);
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.admin_level_label = this.admin_levels[this.admin_level].label;
  }
};
</script>
