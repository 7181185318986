import Vue from "vue";
import Vuex from "vuex";
//import router from "./../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userToken: null,
    connectedToken: null,
    userData: null,
    admin_levels: {
      god: { label: "Dieu", value: 32 },
      top_admin: { label: "Top Administrateur", value: 16 },
      ceo_manager: { label: "Top Manager", value: 8 },
      group_manager: { label: "Group Manager", value: 4 },
      team_manager: { label: "Team Manager", value: 2 },
      sale: { label: "Sale", value: 1 }
    },
    circleToken: "",
    circleData: null
  },
  mutations: {
    // utiliser avec commit depuis les composants. Toujours synchrone
    authUser(state, data) {
      /*state.connectedToken = data.tokenId;
      state.userToken = data.response.user.user_token;
      state.userData = { ...data.response.user }*/
      localStorage.setItem("authUser", JSON.stringify(data));
      state.connectedToken = data.tokenId;
      state.userToken = data.userToken;
      state.userData = { ...state.userData, ...data.userData };
    },
    logout(state) {
      localStorage.removeItem("authUser");
      state.userToken = null;
      state.connectedToken = null;
      state.userData = null;
      state.circleData = null;
      state.circleToken = "";
    },
    circleLogout(state) {
      state.circleData = null;
      state.circleToken = "";
    },
    updateUserData(state, user) {
      state.userData = { ...state.userData, ...user };
    },
    circleToken(state, circleToken) {
      state.circleToken = circleToken;
    },
    circleData(state, circleData) {
      state.circleData = circleData;
    }
  },
  actions: {
    logout: ({ commit }) => {
      commit("logout");
      //router.replace("/");
    },
    updateUser: ({ commit }, user) => {
      commit("updateUserData", user);
      //router.replace("/");
    },
    updateCircleData: ({ commit }, circleData) => {
      commit("circleData", circleData);
      //router.replace("/");
    },
    refreshTokenId: ({ commit }, authData) => {
      commit("authUser", authData);
    }
  },
  modules: {},
  getters: {
    isConnected: state => {
      if (!state.connectedToken) {
        return false;
      }
      if (!state.connectedToken.tokenId) {
        return false;
      }
      let now = new Date().getTime() / 1000;

      if (state.connectedToken.exp < now) {
        return false;
      }
      return true;
    },
    credentials: state => {
      return {
        user_token: state.userToken,
        token_id: state.connectedToken.tokenId
      };
    },
    adminValue: state => {
      return state.admin_levels[state.userData.admin_level].value;
    },
    userData: state => {
      return state.userData;
    }
  }
});
