<template>
  <div class="wrapper-content-column select-user">
    <h2 v-if="challenge_type != ''">
      {{ td("components.challenges.select_element.title.select_element")
      }}<span
        :class="['circle-empty', { clicked: getClickAllItems() }]"
        @click.stop="clickAllItems()"
      ></span>
    </h2>
    <div class="wrapper-content-column user-list">
      <div
        class="wrapper-content-row element-item"
        v-for="(item, index) in elements"
        :key="index"
      >
        <div class="wrapper-content-column element-s">
          <img
            src="/assets/img/action_s.png"
            alt=""
            v-if="item.undeclarative"
          />
        </div>
        <div class="wrapper-content-column bonus">
          <h2>{{ item.name }}</h2>
          <p class="pts">{{ item.points }} pts</p>
          <p class="stars">{{ item.stars }} étoiles</p>
        </div>
        <div class="wrapper-content-column comment">
          <p v-if="item.comment != ''">
            {{ item.comment }}
          </p>
          <!--<span v-if="item.comment != ''" @click.stop="showComment(item)"
            >+</span
          >-->
        </div>
        <div class="col-buttons">
          <span
            :class="['circle-empty', { clicked: getSelectedItem(item) }]"
            @click.stop="selectItem(item)"
          ></span>
        </div>
      </div>
    </div>
    <div class="wrapper-content-row container-buttons">
      <div class="bloc-periodes-footer">
        <button
          class=""
          @click.stop="
            callback({ type: 'click-valider', elements: selectedElements })
          "
        >
          {{ td("components.challenges.select_element.cta.valide") }}
        </button>
        <button class="negatif" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.challenges.select_element.cta.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "selectElement",
  props: [
    "callback",
    "group_token",
    "team_token",
    "challenge_type",
    "old_selected_elements",
    "selected_users",
    "selected_functions",
    "new_user_selected"
  ],
  data: () => {
    return {
      td,
      user: {},
      admin_level: "",
      circle: {},
      elements: [],
      selectedElements: [],
      comment_cliqued: null
    };
  },
  methods: {
    showComment(item) {
      this.comment_cliqued = item;
    },
    clickAllItems() {
      if (this.getClickAllItems() == true) {
        this.selectedElements = [];
      } else {
        this.selectedElements = [];
        for (let i = 0; i < this.elements.length; i++) {
          this.selectedElements.push(this.elements[i]);
        }
      }
    },
    getClickAllItems() {
      // eslint-disable-next-line no-unreachable
      for (let i = 0; i < this.elements.length; i++) {
        if (this.selectedElements.indexOf(this.elements[i]) < 0) {
          return false;
        }
      }
      return true;
    },
    selectItem(item) {
      let index = this.selectedElements.indexOf(item);
      if (index < 0) {
        this.selectedElements.push(item);
      } else {
        this.selectedElements.splice(index, 1);
      }
    },
    getSelectedItem(item) {
      return this.selectedElements.indexOf(item) > -1;
    },
    getelements() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = { circle_token: this.circle.circle_token || "///" };
      filters.challenge_type = this.challenge_type;
      filters.selected_users = this.selected_users;
      filters.selected_functions = this.selected_functions;
      filters.new_user_selected = this.new_user_selected;

      console.log("=================== filters =================");
      console.log(filters);
      console.log("====================================");

      const sendData = {
        credentials,
        filters
      };
      axios
        .post("/elements/for-challenge", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            context.elements = data.response.elements;
            store.dispatch("refreshTokenId", authData);

            context.elements.forEach(elem1 => {
              context.old_selected_elements.forEach(elem2 => {
                if (elem1.element_token == elem2.element_token) {
                  context.selectedElements.push(elem1);
                }
              });
            });
            //context.callback({ type: "loaded" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
  },
  mounted() {
    axios;
    sha256;
    this.getelements();
  }
};
</script>
