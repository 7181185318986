export function valideCircle(circle, circle_init) {
  
  
  
  

  if (circle.name.trim() == "") {
    return false;
  }

  if (circle.name.trim() == circle_init.name.trim()) {
    return false;
  }
  return true;
}

export function valideCeo(ceo, ceo_init) {
  
  
  
  

  let err_msg = "";

  for (const key in ceo_init) {
    if (key == "gender") {
      continue;
    }
    if (key == "name") {
      continue;
    }
    if (key == "send_password") {
      continue;
    }
    if (key == "password") {
      continue;
    }
    if (key == "confirm_email") {
      continue;
    }
    if (ceo[key].trim() == ceo_init[key].trim()) {
      err_msg =key + " - Vous devez compléter le directeur avant d'enregistrer";
      return err_msg;
    }
  }

  /*let patern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,2000}$/;
  if (!ceo.password.trim().match(patern)) {
    err_msg =
      "Attention, le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre";

    return err_msg;
  }*/

  // eslint-disable-next-line no-useless-escape
  let patern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!ceo.email.trim().match(patern)) {
    err_msg = "Attention, cet email ne semble pas valide";

    return err_msg;
  }

  if(ceo.email!=ceo.confirm_email)
  {
      err_msg = "Attention, vous devez confirmer l'email correctement";

    return err_msg;
  }

  return err_msg;
}
