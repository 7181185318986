<template>
  <div class="wrapper-content-column select-user">
    <h2 v-if="challenge_type != ''">
      {{ td("components.challenges.select_functions.title.select_functions")
      }}<span
        :class="['circle-empty', { clicked: getClickAllItems() }]"
        @click.stop="clickAllItems()"
      ></span>
    </h2>
    <div class="wrapper-content-column user-list">
      <div
        class="wrapper-content-row element-item"
        v-for="(item, index) in functions"
        :key="index"
        style="max-height:45px;min-height:45px;"
      >
        <div class="wrapper-content-column bonus  ">
          <h2 style="margin-left:20px">{{ item }}</h2>
        </div>
        <div class="col-buttons">
          <span
            :class="['circle-empty', { clicked: getSelectedItem(item) }]"
            style="left: 270px; top:15px;"
            @click.stop="selectItem(item)"
          ></span>
        </div>
      </div>
    </div>
    <div class="wrapper-content-row container-buttons">
      <div class="bloc-periodes-footer">
        <button
          class=""
          @click.stop="
            callback({ type: 'click-valider', elements: selectedFunctions })
          "
        >
          {{ td("components.challenges.select_functions.cta.valide") }}
        </button>
        <button class="negatif" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.challenges.select_functions.cta.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "selectFunctions",
  props: [
    "callback",
    "group_token",
    "team_token",
    "challenge_type",
    "old_selected_functions",
    "selected_users"
  ],
  data: () => {
    return {
      td,
      user: {},
      admin_level: "",
      circle: {},
      functions: [],
      selectedFunctions: [],
      comment_cliqued: null
    };
  },
  methods: {
    showComment(item) {
      this.comment_cliqued = item;
    },
    clickAllItems() {
      if (this.getClickAllItems() == true) {
        this.selectedFunctions = [];
      } else {
        this.selectedFunctions = [];
        for (let i = 0; i < this.functions.length; i++) {
          this.selectedFunctions.push(this.functions[i]);
        }
      }
    },
    getClickAllItems() {
      // eslint-disable-next-line no-unreachable
      for (let i = 0; i < this.functions.length; i++) {
        if (this.selectedFunctions.indexOf(this.functions[i]) < 0) {
          return false;
        }
      }
      return true;
    },
    selectItem(item) {
      let index = this.selectedFunctions.indexOf(item);
      if (index < 0) {
        this.selectedFunctions.push(item);
      } else {
        this.selectedFunctions.splice(index, 1);
      }
    },
    getSelectedItem(item) {
      return this.selectedFunctions.indexOf(item) > -1;
    },
    getelements() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = { circle_token: this.circle.circle_token || "///" };
      filters.challenge_type = this.challenge_type;
      filters.selected_users = this.selected_users;

      const sendData = {
        credentials,
        filters
      };
      axios
        .post("/elements/for-challenge", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            context.functions = data.response.functions;
            store.dispatch("refreshTokenId", authData);

            //context.callback({ type: "loaded" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
  },
  mounted() {
    axios;
    sha256;
    this.functions = this.circle.params.functions;

    //this.getelements();
    this.functions.forEach(elem1 => {
      this.old_selected_functions.forEach(elem2 => {
        if (elem1 == elem2) {
          this.selectedFunctions.push(elem1);
        }
      });
    });
  }
};
</script>
