<template>
  <div class="wrapper-content">
    <div class="column-functions" v-if="functions_loaded">
      <h2>
        {{ td("views.params.params_functions.title.functions") }}
        <span
          class="plus"
          @click.stop="clickHandler({ type: 'click-plus' })"
          v-if="$store.getters.adminValue > 4"
          >+</span
        >
      </h2>
      <div
        class="function-item"
        v-for="(item, index) in functions"
        :key="index"
      >
        <p>{{ item }}</p>
        <div
          :class="['pen', { empty: $store.getters.adminValue < 8 }]"
          @click.stop="editFunction(item)"
        ></div>
        <div
          :class="[
            'cross',
            {
              empty: getEmptyCross(item)
            }
          ]"
          @click.stop="deleteFunction(item)"
        ></div>
      </div>
      <div class="overlay" v-if="plus_clicked"></div>
    </div>
    <div class="new-function" v-if="plus_clicked">
      <h2>
        {{ td("views.params.params_functions.add_function") }}
      </h2>

      <div class="bloc-content-circle set-position-relative">
        <h3>{{ td("views.params.params_functions.function_name") }}</h3>
        <input
          v-if="editable == 'name'"
          ref="name"
          type="text"
          v-model="new_function.name"
          class="p"
          @blur="
            editable = '';
            testEmptyContent('name');
          "
        />
        <p
          class="hoverable"
          :data-hover="td('views.params.params_functions.data_hover.name')"
          v-else
          @click="
            setPreviousContent('name');
            editable = 'name';
          "
        >
          {{ new_function.name }}
        </p>

        <div class="footer-content">
          <button class="" @click.stop="saveNewFunction">
            {{ td("views.params.params_functions.valide") }}
          </button>
          <button class="negatif" @click.stop="cancelHandler">
            {{ td("views.params.params_functions.cancel") }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="container-alert" v-if="show_confirm_box === true">
        <confirmBox
          :msg1="msg_confirm1"
          :msg2="msg_confirm2"
          :title="title_confirm"
          :callback="confirmCallback"
          :delay="confirm_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import { td } from "../../traductions/traductionsHelpers";
import confirmBox from "./../../components/alertBoxes/confBox";
export default {
  name: "ParamsFunctions",
  components: { confirmBox },
  data: () => {
    return {
      td,
      //confirm box
      show_confirm_box: false,
      msg_confirm1: td("views.params.params_functions.msg_confirm1"),
      msg_confirm2: td("views.params.params_functions.msg_confirm2"),
      title_confirm: td("views.params.params_functions.title_confirm"),
      confirm_delay: 0,
      item_to_delete: "",
      ///
      show_alert_box: false,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      functions: [],
      plus_clicked: false,
      editable: "",
      functions_loaded: false,
      used_functions: [],
      new_function: {
        name: td("views.params.params_functions.new_function.name")
      },
      new_function_init: {
        name: td("views.params.params_functions.new_function_init.name")
      },
      previousContent: {
        new_function: {}
      }
    };
  },
  methods: {
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "click-valider") {
        this.doDeleteFunction(this.item_to_delete);
        this.show_confirm_box = false;
        this.item_to_delete = "";
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
    },
    getEmptyCross(item) {
      // $store.getters.adminValue < 8 &&
      //    used_functions.indexOf(item) > -1
      if (this.$store.getters.adminValue <= 4) {
        return true;
      }
      item;

      if (this.used_functions.indexOf(item) > -1) {
        return true;
      }
    },
    cancelHandler() {
      this.new_function = { ...this.new_function_init };
      this.plus_clicked = false;
      this.getFunctions();
    },
    clickHandler() {
      this.plus_clicked = true;
    },
    editFunction(item) {
      let index = this.functions.indexOf(item);
      index;
      /*if (index >= 0) {
        this.functions.splice(index, 1);
      }*/
      this.new_function.name = item;
      this.new_function.old_name = item;
      this.plus_clicked = true;
    },
    setPreviousContent(key) {
      this.previousContent.new_function[key] = this.new_function[key];
      this.new_function[key] = "";
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_function[key].trim() == "") {
        this.new_function[key] = this.previousContent.new_function[key];
      }
    },
    deleteFunction(item) {
      this.item_to_delete = item;
      this.show_confirm_box = true;
    },
    doDeleteFunction(item) {
      this.itemToDelete = "";
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      if (!this.functions) {
        this.functions = [];
      }
      let index = this.functions.indexOf(item);
      {
        if (index >= 0) {
          this.functions.splice(index, 1);
        }
      }

      let filters = { circle_token: this.circle.circle_token };

      let sendData = { credentials, functions: this.functions, filters };

      axios
        .post("/circles/update/new-function", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.plus_clicked = false;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNewFunction() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      if (this.new_function.old_name) {
        if (this.new_function.old_name != "") {
          let index = this.functions.indexOf(this.new_function.old_name);
          if (index >= 0) {
            this.functions.splice(index, 1);
          }
        }
        delete this.new_function.old_name;
      }

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      if (!this.functions) {
        this.functions = [];
      }

      this.functions.push(this.new_function.name);

      let filters = { circle_token: this.circle.circle_token };

      let sendData = { credentials, functions: this.functions, filters };

      axios
        .post("/circles/update/new-function", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.plus_clicked = false;
            context.new_function = { ...context.new_function_init };

            setTimeout(() => {
              context.getFunctions();
            }, 150);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getFunctions() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = { credentials, filters };

      axios
        .post("/users/used_functions", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.plus_clicked = false;
            context.used_functions = data.response.functions;
            context.functions_loaded = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    this.getFunctions();
  }
};
</script>
