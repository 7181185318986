<template>
  <div class="wrapper-content-column select-user">
    <h2 v-if="level == 'ceo_manager'">
      {{ td("components.catalog.select_users.select_groups")
      }}<span
        :class="['circle-empty', { clicked: getClickAllItems() }]"
        @click.stop="clickAllItems()"
      ></span>
    </h2>
    <h2 v-if="level == 'group_manager'">
      {{ td("components.catalog.select_users.select_teams")
      }}<span
        :class="['circle-empty', { clicked: getClickAllItems() }]"
        @click.stop="clickAllItems()"
      ></span>
    </h2>
    <h2 v-if="level == 'team_manager'">
      {{ td("components.catalog.select_users.select_sales")
      }}<span
        :class="['circle-empty', { clicked: getClickAllItems() }]"
        @click.stop="clickAllItems()"
      ></span>
    </h2>
    <div class="wrapper-content-column user-list">
      <div
        class="user-item"
        v-for="(item, index) in users"
        :key="index"
        @click.stop=""
      >
        <div :class="['container-avatar-body']">
          <div class="col-avatar">
            <img :src="getProfilPicture(item)" alt="" />
          </div>
          <div class="col-body">
            <h2>{{ item.firstname + " " + item.lastname }}</h2>
            <h3>{{ item.functions[0] }}</h3>
            <h4 v-if="level == 'ceo_manager'">{{ item.group_name }}</h4>
            <h4 v-if="level == 'group_manager'">{{ item.team_name }}</h4>
            <h4 v-if="level == 'team_manager'">{{ item.team_name }}</h4>
          </div>
        </div>
        <div class="col-buttons">
          <span
            :class="['circle-empty', { clicked: getSelectedItem(item) }]"
            @click.stop="selectItem(item)"
          ></span>
        </div>
      </div>
    </div>
    <div class="wrapper-content-row container-buttons">
      <div class="bloc-periodes-footer">
        <button
          class=""
          @click.stop="callback({ type: 'click-valider', users: slectedUsers })"
        >
          {{ td("components.alertbox.valide_cta.valide") }}
        </button>
        <button class="negatif" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.catalog.update_element.cta.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { td } from "./../../traductions/traductionsHelpers";
import axios from "axios";
import { sha256 } from "js-sha256";
export default {
  name: "selectUser",
  props: [
    "callback",
    "group_token",
    "team_token",
    "level",
    "old_selected_users"
  ],
  data: () => {
    return {
      user: {},
      admin_level: "",
      circle: {},
      users: [],
      slectedUsers: []
    };
  },
  methods: {
    td,
    clickAllItems() {
      if (this.getClickAllItems() == true) {
        this.slectedUsers = [];
      } else {
        this.slectedUsers = [];
        for (let i = 0; i < this.users.length; i++) {
          this.slectedUsers.push(this.users[i]);
        }
      }
    },
    getClickAllItems() {
      // eslint-disable-next-line no-unreachable
      for (let i = 0; i < this.users.length; i++) {
        if (this.slectedUsers.indexOf(this.users[i]) < 0) {
          return false;
        }
      }
      return true;
    },
    selectItem(item) {
      let index = this.slectedUsers.indexOf(item);
      if (index < 0) {
        this.slectedUsers.push(item);
      } else {
        this.slectedUsers.splice(index, 1);
      }
    },
    getSelectedItem(item) {
      return this.slectedUsers.indexOf(item) > -1;
    },
    getProfilPicture(item) {
      let url = "/assets/img/profil.png";
      if (item.picture.trim() == "") {
        url = "/assets/img/profil.png";
      } else {
        url = axios.defaults.baseURL + "/images/" + item.picture;
      }
      return url;
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = { circle_token: this.circle.circle_token || "///" };
      if (this.level == "ceo_manager") {
        filters = { ...filters, admin_level: "group_manager" };
      } else if (this.level == "group_manager") {
        filters = {
          ...filters,
          admin_level: "team_manager",
          group_token: this.group_token
        };
      } else if (this.level == "team_manager") {
        filters = {
          ...filters,
          admin_level: "sale",
          team_token: this.team_token
        };
      }

      const sendData = {
        credentials,
        filters
      };
      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            context.users = data.response.users;
            store.dispatch("refreshTokenId", authData);

            context.users.forEach(elem1 => {
              context.old_selected_users.forEach(elem2 => {
                if (elem1.user_token == elem2.user_token) {
                  context.slectedUsers.push(elem1);
                }
              });
            });
            //context.callback({ type: "loaded" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
  },
  mounted() {
    axios;
    sha256;
    this.getUsers();
  }
};
</script>
