<template>
  <main>
    <div class="wrapper circles-like edit-user">
      <section class="large-content circle-body ">
        <div class="large-content-column">
          <div class="body-content set-position-relative">
            <div class="bloc-content-profil edit-user-image ">
              <img
                :src="getUserLogo()"
                alt=""
                class="header-img-profil"
                id="user_image"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
            <div class="bloc-content-profil">
              <h2>{{ new_user.firstname + " " + new_user.lastname }}</h2>
              <h3>{{ new_user.function }}</h3>
              <p>{{ new_user.circle_name }}</p>
              <p>{{ new_user.maxime }}</p>
            </div>
            <div class="bloc-content-profil moyenne">
              <h2>{{ td("views.teams.edit_user_sale.average_company") }}</h2>
              <h3>
                <span class="pctage">0%</span
                >{{ td("views.teams.edit_user_sale.average_company_goal") }}
              </h3>
            </div>
            <div :class="{ 'overlay-block': new_user.user_token }"></div>
          </div>
          <div class="footer-content">
            <button
              class=""
              style="width:165px;height:42px"
              @click="updateProfil"
            >
              {{ td("views.teams.edit_user_sale.cta.valide") }}
            </button>
            <button
              class="negatif"
              style="width:105px;height:42px"
              @click="cancelHandler"
            >
              {{ td("views.teams.edit_user_sale.cta.cancel") }}
            </button>
          </div>
        </div>
        <div class="large-content-column col-3  set-position-relative">
          <div class="wrapper">
            <div class="edit-user-bottom">
              <div class="column-content-circle">
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.gender") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.edit_user_sale.gender.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="new_user.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.edit_user_sale.gender.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="new_user.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.name") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="new_user.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_sale.name.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ new_user.lastname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.email") }}</h3>
                  <p
                    class=""
                    :data-hover="
                      td('views.teams.edit_user_sale.email.placeholder')
                    "
                  >
                    {{ new_user.email }}
                  </p>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>
                    {{ td("views.teams.edit_user_sale.objectif_period") }}
                  </h3>
                  <p class="">
                    {{ objectifs_period }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>

                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.internal_id") }}</h3>
                  <input
                    v-if="editable == 'internal_id'"
                    ref="internal_id"
                    type="text"
                    v-model="new_user.internal_id"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('internal_id');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_sale.internal_id.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('internal_id');
                      editable = 'internal_id';
                    "
                  >
                    {{ new_user.internal_id }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle"></div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="new_user.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_sale.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ new_user.firstname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="new_user.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_sale.phone.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ new_user.phone }}
                  </p>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.edit_user_sale.objectifs") }}</h3>
                  <input
                    v-if="editable == 'objectif'"
                    ref="objectif"
                    type="text"
                    v-model="new_user.objectif"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('objectif');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user_sale.objectifs.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('objectif');
                      editable = 'objectif';
                    "
                  >
                    {{ new_user.objectif }}
                  </p>
                </div>

                <div class="bloc-content-circle"></div>
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user_sale.language")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user_sale.language")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        new_user.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.edit_user_sale.main_function") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_main_function_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_main_function_clicked = !drop_down_main_function_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        main_function_selected ||
                          td("views.teams.edit_user_sale.main_function")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_main_function_clicked = !drop_down_main_function_clicked
                      "
                    >
                      {{
                        main_function_selected ||
                          td("views.teams.edit_user_sale.main_function")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in functions_options"
                      :key="key"
                      @click="
                        drop_down_main_function_clicked = false;
                        main_function_selected = option;
                        setPreviousContent('');
                      "
                    >
                      {{ option }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.edit_user_sale.functions") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_other_function_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_other_function_clicked = !drop_down_other_function_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{ td("views.teams.edit_user_sale.function")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_other_function_clicked = !drop_down_other_function_clicked
                      "
                    >
                      {{ td("views.teams.edit_user_sale.function")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in getOtherFunctions()"
                      :key="key"
                      @click="
                        setOtherFunction(option);
                        setPreviousContent('');
                      "
                      :class="{
                        'function-selected': getFunctionIsSelected(option)
                      }"
                    >
                      {{ option }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user_sale.profiles") }}</h3>
                  <p class="profil-level" v-if="profils.ceo_manager.length">
                    {{ td("views.teams.edit_user_sale.ceos") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.ceo_manager"
                    :key="key + profil.circle_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.circle_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.group_manager.length">
                    {{ td("views.teams.edit_user_sale.resps") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.group_manager"
                    :key="key + profil.group_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.group_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.team_manager.length">
                    {{ td("views.teams.edit_user_sale.tls") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.team_manager"
                    :key="key + profil.team_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.sale.length">
                    {{ td("views.teams.edit_user_sale.sales") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.sale"
                    :key="key + profil.team_name + '_'"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                </div>

                <div class="bloc-content-circle"></div>
              </div>
            </div>
          </div>
          <!--<div :class="{ 'overlay-block': new_user.user_token }"></div>-->
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import alertBox from "./../../components/alertBoxes/alertBox";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { sha256 } from "js-sha256";
import { td } from "../../traductions/traductionsHelpers";

// eslint-disable-next-line no-unused-vars
import { valideUser } from "./../../assets/scripts/users";

export default {
  name: "EditUserSale",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      objectifs_period: "",
      team: {},
      team_token: "",
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      new_users_options: [],
      new_user_selected: {},
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      //
      drop_down_main_function_clicked: false,
      drop_down_other_function_clicked: false,
      functions_options: [],
      main_function_selected: "",
      //
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {}, //le user qui est connecté, pas celui que l'on crée
      profils: {
        ceo_manager: [],
        group_manager: [],
        team_manager: [],
        sale: []
      },
      new_user_init: {
        name: td("views.teams.edit_user_sale.new_user_init.name"),
        firstname: td("views.teams.edit_user_sale.new_user_init.firstname"),
        lastname: td("views.teams.edit_user_sale.new_user_init.lastname"),
        email: td("views.teams.edit_user_sale.new_user_init.email"),
        confirm_email: td(
          "views.teams.edit_user_sale.new_user_init.confirm_email"
        ),
        function: td("views.teams.edit_user_sale.new_user_init.function"),
        functions: [],
        phone: td("views.teams.edit_user_sale.new_user_init.phone"),
        gender: td("views.teams.edit_user_sale.new_user_init.gender"),
        language: td("views.teams.edit_user_sale.new_user_init.language"),
        internal_id: td("views.teams.edit_user_sale.new_user_init.internal_id")
      },
      new_user: {},
      admin_level: "",
      previousContent: {
        new_user: {}
      }
    };
  },
  methods: {
    getOtherFunctions() {
      let tab = [];
      for (var i = 0; i < this.functions_options.length; i++) {
        if (this.functions_options[i] != this.main_function_selected) {
          tab.push(this.functions_options[i]);
        }
      }
      return tab;
    },
    getFunctionIsSelected(f) {
      if (f == this.main_function_selected) {
        return true;
      }
      return this.new_user.functions.indexOf(f) > -1;
    },
    setOtherFunction(f) {
      if (this.new_user.functions.indexOf(f) == -1) {
        this.new_user.functions.push(f);
      } else {
        if (f == this.main_function_selected) {
          return;
        }
        let index = this.new_user.functions.indexOf(f);
        this.new_user.functions.splice(index, 1);
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    cancelHandler() {
      this.$router.go("-1");
    },
    setPreviousContent(key) {
      if (this.new_user.user_token) {
        // this.cancelHandler();
        // return;
      }
      this.previousContent.new_user[key] = this.new_user[key];

      if (this.new_user[key] == this.new_user_init[key]) {
        this.new_user[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_user[key].trim() == "") {
        this.new_user[key] = this.previousContent.new_user[key];
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("user_image");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return td("views.teams.edit_user_sale.get_selected_username");
      }
      if (!this.new_user.user_token) {
        return td("views.teams.edit_user_sale.get_selected_username");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return td("views.teams.edit_user_sale.get_selected_username");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },

    getTeam() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        team_token: this.team_token,
        circle_token: this.$store.state.circleToken
      };
      let sendData = { credentials, filters };

      axios
        .post("/teams/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /teams/by ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            /*let scratchArray = data.response.users.map((obj) => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });*/

            let team = data.response.teams[0];

            /*let reduced = Array.from(
              new Set(groups.map((a) => a.group_token))
            ).map((group_token) => {
              return groups.find((a) => a.group_token === group_token);
            });*/

            context.team = team;
            switch (team.objectifs_period) {
              case "year":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.year"
                );
                break;
              case "sem":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.semester"
                );
                break;
              case "quadri":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.quadri"
                );
                break;
              case "trim":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.trim"
                );
                break;
              case "month":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.month"
                );
                break;
              case "week":
                context.objectifs_period = td(
                  "views.teams.edit_user_sale.week"
                );
                break;

              default:
                break;
            }
            store.commit("authUser", authData);
            context.getUsers();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        user_token: this.$route.query.ut,
        circle_token: this.$store.state.circleToken
      };
      let options = { profils: 1 };
      let sendData = { credentials, filters, options };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /users/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });

            context.new_user = scratchArray[0];

            if (!context.new_user.internal_id) {
              context.new_user.internal_id = context.new_user_init.internal_id;
            }

            context.main_function_selected = context.new_user.functions[0];

            let prfls = context.new_user.profils;
            for (let i = 0; i < prfls.length; i++) {
              if (prfls[i].admin_level == "ceo_manager") {
                context.profils.ceo_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "group_manager") {
                context.profils.group_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "team_manager") {
                context.profils.team_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "sale") {
                context.profils.sale.push(prfls[i]);
              }
            }

            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    updateProfil() {
      this.drop_down_other_function_clicked = false;

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      sha256;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      if (isNaN(this.new_user.objectif)) {
        this.msg_alert = td("views.teams.edit_user_sale.msg_alert3");
        this.show_alert_box = true;
        return;
      }

      let mf = this.main_function_selected;
      let index = this.new_user.functions.indexOf(mf);
      if (index > -1) {
        this.new_user.functions.splice(index, 1);
      }
      this.new_user.functions.unshift(mf);

      let sendData = { credentials };
      let user = { ...this.new_user };

      if (user.internal_id == this.new_user_init.internal_id) {
        user.internal_id = "";
      }

      //user.functions[0] = user.function;

      sendData.user = user;

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/users/update/different_user", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.msg_alert = td("views.teams.edit_user_sale.msg_alert1");
            context.show_alert_box = true;

            store.dispatch("refreshTokenId", authData);
            setTimeout(() => {
              context.$router.go(-1);
            }, 3100);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.msg_alert = td("views.teams.edit_user_sale.msg_alert2");
            context.show_alert_box = true;
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    this.functions_options = this.$store.state.circleData.params.functions;

    this.team_token = this.$route.query.team_token;
    this.group_token = this.$route.query.group_token;

    this.getTeam();

    //this.getUsers();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
