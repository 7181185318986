<template>
  <div class="body">
    <headerComponent />
    <main class="wrapper-catalogue">
      <div class="menu-column">
        <router-link
          to="/reporting/actions"
          tag="div"
          class="menu-item"
          active-class="selected"
          v-if="$store.getters.adminValue >= 2"
          ><p>{{ td("views.params.reporting.cta.actions") }}</p></router-link
        >
      </div>
      <router-view />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import headerComponent from "../../components/header/headerComponent";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "Reporting",
  components: {
    headerComponent
  },
  data: () => {
    return {
      td,
      admin_levels: {},
      user: {},
      admin_level: ""
    };
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
  }
};
</script>
