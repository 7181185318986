<template>
  <div class="wrapper-content-column challenges">
    <section
      class="large-content top-bar"
      v-if="$store.getters.adminValue >= 2"
    >
      <div class="large-content-column col-gauche">
        <h2>
          {{ td("views.challenges.challenges_inter_team.select_group") }}
        </h2>
      </div>
      <div class="large-content-column col-3">
        <div class="bloc-search">
          <h2>{{ td("views.challenges.challenges_inter_team.search") }}</h2>
          <div class="bottom-line">
            <v-select
              label="name"
              v-if="editable == 'search'"
              :placeholder="td('global.topcomponent.add_name')"
              :options="new_user_options"
              :components="{ OpenIndicator }"
              v-model="new_user_selected"
              @input="
                editable = '';
                setNewUserFromVSelect();
              "
            >
              <div slot="no-options">
                {{ td("views.challenges.challenges_inter_team.sorry_but") }}
              </div>
            </v-select>

            <p
              class="hoverable"
              :data-hover="td('global.topcomponent.add_name')"
              v-else
              @click="editable = 'search'"
            >
              {{ getSelectedUserName() }}
            </p>
            <img src="/assets/img/loupe.png" alt="" />
          </div>
        </div>
      </div>
      <div class="user-item" v-if="new_user_selected.user_token">
        <div class="container-avatar-body">
          <div class="col-avatar">
            <img :src="getUserLogo(new_user_selected)" alt="" id="user_image" />
          </div>
          <div class="col-body">
            <h2>
              {{
                new_user_selected.firstname + " " + new_user_selected.lastname
              }}
            </h2>
            <h3>{{ new_user_selected.functions[0] }}</h3>
            <h4>{{ new_user_selected.group_name }}</h4>
          </div>
        </div>
        <div class="col-buttons"></div>
      </div>
    </section>

    <div class="wrapper-content-row">
      <div class="column-items" v-if="new_user_selected.user_token">
        <h2>
          {{ td("views.challenges.challenges_inter_team.current_challenge") }}
          <span
            class="plus"
            @click.stop="clickHandler({ type: 'click-plus' })"
            v-if="addChallangeAllowed()"
            >+</span
          >
        </h2>
        <div
          class="wrapper-content-column challenge-item "
          v-for="(item, index) in challenges"
          :key="index"
        >
          <div class="wrapper-content-row top">
            <div class="wrapper-content-column">
              <h2>{{ item.name }}</h2>
              <h3>{{ getSubtitle(item) }}</h3>
              <div>
                <p>
                  {{ td("views.challenges.challenges_inter_team.start")
                  }}<span>{{ getDate(item.start_date) }}</span>
                </p>
                <p>
                  {{ td("views.challenges.challenges_inter_team.end")
                  }}<span>{{ getDate(item.end_date) }}</span>
                </p>
                <p>
                  {{
                    item.selected_users.length +
                      " " +
                      td(
                        "views.challenges.challenges_closed.participating_teams"
                      )
                  }}
                </p>
              </div>

              <div class="avatar">
                <img :src="getItemAvatar(item.owner_data)" />
              </div>
            </div>
            <div class="wrapper-content-column buttons">
              <span
                :class="['pen', { empty: isEmptyPen() }]"
                @click.stop="updateItem(item)"
              ></span>
              <span
                :class="['copy', { empty: isEmptyCross(item) }]"
                @click.stop="copyItem(item)"
              ></span>
              <span
                :class="['cross', { empty: isEmptyCross(item) }]"
                @click.stop="deleteItem(item)"
              ></span>
            </div>
          </div>
          <div class="wrapper-content-row bottom">
            <div>
              <img src="/assets/img/medaille.png" class="medaille " alt="" />
              <div
                class="wrapper-content-column participant"
                v-if="item.selected_users.length >= 1"
              >
                <h2>{{ td("views.challenges.challenges_inter_team.team") }}</h2>
                <div class="avatar-participant">
                  <img :src="getItemAvatar()" />
                </div>
                <p class="score">0</p>
              </div>
              <div class="wrapper-content-column participant" v-else></div>
              <div
                class="wrapper-content-column participant"
                v-if="item.selected_users.length >= 0"
              >
                <h2>{{ td("views.challenges.challenges_inter_team.sale") }}</h2>
                <div class="avatar-participant">
                  <img :src="getItemAvatar()" />
                </div>
                <p class="score">0</p>
              </div>
              <div class="wrapper-content-column participant" v-else></div>
              <div
                class="wrapper-content-column participant"
                v-if="item.selected_users.length >= 30"
              >
                <h2>{{ item.selected_users[2].team_name }}</h2>
                <div class="avatar-participant">
                  <img :src="getItemAvatar(item.selected_users[2])" />
                </div>
                <p class="score">0</p>
              </div>
              <div class="wrapper-content-column participant" v-else></div>
            </div>
          </div>
        </div>

        <div
          class="transparent-lightbox-bg"
          v-if="
            plus_cliqued == true ||
              update_cliqued == true ||
              show_cliqued == true
          "
        ></div>
        <div
          class="transparent-lightbox-bg"
          v-if="comment_cliqued != null"
        ></div>
        <div
          class="wrapper-content-column show-comment"
          v-if="comment_cliqued != null"
        >
          <p>
            {{ comment_cliqued.comment }}
          </p>
          <button @click.stop="comment_cliqued = null">ok</button>
        </div>
      </div>
      <newChallenge
        :callback="newChallengeCallback"
        :challenge_type="'inter_team'"
        :group_token="getGroupToken()"
        :team_token="getTeamToken()"
        :creator_token="getCreatorToken()"
        :selectedUsers="selectedUsers"
        :selectedElements="selectedElements"
        :item_to_copy="item_to_copy"
        :item_to_update="item_to_update"
        :item_to_show="item_to_show"
        v-if="plus_cliqued == true"
      />
    </div>

    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
    <transition name="fade">
      <div class="container-alert" v-if="show_confirm_box === true">
        <confirmBox
          :msg1="msg_confirm1"
          :msg2="msg_confirm2"
          :title="title_confirm"
          :callback="confirmCallback"
          :delay="confirm_delay"
        />
      </div>
    </transition>
    <div
      class="transparent-lightbox-bg"
      v-if="elements_plus_cliqued == true || users_plus_cliqued == true"
    ></div>
    <selectUsers
      :callback="selectedUsersCallback"
      :level="'group_manager'"
      :group_token="getGroupToken()"
      :team_token="getTeamToken()"
      :old_selected_users="selectedUsers"
      v-if="users_plus_cliqued == true"
    />
    <selectElements
      :callback="selectedElementsCallback"
      :challenge_type="'inter_team'"
      :group_token="getGroupToken()"
      :team_token="getTeamToken()"
      :old_selected_elements="selectedElements"
      :selected_users="selectedUsers"
      v-if="elements_plus_cliqued == true"
    />
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import { td } from "../../traductions/traductionsHelpers";
import alertBox from "./../../components/alertBoxes/alertBox";
import confirmBox from "./../../components/alertBoxes/confBox";
import newChallenge from "./../../components/challenges/newChallenge";
import selectElements from "./../../components/challenges/selectElements";
import selectUsers from "./../../components/challenges/selectUsers";
export default {
  name: "ChallengeInterTeam",
  components: {
    alertBox,
    selectUsers,
    selectElements,
    confirmBox,
    newChallenge
  },
  data: () => {
    return {
      td,
      comment_cliqued: null,
      plus_cliqued: false,
      update_cliqued: false,
      elements_plus_cliqued: false,
      users_plus_cliqued: false,
      item_to_delete: null,
      item_to_copy: null,
      item_to_show: null,
      item_to_update: null,
      show_cliqued: false,
      //alert box
      show_alert_box: false,
      msg_alert: td("views.challenges.challenges_inter_team.msg_alert"),
      alert_delay: 3000,
      //confirm box
      show_confirm_box: false,
      msg_confirm1: td("views.challenges.challenges_inter_team.msg_confirm1"),
      msg_confirm2: td("views.challenges.challenges_inter_team.msg_confirm2"),
      title_confirm: td("views.challenges.challenges_inter_team.title_confirm"),
      confirm_delay: 0,
      //
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      selectedUsers: [],
      selectedElements: [],
      new_user_options: [],
      new_user_selected: {},
      new_user_init: {
        name: td("views.challenges.challenges_inter_team.new_user_init.name"),
        firstname: td(
          "views.challenges.challenges_inter_team.new_user_init.firstname"
        ),
        lastname: td(
          "views.challenges.challenges_inter_team.new_user_init.lastname"
        ),
        email: td("views.challenges.challenges_inter_team.new_user_init.email"),
        confirm_email: td(
          "views.challenges.challenges_inter_team.new_user_init.confirm_email"
        ),
        function: td(
          "views.challenges.challenges_inter_team.new_user_init.function"
        ),
        phone: td("views.challenges.challenges_inter_team.new_user_init.phone"),
        gender: td(
          "views.challenges.challenges_inter_team.new_user_init.gender"
        ),
        language: td(
          "views.challenges.challenges_inter_team.new_user_init.language"
        )
      },
      editable: "",
      challenges: [],
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          elem.id = "toto";
          return elem;
        }
      }
    };
  },
  methods: {
    addChallangeAllowed() {
      if (this.$store.getters.adminValue > 4) {
        return true;
      }
      if (this.$store.getters.adminValue == 4) {
        if (this.new_user_selected.email == this.user.email) {
          return true;
        }
        return false;
      }
      return false;
    },
    getDate(time) {
      let monthes = [
        td("views.global.monthes.1"),
        td("views.global.monthes.2"),
        td("views.global.monthes.3"),
        td("views.global.monthes.4"),
        td("views.global.monthes.5"),
        td("views.global.monthes.6"),
        td("views.global.monthes.7"),
        td("views.global.monthes.8"),
        td("views.global.monthes.9"),
        td("views.global.monthes.10"),
        td("views.global.monthes.11"),
        td("views.global.monthes.12")
      ];
      let txt = "";
      let date = new Date(time);
      txt =
        date.getDate() +
        " " +
        monthes[date.getMonth()] +
        " " +
        date.getFullYear();
      return txt;
    },
    getSubtitle(item) {
      if (item.score_type == "max_points") {
        return td(
          "views.challenges.challenges_inter_team.get_subtitle.need_max_points"
        );
      }
      if (item.score_type == "max_stars") {
        return td(
          "views.challenges.challenges_inter_team.get_subtitle.need_max_stars"
        );
      }
      if (item.score_type == "max_elements") {
        return td(
          "views.challenges.challenges_inter_team.get_subtitle.need_max_elements"
        );
      }
      if (item.score_type == "first_to") {
        let txt = td(
          "views.challenges.challenges_inter_team.get_subtitle.first_to_get"
        );
        if (item.score_to_type == "points") {
          txt +=
            item.points_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.points");
        }
        if (item.score_to_type == "stars") {
          txt +=
            item.stars_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.stars");
        }
        if (item.score_to_type == "elements") {
          txt +=
            item.elements_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.elements");
        }
        return txt;
      }
      if (item.score_type == "score_to") {
        let txt = td(
          "views.challenges.challenges_inter_team.get_subtitle.need_to_go"
        );
        if (item.score_to_type == "points") {
          txt +=
            item.points_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.points");
        }
        if (item.score_to_type == "stars") {
          txt +=
            item.stars_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.stars");
        }
        if (item.score_to_type == "elements") {
          txt +=
            item.elements_to_value +
            td("views.challenges.challenges_inter_team.get_subtitle.elements");
        }
        return txt;
      }
    },
    getGroupToken() {
      // eslint-disable-next-line no-unreachable
      if (this.new_user.group_token) {
        return this.new_user.group_token;
      }
      if (this.user.group_token) {
        return this.user.group_token;
      }
      return "";
    },
    getTeamToken() {
      // eslint-disable-next-line no-unreachable
      if (this.new_user.team_token) {
        return this.new_user.team_token;
      }

      if (this.user.team_token) {
        return this.user.team_token;
      }
      return "";
    },
    getCreatorToken() {
      return "";
      // eslint-disable-next-line no-unreachable
      if (this.new_user.user_token) {
        return this.new_user.user_token;
      }

      if (this.user.user_token) {
        return this.user.user_token;
      }
      return "";
    },
    isGroupManager() {
      if (this.$store.getters.adminValue == 4) {
        return true;
      }
      return false;
    },
    getItemAvatar(user) {
      if (!user) {
        return "/assets/img/profil2.png";
      }
      if (user.picture) {
        if (user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + user.picture;
      }
      return "/assets/img/profil2.png";
    },
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "click-valider") {
        this.doDeleteItem(this.item_to_delete);
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
    },
    showItem(item) {
      this.selectedUsers = item.selected_users;
      this.item_to_update = item;
      this.show_cliqued = true;
    },
    copyItem(item) {
      this.item_to_copy = item;
      this.selectedUsers = item.selected_users;
      this.selectedElements = item.selected_elements;
      this.plus_cliqued = true;
    },
    deleteItem(item) {
      if (!item) {
        return;
      }
      if (this.isEmptyCross(item)) {
        return;
      }
      this.item_to_delete = item;
      this.show_confirm_box = true;
    },
    updateItem(item) {
      if (!item) {
        return;
      }
      if (this.isEmptyPen(item)) {
        // return;
      }

      /*if (this.user.user_token == item.owner_token) {
        this.item_to_update = item;
        this.item_to_copy = null;
        this.item_to_show = null;
      } else {
        this.item_to_update = null;
        this.item_to_copy = null;
        this.item_to_show = item;
      }*/
      this.item_to_update = item;
      this.selectedUsers = item.selected_users;
      this.selectedElements = item.selected_elements;
      this.plus_cliqued = true;
    },
    showComment(item) {
      this.comment_cliqued = item;
    },
    isEmptyCross(item) {
      if (!item) {
        return false;
      }
      if (this.user.user_token != item.owner_token) {
        return true;
      }
      if (item.challenge_type != "inter_team") {
        return true;
      }
      return false;
    },
    isEmptyPen(item) {
      if (!item) {
        return false;
      }
      if (this.user.user_token != item.owner_token) {
        return true;
      }
      if (item.challenge_type != "inter_team") {
        return true;
      }
      return false;
    },
    selectedUsersCallback(obj) {
      if (obj.type == "click-valider") {
        /*if (obj.users.length) {
          for (let i = 0; i < obj.users.length; i++) {
            for (let j = 0; j < 10; j++) {
              this.selectedUsers.push(obj.users[i]);
            }
          }
        }*/
        this.selectedUsers = obj.users;
      } else {
        //this.selectedUsers = [];
      }
      this.elements_plus_cliqued = false;
      this.users_plus_cliqued = false;
    },
    selectedElementsCallback(obj) {
      if (obj.type == "click-valider") {
        /*if (obj.users.length) {
          for (let i = 0; i < obj.users.length; i++) {
            for (let j = 0; j < 10; j++) {
              this.selectedUsers.push(obj.users[i]);
            }
          }
        }*/
        this.selectedElements = obj.elements;
      } else {
        //.selectedUsers = [];
      }
      this.elements_plus_cliqued = false;
      this.users_plus_cliqued = false;
    },
    newChallengeCallback(obj) {
      this.show_cliqued = false;

      if (obj.type == "click-plus-users") {
        this.users_plus_cliqued = true;
        return;
      }
      if (obj.type == "click-plus-elements") {
        this.elements_plus_cliqued = true;
        return;
      }
      if (obj.type == "cancel") {
        this.elements_plus_cliqued = false;
        this.users_plus_cliqued = false;
        this.plus_cliqued = false;
        this.update_cliqued = false;
        this.selectedUsers = [];
        this.selectedElements = [];
        this.item_to_update = null;
        this.item_to_copy = null;
        this.item_to_show = null;
        return;
      }
      if (obj.type == "element-saved") {
        this.elements_plus_cliqued = false;
        this.users_plus_cliqued = false;
        this.plus_cliqued = false;
        this.update_cliqued = false;
        this.selectedUsers = [];
        this.selectedElements = [];
        this.item_to_update = null;
        this.item_to_copy = null;
        this.item_to_show = null;
        this.getChallenges();
        return;
      }
    },
    clickHandler(obj) {
      if (obj.type == "click-plus") {
        this.selectedUsers = [];
        this.plus_cliqued = true;
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      /*var output = document.getElementById("user_image");
      output.src = this.getUserLogo();*/
      this.getChallenges();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return this.td("global.topcomponent.add_name");
      }
      if (!this.new_user.user_token) {
        return this.td("global.topcomponent.add_name");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return this.td("global.topcomponent.add_name");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    getChallenges() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {};
      let url = "/challenges/by";

      if (this.user.admin_level == "ceo_manager") {
        filters = {
          challenge_type: "inter_team",
          group_token: this.new_user.group_token,
          circle_token: this.user.circle_token,
          status: "open"
        };
      }

      if (this.user.admin_level == "group_manager") {
        filters = {
          challenge_type: "inter_team",
          group_token: this.new_user.group_token,
          circle_token: this.user.circle_token,
          status: "open"
        };
      }

      if (this.user.admin_level == "team_manager") {
        let team_tokens = [];
        for (let i = 0; i < this.user.profils.length; i++) {
          const usr = this.user.profils[i];
          if (usr.admin_level == "team_manager") {
            team_tokens.push(usr.team_token);
          }
        }
        filters = {
          challenge_type: "inter_team",
          circle_token: this.user.circle_token,
          group_token: this.new_user.group_token,
          team_tokens: team_tokens,
          //user: this.user,
          status: "open"
        };
        url = "/challenges/of";
      }

      // eslint-disable-next-line no-unreachable
      let sendData = { credentials, filters };

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            context.challenges = data.response.challenges;

            console.log("====================================");
            console.log(context.challenges);
            console.log("====================================");
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    doDeleteItem(item) {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        owner_token: this.user.user_token,
        challenge_token: item.challenge_token
      };

      let sendData = { credentials, filters };

      axios
        .post("/challenges/delete", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            setTimeout(() => {
              context.getChallenges();
            }, 100);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getUsers(byEmail = false) {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let url = "/users/by";
      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        admin_level: "group_manager"
      };
      byEmail;
      /*if (this.user.admin_level == "team_manager") {
        filters.admin_level = "team_manager";
      }
      if (byEmail) {
        filters.email = this.user.email;
      }*/

      if (
        this.user.admin_level == "group_manager" ||
        this.user.admin_level == "team_manager"
      ) {
        filters = {
          circle_token: this.circle.circle_token,
          //je cherche les users appartenant à cet email (en dessous de lui)
          email: this.user.email,
          //le user parent est un group_manager, on cherche dans tous ses groupes, pas uniquement celui dans lequel il est connecté
          user_admin_level: "team_manager",
          //on cherche tous ses group_managers
          target_admin_level: "group_manager",
          //on les recherche par leur group_token
          find_by_key_token: "group_token"
        };
        url = "/users/of";
      }

      let sendData = { credentials, filters };

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            let key = "group_name";
            /*if (context.user.admin_level == "team_manager") {
              key = "team_name";
            }*/
            let users = [];
            let selected_groups = [];
            for (let i = 0; i < data.response.users.length; i++) {
              const usr = data.response.users[i];
              if (selected_groups.indexOf(usr.group_token) == -1) {
                selected_groups.push(usr.group_token);
                users.push(usr);
              }
            }

            let scratchArray = users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name =
                obj.firstname + " " + obj.lastname + " - " + obj[key];
              obj.key = Math.random();
              return newObj;
            });

            /*let reduced = Array.from(
              new Set(scratchArray.map((a) => a.email))
            ).map((email) => {
              return scratchArray.find((a) => a.email === email);
            });*/

            context.new_user_options = scratchArray;

            /*setTimeout(() => {
              context.getChallenges();
            }, 150);*/
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    if (
      this.user.admin_level == "group_manager" ||
      this.user.admin_level == "team_manager"
    ) {
      //this.new_user_selected = JSON.parse(JSON.stringify(this.user));
      //this.getChallenges();
      this.getUsers(true);
      return;
    }

    axios;
    sha256;

    //
    this.getUsers();
  }
};
</script>
