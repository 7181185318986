<template>
  <transition name="fade">
    <div class="header-popin">
      <div @click="clickHandler({ type: 'account' })">
        <img :src="getUserPicture()" alt="" class="header-img-profil" />
        <div>
          <p>{{ user.firstname + " " + user.lastname }}</p>
          <p>{{ td("components.header.header_popin.access_account") }}</p>
        </div>
      </div>
      <div
        v-if="
          admin_levels[admin_level].value >= 16 &&
            $store.state.circleToken != ''
        "
        @click="clickHandler({ type: 'click-unconect-circle' })"
      >
        <img src="/assets/img/logout.png" alt="" class="img-logout" />
        <div>
          <p>{{ td("components.header.header_popin.logout_company") }}</p>
        </div>
      </div>
      <div @click="clickHandler({ type: 'click-unconnect-profil' })">
        <img src="/assets/img/logout.png" alt="" class="img-logout" />
        <div>
          <p>{{ td("components.header.header_popin.logout") }}</p>
          <p>
            {{ td("components.header.header_popin.logout_info") }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import axios from "axios";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "popinHeader",
  props: ["callback"],
  data: () => {
    return {
      td,
      admin_levels: {},
      user: {},
      admin_level: "",
      admin_level_label: ""
    };
  },
  methods: {
    getUserPicture() {
      if (this.user.picture) {
        if (this.user.picture.trim() == "") {
          return "/assets/img/profil.png";
        }
        return axios.defaults.baseURL + "/images/" + this.user.picture;
      }
      return "/assets/img/profil.png";
    },
    clickHandler(obj) {
      this.callback(obj);
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.admin_level_label = this.admin_levels[this.admin_level].label;
  }
};
</script>
