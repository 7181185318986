import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store/index";

import Teams from "./../views/teams/Teams.vue";
import Params from "./../views/params/Params.vue";
import ParamsFunctions from "./../views/params/ParamsFunctions.vue";
import ParamsNotifications from "./../views/params/ParamsNotifications.vue";
import ParamsAffichage from "./../views/params/ParamsAffichage.vue";
import ParamsLabels from "./../views/params/ParamsLabels.vue";
import MessagePush from "./../views/params/Push.vue";

import TeamsList from "./../views/teams/List.vue";
import Circles from "./../views/teams/Circles.vue";
import NewUser from "./../views/teams/NewUser.vue";
import NewSale from "./../views/teams/NewSale.vue";
import EditUser from "./../views/teams/EditUser.vue";
import EditUserTeamManager from "./../views/teams/EditUserTeamManager.vue";
import EditUserSale from "./../views/teams/EditUserSale.vue";

import Catalogue from "./../views/catalogue/Catalogue.vue";
import CatalogueCeo from "./../views/catalogue/CatalogueCeo.vue";
import CatalogueGroup from "./../views/catalogue/CatalogueGroup.vue";
import CatalogueTeam from "./../views/catalogue/CatalogueTeam.vue";

import Challenges from "./../views/challenges/Challenges.vue";
import ChallengesInterGroup from "./../views/challenges/ChallengesInterGroup.vue";
import ChallengesInterTeam from "./../views/challenges/ChallengesInterTeam.vue";
import ChallengesInsideTeam from "./../views/challenges/ChallengesInsideTeam.vue";
import ChallengesByFunction from "./../views/challenges/ChallengesByFunction.vue";
import ChallengesClosed from "./../views/challenges/ChallengesClosed.vue";

import Reporting from "./../views/reporting/Reporting.vue";
import ReportingActions from "./../views/reporting/ReportingActions.vue";
import Account from "./../views/Account.vue";

import Login from "./../views/Login.vue";
import Reset from "./../views/Reset.vue";


Vue.use(VueRouter);

store;

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next("/teams/list");
      } else {
        next("/login");
      }
    },
    component: Teams,
  },
  {
    path: "/params",
    name: "Params",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        //next();
        next("/login");
      }
    },
    component: Params,
    children: [
      {
        path: "functions",
        name: "ParamsFunctions",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: ParamsFunctions,
      },
      {
        path: "notifications",
        name: "ParamsNotifications",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: ParamsNotifications,
      },
      {
        path: "affichage",
        name: "ParamsAffichage",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: ParamsAffichage,
      },
      {
        path: "labels",
        name: "ParamsLabels",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: ParamsLabels,
      },
      {
        path: "push",
        name: "MessagePush",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: MessagePush,
      },
    ]
  },
  {
    path: "/teams",
    redirect: "/teams/list",
    name: "Teams",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        next("/login");
      }
    },
    component: Teams,
    children: [
      {
        path: "list",
        name: "TeamsList",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: TeamsList,
      },
      {
        path: "new-user",
        name: "NewUser",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: NewUser,
      },
      {
        path: "new-sale",
        name: "NewSale",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: NewSale,
      },
      {
        path: "edit-user",
        name: "EditUser",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: EditUser,
      },
      {
        path: "edit-user-team-manager",
        name: "EditUserTeamManager",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: EditUserTeamManager,
      },
      {
        path: "edit-user-sale",
        name: "EditUserTeamSale",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next("/login");
          }
        },
        component: EditUserSale,
      },
      {
        path: "circles",
        name: "Circles",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            if (store.getters.adminValue < 16) {
              store.commit("logout");
              next("/login");
            } else {
              next();
            }
          } else {
            next("/login");
          }
        },
        component: Circles,
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        next("/login");
      }
    },
    component: Account,
  },
  {
    path: "/catalogue",
    name: "Catalogue",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        //next();
        next("/login");
      }
    },
    component: Catalogue,
    children: [
      {
        path: "ceo_manager",
        name: "CatalogueCeo",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: CatalogueCeo,
      },
      {
        path: "group_manager",
        name: "CatalogueGroup",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: CatalogueGroup,
      },
      {
        path: "team_manager",
        name: "CatalogueTeam",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            //next();
            next("/login");
          }
        },
        component: CatalogueTeam,
      },
    ]
  },
  {
    path: "/challenges",
    name: "Challenges",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        //next();
        next("/login");
      }
    },
    component: Challenges,
    children: [
      {
        path: "inter_group",
        name: "ChallengeInterGroup",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ChallengesInterGroup,
      },
      {
        path: "inter_Team",
        name: "ChallengeInterTeam",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ChallengesInterTeam,
      },
      {
        path: "inside_Team",
        name: "ChallengeInsideTeam",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ChallengesInsideTeam,
      },
      {
        path: "by_function",
        name: "ChallengesByFunction",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ChallengesByFunction,
      },
      {
        path: "closed",
        name: "ChallengesClosed",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ChallengesClosed,
      },
    ]
  },
  {
    path: "/reporting",
    name: "Reporting",
    beforeEnter: (to, from, next) => {
      if (store.getters.isConnected) {
        next();
      } else {
        //next("/login");
        next();
      }
    },
    component: Reporting,
    children:[

      {
        path: "actions",
        name: "ReportingActions",
        beforeEnter: (to, from, next) => {
          if (store.getters.isConnected) {
            next();
          } else {
            next();
            //next("/login");
          }
        },
        component: ReportingActions,
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: (to, from, next) => {
      if (!store.getters.isConnected) {
        next();
      } else {
        next("/login");
      }
    },
    component: Login,
  },
  {
    path: "/resetpassword/:link_token",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/*",
    /*beforeEnter: (to, from, next) => {
      next("/");
    }*/
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  
  
  
  
  if (to.name == "Circles") {
    next();
  } else if (to.name == "Login") {
    next();
  } else if (to.name == "Reset") {
    next();
  } else if (to.name == "Account") {
    next();
  } else if (to.name == "Params") {
    next();
  }else if (to.name == "Reporting") {
    next();
  } /*else if (to.name == "Challenges") {//a retirer après dev

    next();
  }else if (to.name == "ChallengesInterGroup") {//a retirer après dev

    next();
  }else if (to.name == "ChallengesInterTeam") {//a retirer après dev

    next();
  } */else if (store.state.circleToken == "") {
    //next();
    next("/teams/circles"); 
  } else {
    next();
  }
});

export default router;
