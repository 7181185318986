<template>
  <main>
    <div class="wrapper circles-like edit-user">
      <section class="large-content circle-body ">
        <div class="large-content-column">
          <div class="body-content set-position-relative">
            <div class="bloc-content-profil edit-user-image ">
              <img
                :src="getUserLogo()"
                alt=""
                class="header-img-profil"
                id="user_image"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
            <div class="bloc-content-profil">
              <h2>{{ new_user.firstname + " " + new_user.lastname }}</h2>
              <h3>{{ new_user.function }}</h3>
              <p>{{ new_user.circle_name }}</p>
              <p>{{ new_user.maxime }}</p>
            </div>
            <div class="bloc-content-profil moyenne">
              <h2>{{ td("views.teams.edit_user.average_company") }}</h2>
              <h3>
                <span class="pctage">0%</span
                >{{ td("views.teams.edit_user.average_company_goal") }}
              </h3>
            </div>
            <div
              :class="{ 'overlay-block': $store.getters.adminValue < 64 }"
            ></div>
          </div>
          <div class="footer-content">
            <button
              class=""
              style="width:165px;height:42px"
              @click="updateProfil"
            >
              {{ td("views.teams.edit_user.cta.valide") }}
            </button>
            <button
              class="negatif"
              style="width:105px;height:42px"
              @click="cancelHandler"
            >
              {{ td("views.teams.edit_user.cta.cancel") }}
            </button>
          </div>
        </div>
        <div class="large-content-column col-3  set-position-relative">
          <div class="wrapper">
            <div class="edit-user-bottom">
              <div class="column-content-circle">
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.gender") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.edit_user.gender.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="new_user.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.edit_user.gender.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="new_user.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.name") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="new_user.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.edit_user.name.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ new_user.lastname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.email") }}</h3>
                  <p
                    class=""
                    :data-hover="td('views.teams.edit_user.email.placeholder')"
                  >
                    {{ new_user.email }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div
                  class="bloc-content-circle"
                  v-if="
                    $store.getters.adminValue >= 4 &&
                      edit_user_type == 'group_manager'
                  "
                >
                  <h3>{{ td("views.teams.edit_user.group_name") }}</h3>
                  <input
                    v-if="editable == 'groupname'"
                    ref="groupname"
                    type="text"
                    v-model="new_user.group_name"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('groupname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.edit_user.group_name')"
                    v-else
                    @click="
                      setPreviousContent('groupname');
                      editable = 'groupname';
                    "
                  >
                    {{ new_user.group_name }}
                  </p>
                </div>
                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="new_user.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ new_user.firstname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="new_user.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.edit_user.phone.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ new_user.phone }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user.language")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.edit_user.language")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        new_user.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.function") }}</h3>
                  <input
                    v-if="editable == 'function'"
                    ref="function"
                    type="text"
                    v-model="new_user.function"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('function');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.edit_user.function.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('function');
                      editable = 'function';
                    "
                  >
                    {{ new_user.function }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.edit_user.profiles") }}</h3>
                  <p class="profil-level" v-if="profils.ceo_manager.length">
                    {{ td("views.teams.edit_user.ceos") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.ceo_manager"
                    :key="key + profil.circle_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.circle_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.group_manager.length">
                    {{ td("views.teams.edit_user.resps") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.group_manager"
                    :key="key + profil.group_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.group_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.team_manager.length">
                    {{ td("views.teams.edit_user.tls") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.team_manager"
                    :key="key + profil.team_name"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                  <p class="profil-level" v-if="profils.sale.length">
                    {{ td("views.teams.edit_user.sales") }}
                  </p>
                  <div
                    v-for="(profil, key) in profils.sale"
                    :key="key + profil.team_name + '_'"
                  >
                    <p class="profil-level-name">
                      {{ profil.team_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div :class="{ 'overlay-block': new_user.user_token }"></div>-->
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import alertBox from "./../../components/alertBoxes/alertBox";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { sha256 } from "js-sha256";

// eslint-disable-next-line no-unused-vars
import { valideUser } from "./../../assets/scripts/users";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "EditUser",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      new_users_options: [],
      new_user_selected: {},
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {}, //le user qui est connecté, pas celui que l'on crée
      profils: {
        ceo_manager: [],
        group_manager: [],
        team_manager: [],
        sale: []
      },
      new_user_init: {
        name: td("views.teams.edit_user.new_user_init.name"),
        firstname: td("views.teams.edit_user.new_user_init.firstname"),
        lastname: td("views.teams.edit_user.new_user_init.lastname"),
        email: td("views.teams.edit_user.new_user_init.email"),
        confirm_email: td("views.teams.edit_user.new_user_init.confirm_email"),
        function: td("views.teams.edit_user.new_user_init.function"),
        phone: td("views.teams.edit_user.new_user_init.phone"),
        gender: td("views.teams.edit_user.new_user_init.gender"),
        language: td("views.teams.edit_user.new_user_init.language")
      },
      new_user: {},
      admin_level: "",
      previousContent: {
        new_user: {}
      },
      edit_user_type: ""
    };
  },
  methods: {
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    cancelHandler() {
      this.$router.go("-1");
    },
    setPreviousContent(key) {
      if (this.new_user.user_token) {
        // this.cancelHandler();
        // return;
      }
      this.previousContent.new_user[key] = this.new_user[key];
      if (this.new_user[key] == this.new_user_init[key]) {
        this.new_user[key] = "";
      }

      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_user[key].trim() == "") {
        this.new_user[key] = this.previousContent.new_user[key];
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("user_image");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return td("views.teams.edit_user.get_selected_username");
      }
      if (!this.new_user.user_token) {
        return td("views.teams.edit_user.get_selected_username");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return td("views.teams.edit_user.get_selected_username");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        user_token: this.$route.query.ut,
        circle_token: this.$store.state.circleToken
      };
      let options = { profils: 1 };
      let sendData = { credentials, filters, options };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /users/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });

            context.new_user = scratchArray[0];

            let prfls = context.new_user.profils;
            for (let i = 0; i < prfls.length; i++) {
              if (prfls[i].admin_level == "ceo_manager") {
                context.profils.ceo_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "group_manager") {
                context.profils.group_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "team_manager") {
                context.profils.team_manager.push(prfls[i]);
              }
              if (prfls[i].admin_level == "sale") {
                context.profils.sale.push(prfls[i]);
              }
            }

            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },

    updateGroup() {
      if (this.new_user.group_name) {
        //return;
      }

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      sha256;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };
      let group = { ...this.new_user };

      sendData.group = group;

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/groups/update", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.dispatch("refreshTokenId", authData);
            //context.new_user.group_name = null;
            context.new_user.group_updated = true;
            setTimeout(() => {
              context.updateProfil();
            }, 150);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.msg_alert = td("views.teams.edit_user.msg_alert1");
            context.show_alert_box = true;
          }
        })
        .catch(function(error) {
          error;
        });
    },

    updateProfil() {
      if (this.new_user.group_name && !this.new_user.group_updated) {
        this.updateGroup();
        return;
      }

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      sha256;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };
      let user = { ...this.new_user };

      user.functions[0] = user.function;

      sendData.user = user;

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/users/update/different_user", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.msg_alert = td("views.teams.edit_user.msg_alert2");
            context.show_alert_box = true;

            store.dispatch("refreshTokenId", authData);
            setTimeout(() => {
              context.$router.go(-1);
            }, 3100);
          } else {
            /*store.dispatch("logout");
            router.replace("/login");*/
            router;
            context.msg_alert = td("views.teams.edit_user.msg_alert1");
            context.show_alert_box = true;
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    if (this.$route.query.type) {
      this.edit_user_type = this.$route.query.type;
    }

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    this.getUsers();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
