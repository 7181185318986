var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-element"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.add_element"))+" ")]),_c('div',{staticClass:"wrapper-content-row"},[_c('div',{staticClass:"wrapper-content-column"},[_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.element_name"))+" ")]),(_vm.editable == 'name')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_element.name),expression:"new_element.name"}],ref:"name",staticClass:"p",attrs:{"type":"text"},domProps:{"value":(_vm.new_element.name)},on:{"blur":function($event){_vm.editable = '';
            _vm.testEmptyContent('name');},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.new_element, "name", $event.target.value)}}}):_c('p',{staticClass:"hoverable",attrs:{"data-hover":_vm.td('components.catalog.newelement.data_hover.name')},on:{"click":function($event){_vm.setprevious_content('name');
            _vm.editable = 'name';}}},[_vm._v(" "+_vm._s(_vm.new_element.name)+" ")])]),_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.points"))+" ")]),(_vm.editable == 'points')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_element.points),expression:"new_element.points"}],ref:"points",staticClass:"p",attrs:{"type":"text"},domProps:{"value":(_vm.new_element.points)},on:{"blur":function($event){_vm.editable = '';
            _vm.testEmptyContent('points');},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.new_element, "points", $event.target.value)}}}):_c('p',{staticClass:"hoverable",attrs:{"data-hover":_vm.td('components.catalog.newelement.data_hover.points')},on:{"click":function($event){_vm.setprevious_content('points');
            _vm.editable = 'points';}}},[_vm._v(" "+_vm._s(_vm.new_element.points)+" ")])]),_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.photo_mandatory"))+" "),_c('span',{class:['circle', { clicked: _vm.new_element.need_picture == true }],on:{"click":function($event){$event.stopPropagation();_vm.new_element.need_picture = !_vm.new_element.need_picture}}})])])]),_c('div',{staticClass:"wrapper-content-column"},[_c('div',{staticClass:"bloc-content-circle set-position-relative"}),_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.stars"))+" ")]),(_vm.editable == 'stars')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_element.stars),expression:"new_element.stars"}],ref:"stars",staticClass:"p",attrs:{"type":"text"},domProps:{"value":(_vm.new_element.stars)},on:{"blur":function($event){_vm.editable = '';
            _vm.testEmptyContent('stars');},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.new_element, "stars", $event.target.value)}}}):_c('p',{staticClass:"hoverable",attrs:{"data-hover":_vm.td('components.catalog.newelement.data_hover.stars')},on:{"click":function($event){_vm.setprevious_content('stars');
            _vm.editable = 'stars';}}},[_vm._v(" "+_vm._s(_vm.new_element.stars)+" ")])]),_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.non_declarative"))+" "),_c('span',{class:[
              'circle',
              { clicked: _vm.new_element.undeclarative == true }
            ],on:{"click":function($event){$event.stopPropagation();_vm.new_element.undeclarative = !_vm.new_element.undeclarative}}})])])])]),_c('div',{staticClass:"wrapper-content-row"},[_c('div',{staticClass:"wrapper-content-column container-users"},[(_vm.level == 'ceo_manager')?_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.groups_concerned"))+" ")]):_vm._e(),(_vm.level == 'group_manager')?_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.teams_concerned"))+" ")]):_vm._e(),(_vm.level == 'team_manager')?_c('h3',[_vm._v(" "+_vm._s(_vm.td("components.catalog.newelement.sales_concerned"))+" ")]):_vm._e(),(_vm.selectedUsers.length > 0)?_c('div',{staticClass:"wrapper-content-row"},[_c('div',{staticClass:"wrapper-content-row container-avatars"},_vm._l((_vm.selectedUsers),function(item,index){return _c('div',{key:index,staticClass:"col-avatar"},[_c('img',{attrs:{"src":_vm.getProfilPicture(item),"alt":""}})])}),0),_c('div',{staticClass:"wrapper-content-row container-plus"},[_c('span',{staticClass:"plus-users",on:{"click":function($event){$event.stopPropagation();return _vm.callback({ type: 'click-plus' })}}},[_vm._v("+")])])]):_c('div',{staticClass:"wrapper-content-row container-plus"},[_c('span',{staticClass:"plus-users",on:{"click":function($event){$event.stopPropagation();return _vm.callback({ type: 'click-plus' })}}},[_vm._v("+")])])])]),_c('div',{staticClass:"wrapper-content-row"},[_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(_vm._s(_vm.td("components.catalog.newelement.optional_comment")))]),(_vm.editable == 'comment')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_element.comment),expression:"new_element.comment"}],ref:"comment",staticClass:"p",attrs:{"type":"text"},domProps:{"value":(_vm.new_element.comment)},on:{"blur":function($event){_vm.editable = '';
          _vm.testEmptyContent('comment');},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.new_element, "comment", $event.target.value)}}}):_c('p',{staticClass:"hoverable",attrs:{"data-hover":_vm.td('components.catalog.newelement.data_hover.comment')},on:{"click":function($event){_vm.setprevious_content('comment');
          _vm.editable = 'comment';}}},[_vm._v(" "+_vm._s(_vm.new_element.comment)+" ")])])]),_c('div',{staticClass:"wrapper-content-row container-buttons"},[_c('div',{staticClass:"bloc-periodes-footer"},[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.saveElement()}}},[_vm._v(" "+_vm._s(_vm.td("components.alertbox.valide_cta.valide"))+" ")]),_c('button',{staticClass:"negatif",on:{"click":function($event){$event.stopPropagation();return _vm.callback({ type: 'cancel' })}}},[_vm._v(" "+_vm._s(_vm.td("components.catalog.update_element.cta.cancel"))+" ")])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show_alert_box === true)?_c('div',{staticClass:"container-alert",staticStyle:{"height":"100%"}},[_c('alertBox',{attrs:{"msg":_vm.msg_alert,"callback":_vm.alertCallback,"delay":_vm.alert_delay}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }