<template>
  <div class="wrapper-content-column catalogue">
    <div class="wrapper-content-row">
      <div class="column-items">
        <h2>
          {{ td("views.catalog.catalog_ceo.elements") }}
          <span class="plus" @click.stop="clickHandler({ type: 'click-plus' })"
            >+</span
          >
        </h2>
        <div
          class="wrapper-content-row element-item"
          v-for="(item, index) in elements"
          :key="index"
          @click.stop="showItem(item)"
        >
          <div class="wrapper-content-column element-s">
            <img
              src="/assets/img/action_s.png"
              alt=""
              v-if="item.undeclarative"
            />
          </div>
          <div class="wrapper-content-column bonus">
            <h2>{{ item.name }}</h2>
            <p class="pts">{{ item.points }} pts</p>
            <p class="stars">{{ item.stars }} étoiles</p>
          </div>
          <div class="wrapper-content-column comment">
            <p v-if="item.comment != ''">
              {{ item.comment }}
            </p>
            <span v-if="item.comment != ''" @click.stop="showComment(item)"
              >+</span
            >
          </div>
          <div class="wrapper-content-column buttons">
            <span
              :class="['pen', { empty: isEmptyPen(item) }]"
              @click.stop="updateItem(item)"
            ></span>
            <span
              :class="['cross', { empty: isEmptyCross(item) }]"
              @click.stop="deleteItem(item)"
            ></span>
          </div>
          <div class="avatar" v-if="isEmptyPen(item)">
            <img :src="getItemAvatar(item)" />
          </div>
        </div>
        <div
          class="transparent-lightbox-bg"
          v-if="
            plus_cliqued == true ||
              update_cliqued == true ||
              show_cliqued == true
          "
        ></div>
        <div
          class="transparent-lightbox-bg"
          v-if="comment_cliqued != null"
        ></div>
        <div
          class="wrapper-content-column show-comment"
          v-if="comment_cliqued != null"
        >
          <p>
            {{ comment_cliqued.comment }}
          </p>
          <button class="ok-button" @click.stop="comment_cliqued = null">
            {{ td("views.catalog.catalog_ceo.cta.ok") }}
          </button>
        </div>
      </div>
      <newElement
        :callback="newElementCallback"
        :level="'ceo_manager'"
        :selectedUsers="selectedUsers"
        :creator_token="user.user_token"
        :group_token="getGroupToken()"
        :team_token="getTeamToken()"
        v-if="plus_cliqued == true"
      />
      <updateElement
        :callback="newElementCallback"
        :level="'ceo_manager'"
        :selectedUsers="selectedUsers"
        :elementParams="item_to_update"
        v-if="update_cliqued == true"
      />
      <showElement
        :callback="newElementCallback"
        :level="'ceo_manager'"
        :selectedUsers="selectedUsers"
        :elementParams="item_to_update"
        v-if="show_cliqued == true"
      />
    </div>

    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
    <transition name="fade">
      <div class="container-alert" v-if="show_confirm_box === true">
        <confirmBox
          :msg1="msg_confirm1"
          :msg2="msg_confirm2"
          :title="title_confirm"
          :callback="confirmCallback"
          :delay="confirm_delay"
        />
      </div>
    </transition>
    <div
      class="transparent-lightbox-bg"
      v-if="element_plus_cliqued == true"
    ></div>
    <selectUsers
      :callback="selectedUsersCallback"
      :level="'ceo_manager'"
      :group_token="getGroupToken()"
      :team_token="getTeamToken()"
      :old_selected_users="selectedUsers"
      v-if="element_plus_cliqued == true"
    />
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import confirmBox from "./../../components/alertBoxes/confBox";
import newElement from "./../../components/catalogue/newElement";
import updateElement from "./../../components/catalogue/updateElement";
import showElement from "./../../components/catalogue/showElement";
import selectUsers from "./../../components/catalogue/selectUsers";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "CatalogueCeo",
  components: {
    alertBox,
    newElement,
    selectUsers,
    confirmBox,
    updateElement,
    showElement
  },
  data: () => {
    return {
      td,
      comment_cliqued: null,
      plus_cliqued: false,
      update_cliqued: false,
      show_cliqued: false,
      element_plus_cliqued: false,
      item_to_delete: null,
      //alert box
      show_alert_box: false,
      msg_alert: td("views.catalog.catalog_ceo.msg_alert"),
      alert_delay: 3000,
      //confirm box
      show_confirm_box: false,
      msg_confirm1: td("views.catalog.catalog_ceo.msg_confirm1"),
      msg_confirm2: td("views.catalog.catalog_ceo.msg_confirm2"),
      title_confirm: td("views.catalog.catalog_ceo.title_confirm"),
      confirm_delay: 0,
      //
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      selectedUsers: [],
      new_user_options: [],
      new_user_selected: {},
      editable: "",
      elements: [],
      item_to_update: null
    };
  },
  methods: {
    getGroupToken() {
      if (this.user.group_token) {
        return this.user.group_token;
      }
      return "";
    },
    getTeamToken() {
      if (this.user.team_token) {
        return this.user.team_token;
      }
      return "";
    },
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "click-valider") {
        this.doDeleteItem(this.item_to_delete);
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
        this.item_to_delete = null;
      }
    },
    deleteItem(item) {
      if (this.isEmptyCross(item)) {
        return;
      }
      this.item_to_delete = item;
      this.show_confirm_box = true;
    },
    updateItem(item) {
      if (this.isEmptyPen(item)) {
        return;
      }
      this.selectedUsers = item.selected_users;
      this.item_to_update = item;
      this.update_cliqued = true;
    },
    showItem(item) {
      this.selectedUsers = item.selected_users;
      this.item_to_update = item;
      this.show_cliqued = true;
    },
    showComment(item) {
      this.comment_cliqued = item;
    },
    isEmptyCross(item) {
      if (this.user.user_token != item.owner_token) {
        return true;
      }
      if (item.level != "ceo_manager") {
        return true;
      }
      return false;
    },
    isEmptyPen(item) {
      if (this.user.user_token != item.owner_token) {
        return true;
      }

      if (item.level != "ceo_manager") {
        return true;
      }
      return false;
    },
    selectedUsersCallback(obj) {
      if (obj.type == "click-valider") {
        /*if (obj.users.length) {
          for (let i = 0; i < obj.users.length; i++) {
            for (let j = 0; j < 10; j++) {
              this.selectedUsers.push(obj.users[i]);
            }
          }
        }*/
        this.selectedUsers = obj.users;
      } else {
        //this.selectedUsers = [];
      }
      this.element_plus_cliqued = false;
    },
    newElementCallback(obj) {
      this.show_cliqued = false;

      if (obj.type == "click-plus") {
        this.element_plus_cliqued = true;
        return;
      }
      if (obj.type == "cancel") {
        this.plus_cliqued = false;
        this.update_cliqued = false;
        return;
      }
      if (obj.type == "element-saved") {
        this.element_plus_cliqued = false;
        this.plus_cliqued = false;
        this.update_cliqued = false;
        this.selectedUsers = [];
        this.getElements();
        return;
      }
    },
    clickHandler(obj) {
      if (obj.type == "click-plus") {
        this.selectedUsers = [];
        this.plus_cliqued = true;
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getItemAvatar(item) {
      if (item.owner_data.picture) {
        if (item.owner_data.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + item.owner_data.picture;
      }
      return "/assets/img/profil2.png";
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    getElements() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      /*let filters = {
        circle_token: this.circle.circle_token,
        owner_token: this.user.user_token,
        level:'ceo_manager'
      };*/

      let filters = {
        //user_tokens: [this.user.user_token],
        //group_token: this.new_user.group_token,
        user_token: this.user.user_token,
        level: "ceo_manager",
        circle_token: this.circle.circle_token,
        with_owner_data: true
      };
      let url = "/elements/of";

      let sendData = { credentials, filters };

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            context.elements = data.response.elements;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    doDeleteItem(item) {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        owner_token: this.user.user_token,
        element_token: item.element_token
      };

      let sendData = { credentials, filters };

      axios
        .post("/elements/delete", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            setTimeout(() => {
              context.getElements();
            }, 100);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    axios;
    sha256;

    this.getElements();
  }
};
</script>
