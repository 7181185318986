/* eslint-disable no-unused-vars */
import mock from "./../assets/models/traductions/traduction_mock_admin.json";
import { config } from "./../config/config";
import axios from "axios";

const useMock = config.useDefaultTraductionMock();

let url = null;

let languages = ["FR", "NL", "EN"];

let traductions = [];

export const getTraductionFiles = async () => {
  for (let i = 0; i < languages.length; i++) {
    const ln = languages[i];
    url = config.webURL() + "./traductions/traductions_admin_" + ln + ".json";
    console.log("=== url === traductionsHelpers.js === key: 238153 ===");
    console.log(url);
    console.log("=================================");
    try {
      let resp = await axios.get(url);
      traductions[ln] = resp.data;
    } catch (e) {
      e;
    }
  }
  console.log("=== traductions === traductionsHelpers.js === key: 427105 ===");
  console.log(traductions);
  console.log("=================================");
};

export const td = (key = "") => {
  let keyExplode = key.toString().split(".");
  let selectedTraduction = null;

  let lang = config.getUserLanguage()
    ? config.getUserLanguage()
    : config.getDefaultLanguage();

  selectedTraduction = traductions[lang];

  if (!useMock) {
    if (selectedTraduction) {
      if (selectedTraduction[key]) {
        return selectedTraduction[key];
      }
    }
    return keyExplode[keyExplode.length - 1] + "/!\\_1";
  }
  if (mock[key]) {
    return mock[key] + "/!\\_2";
  }
  return keyExplode[keyExplode.length - 1] + "/!\\_3";
};
