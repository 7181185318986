<template>
  <main>
    <div class="wrapper circles-like new-user">
      <section class="large-content top-bar">
        <div class="large-content-column">
          <h2>{{ td("views.teams.new_sale.select_account") }}</h2>
        </div>
        <div class="large-content-column col-3">
          <div class="bloc-search">
            <h2>{{ td("views.teams.new_sale.search") }}</h2>
            <div class="bottom-line">
              <v-select
                label="name"
                v-if="editable == 'search'"
                :placeholder="td('global.topcomponent.add_name')"
                :options="new_user_options"
                :components="{ OpenIndicator }"
                v-model="new_user_selected"
                @input="
                  editable = '';
                  setNewUserFromVSelect();
                "
              >
                <div slot="no-options">
                  {{ td("views.teams.new_sale.sorry_but") }}
                </div>
              </v-select>

              <p
                class="hoverable"
                :data-hover="td('global.topcomponent.add_name')"
                v-else
                @click="editable = 'search'"
              >
                {{ getSelectedUserName() }}
              </p>
              <img src="/assets/img/loupe.png" alt="" />
            </div>
          </div>
          <!-- <div class="bloc-buttons ">
              <button class="" @click="valideSelectedCircle">
                Valider
              </button>
              <button
                class="negatif"
                @click="
                  circle_selected = null;
                  circle = circle_init;
                "
              >
                Annuler
              </button>
            </div>-->
        </div>
      </section>
      <section class="large-content circle-body ">
        <div class="large-content-column">
          <div class="body-content set-position-relative">
            <div class="bloc-content-profil">
              <h2 v-if="profil_type == 'sale'">
                {{ td("views.teams.new_sale.new_sale") }}
              </h2>
            </div>
            <div class="bloc-content-profil new-user-image ">
              <img
                :src="getUserLogo()"
                alt=""
                class="header-img-profil"
                id="user_image"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
                v-if="$store.getters.adminValue > 8"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
            <div :class="{ 'overlay-block': new_user.user_token }"></div>
          </div>
          <div class="footer-content">
            <button
              class=""
              style="width:165px;height:42px"
              @click="saveNewUser"
              v-if="profil_type == 'sale'"
              v-html="td('views.teams.new_sale.add_sale')"
            ></button>
            <button
              class="negatif"
              style="width:115px;height:42px"
              @click="cancelHandler"
            >
              {{ td("views.teams.new_sale.cancel") }}
            </button>
          </div>
        </div>
        <div class="large-content-column col-3">
          <div class="wrapper">
            <div class="new-user-bottom">
              <div class="column-content-circle">
                <div class="bloc-content-circle   set-position-relative">
                  <h3>{{ td("views.teams.new_sale.gender") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.new_sale.gender.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="new_user.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.new_sale.gender.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="new_user.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.name") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="new_user.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_sale.name.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ new_user.lastname }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.email") }}</h3>
                  <input
                    v-if="editable == 'email'"
                    ref="email"
                    type="text"
                    v-model="new_user.email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_sale.email.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('email');
                      editable = 'email';
                    "
                  >
                    {{ new_user.email }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>
                    {{ td("views.teams.new_sale.objectif_period") }}
                  </h3>
                  <p class="">
                    {{ objectifs_period }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.new_sale.internal_id") }}</h3>
                  <input
                    v-if="editable == 'internal_id'"
                    ref="internal_id"
                    type="text"
                    v-model="new_user.internal_id"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('internal_id');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_sale.internal_id.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('internal_id');
                      editable = 'internal_id';
                    "
                  >
                    {{ new_user.internal_id }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div
                  class="bloc-content-circle set-position-relative"
                  v-if="!new_user.user_token"
                >
                  <h3>{{ td("views.teams.new_sale.send_password") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.new_sale.yes") }}
                      <input
                        type="radio"
                        id="mdp1"
                        value="1"
                        v-model="send_password"
                        name="mdp1"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.new_sale.no") }}
                      <input
                        type="radio"
                        id="mdp0"
                        value="0"
                        v-model="send_password"
                        name="mdp0"
                        @input="
                          Content('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="new_user.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_sale.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ new_user.firstname }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.confirm_email") }}</h3>
                  <input
                    v-if="editable == 'confirm_email'"
                    ref="confirm_email"
                    type="text"
                    v-model="new_user.confirm_email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('confirm_email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_sale.confirm_email.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('confirm_email');
                      editable = 'confirm_email';
                    "
                  >
                    {{ new_user.confirm_email }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>

                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.objectifs") }}</h3>
                  <input
                    v-if="editable == 'objectif'"
                    ref="objectif"
                    type="text"
                    v-model="new_user.objectif"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('objectif');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_sale.objectifs.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('objectif');
                      editable = 'objectif';
                    "
                  >
                    {{ new_user.objectif }}
                  </p>
                </div>
                <div class="bloc-content-circle" />
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                      style="padding-right: 15px"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.new_sale.language")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.new_sale.language")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        new_user.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="new_user.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_sale.phone.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ new_user.phone }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.main_function") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_main_function_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_main_function_clicked = !drop_down_main_function_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        main_function_selected ||
                          td("views.teams.new_sale.main_function")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_main_function_clicked = !drop_down_main_function_clicked
                      "
                    >
                      {{
                        main_function_selected ||
                          td("views.teams.new_sale.main_function")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in functions_options"
                      :key="key"
                      @click="
                        drop_down_main_function_clicked = false;
                        main_function_selected = option;
                        setPreviousContent('');
                      "
                    >
                      {{ option }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_sale.functions") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_other_function_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_other_function_clicked = !drop_down_other_function_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{ td("views.teams.new_sale.function")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_other_function_clicked = !drop_down_other_function_clicked
                      "
                    >
                      {{ td("views.teams.new_sale.function")}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in getOtherFunctions()"
                      :key="key"
                      @click="
                        setOtherFunction(option);
                        setPreviousContent('');
                      "
                      :class="{
                        'function-selected': getFunctionIsSelected(option)
                      }"
                    >
                      {{ option }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle" />
              </div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
import { sha256 } from "js-sha256";
import "vue-select/dist/vue-select.css";
import { td } from "../../traductions/traductionsHelpers";
import alertBox from "./../../components/alertBoxes/alertBox";

// eslint-disable-next-line no-unused-vars
import { valideUser } from "./../../assets/scripts/users";

export default {
  name: "NewSale",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      profilIsModified: false,
      team: {},
      group_token: "",
      team_token: "",
      profil_type: "",
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      //
      objectifs_period: "",
      //
      new_users_options: [],
      new_user_selected: {},
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      //
      drop_down_main_function_clicked: false,
      drop_down_other_function_clicked: false,
      functions_options: [],
      main_function_selected: "",
      //
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {}, //le user qui est connecté, pas celui que l'on crée
      new_user_init: {
        name: td("views.teams.new_sale.new_user_init.name"),
        firstname: td("views.teams.new_sale.new_user_init.firstname"),
        lastname: td("views.teams.new_sale.new_user_init.lastname"),
        email: td("views.teams.new_sale.new_user_init.email"),
        confirm_email: td("views.teams.new_sale.new_user_init.confirm_email"),
        function: td("views.teams.new_sale.new_user_init.function"),
        functions: [],
        phone: td("views.teams.new_sale.new_user_init.phone"),
        gender: td("views.teams.new_sale.new_user_init.gender"),
        language: td("views.teams.new_sale.new_user_init.language"),
        internal_id: td("views.teams.new_sale.new_user_init.internal_id"),
        objectif: td("views.teams.new_sale.objectifs.placeholder")
      },
      new_user: {},
      admin_level: "",
      previousContent: {
        new_user: {}
      }
    };
  },
  methods: {
    getOtherFunctions() {
      let tab = [];
      for (var i = 0; i < this.functions_options.length; i++) {
        if (this.functions_options[i] != this.main_function_selected) {
          tab.push(this.functions_options[i]);
        }
      }
      return tab;
    },
    getFunctionIsSelected(f) {
      if (f == this.main_function_selected) {
        return true;
      }
      return this.new_user.functions.indexOf(f) > -1;
    },
    setOtherFunction(f) {
      if (this.new_user.functions.indexOf(f) == -1) {
        this.new_user.functions.push(f);
      } else {
        if (f == this.main_function_selected) {
          return;
        }
        let index = this.new_user.functions.indexOf(f);
        this.new_user.functions.splice(index, 1);
      }
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      if (!this.new_user.objectif) {
        this.new_user.objectif = this.new_user_init.objectif;
      }
      if (!this.new_user.internal_id || this.new_user.internal_id == "") {
        this.new_user.internal_id = this.new_user_init.internal_id;
      }
      //delete this.new_user.function;
      this.new_user.functions = [];
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    cancelHandler() {
      this.file_01 = null;
      this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
      this.editable = "";
      this.send_password = 0;
      this.main_function_selected = this.functions_options[0];
      this.new_team_selected = {};
      this.is_new_team = false;
      this.new_team = this.new_team_init;
      this.drop_down_team_clicked = false;

      this.drop_down_main_function_clicked = false;
      this.new_objectifs_selected = {};
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();

      setTimeout(() => {
        this.$router.go(-1);
      }, 100);
    },
    setPreviousContent(key) {
      if (this.new_user.user_token) {
        // this.cancelHandler();
        // return;
      }
      this.previousContent.new_user[key] = this.new_user[key];
      if (this.new_user[key] == this.new_user_init[key]) {
        this.new_user[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_user[key].trim() == "") {
        this.new_user[key] = this.previousContent.new_user[key];
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("user_image");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return td("views.teams.new_sale.get_selected_username");
      }
      if (!this.new_user.user_token) {
        return td("views.teams.new_sale.get_selected_username");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return td("views.teams.new_sale.get_selected_username");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = { circle_token: this.$store.state.circleToken };
      let sendData = { credentials, filters };

      axios
        .post("/users/list", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /users/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });

            let reduced = Array.from(
              new Set(scratchArray.map(a => a.email))
            ).map(email => {
              return scratchArray.find(a => a.email === email);
            });

            context.new_user_options = reduced;
            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getTeam() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        team_token: this.team_token,
        circle_token: this.$store.state.circleToken
      };
      let sendData = { credentials, filters };

      axios
        .post("/teams/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /teams/by ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            /*let scratchArray = data.response.users.map((obj) => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });*/

            let team = data.response.teams[0];

            /*let reduced = Array.from(
              new Set(groups.map((a) => a.group_token))
            ).map((group_token) => {
              return groups.find((a) => a.group_token === group_token);
            });*/

            context.team = team;
            switch (team.objectifs_period) {
              case "year":
                context.objectifs_period = td("views.teams.new_sale.year");
                break;
              case "sem":
                context.objectifs_period = td("views.teams.new_sale.semester");
                break;
              case "quadri":
                context.objectifs_period = td("views.teams.new_sale.quadri");
                break;
              case "trim":
                context.objectifs_period = td("views.teams.new_sale.trim");
                break;
              case "month":
                context.objectifs_period = td("views.teams.new_sale.month");
                break;
              case "week":
                context.objectifs_period = td("views.teams.new_sale.week");
                break;

              default:
                break;
            }
            store.commit("authUser", authData);
            context.getUsers();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNewUser() {
      // eslint-disable-next-line no-unreachable
      this.drop_down_other_function_clicked = false;
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      this.new_user.function = this.main_function_selected;

      let err = valideUser(this.new_user, this.new_user_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }

      if (isNaN(this.new_user.objectif)) {
        this.msg_alert = "Vous devez entrer un objectif en points";
        this.show_alert_box = true;
        return;
      }

      let mf = this.main_function_selected;
      let index = this.new_user.functions.indexOf(mf);
      if (index > -1) {
        this.new_user.functions.splice(index, 1);
      }
      this.new_user.functions.unshift(mf);

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let url = "/users/new/sale";

      this.new_user.admin_level = "sale";
      this.new_user.circle_token = this.$store.state.circleToken;
      this.new_user.circle_name = this.$store.state.circleData.name;
      this.new_user.send_password = this.send_password;
      this.new_user.team_name = this.team.name;
      this.new_user.group_token = this.group_token;
      this.new_user.team_token = this.team_token;

      delete this.new_user.confirm_email;
      delete this.new_user.function;
      delete this.new_user.name;

      if (this.new_user.internal_id == this.new_user_init.internal_id) {
        this.new_user.internal_id = "";
      }

      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      const userJson = JSON.stringify(this.new_user);
      formData.append("user_txt", userJson);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      }

      axios
        .post(url, formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.new_user = data.response.user;
            store.commit("authUser", authData);
            router.go(-1);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    this.functions_options = this.$store.state.circleData.params.functions;
    this.main_function_selected = this.functions_options[0];
    //this.new_user.functions.push(this.main_function_selected);

    this.profil_type = this.$route.query.type;
    this.team_token = this.$route.query.team_token;
    this.group_token = this.$route.query.group_token;

    this.getTeam();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
