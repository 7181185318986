/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import store from "./../store";

let bu = null;
let wu = null;

const REMOTE_DEV = "remote_dev";
const PROD = "prod";
const LOCAL_DEV = "local_dev";

let forceEnvironmentKey = null;

const _getEnvironmentKey = () => {
  if (forceEnvironmentKey) {
    return forceEnvironmentKey;
  }
  return process.env.VUE_APP_ENVIRONMENT;
};

const _isInProdFromEnvironmentKey = () => {
  return _getEnvironmentKey() == "prod";
};

const _getUserLanguage = () => {
  let lang = "FR";

  if (process.env.VUE_APP_FORCE_LANGUAGE == "true") {
    return process.env.VUE_APP_FORCED_LANGUAGE.toUpperCase();
  }

  if (store.getters.userData != null) {
    try {
      lang = store.getters.userData.language.toUpperCase();
    } catch (e) {
      e;
    }
  }
  return lang.toUpperCase();
};

const _getWebURL = () => {
  if (wu == null) {
    let envKey = _getEnvironmentKey();
    wu =
      envKey == LOCAL_DEV
        ? process.env.VUE_APP_WEB_LOCAL_URL
        : envKey == REMOTE_DEV
        ? process.env.VUE_APP_WEB_DEV_URL
        : envKey == PROD
        ? process.env.VUE_APP_WEB_PROD_URL
        : null;
  }

  return wu;
};

const _getBaseURL = () => {
  if (bu === null) {
    bu =
      _getEnvironmentKey() == PROD
        ? process.env.VUE_APP_API_PROD_URL
        : _getEnvironmentKey() == LOCAL_DEV
        ? process.env.VUE_APP_API_DEV_LOCALE_URL
        : _getEnvironmentKey() == REMOTE_DEV
        ? process.env.VUE_APP_API_DEV_REMOTE_URL
        : "none";
  }

  return bu;
};

export const config = {
  baseURL: () => {
    return _getBaseURL();
  },

  webURL: () => {
    return _getWebURL();
  },

  isInProd: () => {
    return _isInProdFromEnvironmentKey();
  },

  getEnvironement: () => {
    return _getEnvironmentKey();
  },

  getUserLanguage: () => {
    return _getUserLanguage();
  },

  getDefaultLanguage: () => {
    return process.env.VUE_APP_DEFAULT_LANGUAGE;
  },

  useDefaultTraductionMock: () => {
    return process.env.VUE_APP_USE_DEFAULT_TRADUCTION_MOCK == "true"
      ? true
      : false;
  }
};
