<template>
  <main>
    <div class="wrapper circles-like">
      <section class="large-content top-bar">
        <div class="large-content-column">
          <h2>{{ td("views.teams.circles.title.company") }}</h2>
        </div>
        <div class="large-content-column col-3">
          <div class="bloc-search">
            <h2>{{ td("views.teams.circles.search") }}</h2>
            <div class="bottom-line">
              <v-select
                label="name"
                v-if="editable == 'search'"
                :placeholder="td('views.teams.circles.search.placeholder')"
                :options="circles_options"
                :components="{ OpenIndicator }"
                v-model="circle_selected"
                @input="
                  editable = '';
                  valideSelectedCircle();
                "
              >
                <div slot="no-options">
                  {{ td("views.teams.circles.sorry_but") }}
                </div>
              </v-select>

              <p
                class="hoverable"
                :data-hover="td('views.teams.circles.search.placeholder')"
                v-else
                @click="editable = 'search'"
              >
                {{ getCircleName() }}
              </p>
              <img src="/assets/img/loupe.png" alt="" />
            </div>
          </div>
          <!-- <div class="bloc-buttons ">
              <button class="" @click="valideSelectedCircle">
                Valider
              </button>
              <button
                class="negatif"
                @click="
                  circle_selected = null;
                  circle = circle_init;
                "
              >
                Annuler
              </button>
            </div>-->
        </div>
      </section>
      <section class="large-content circle-body">
        <div class="large-content-column">
          <div class="body-content">
            <div class="bloc-content-profil">
              <h2>{{ circle.name }}</h2>
            </div>
            <div class="bloc-content-profil">
              <img
                :src="getCircleLogo()"
                alt=""
                class="header-img-profil"
                id="circle_logo"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
          </div>
          <div class="footer-content">
            <button
              class="karamba"
              style="width:165px;height:42px"
              @click="saveCircle"
              v-if="!circle.circle_token"
              v-html="td('views.teams.circles.add_company')"
            ></button>
            <button
              class="karamba"
              style="width:165px;height:42px"
              @click="connectToCircle"
              v-else-if="is_changed == false"
            >
              {{ td("views.teams.circles.connect") }}
            </button>
            <button
              class="karamba"
              style="width:165px;height:42px"
              @click="updateCircle"
              v-else
              v-html="td('views.teams.circles.modify_company')"
            ></button>
            <button
              class="negatif karamba"
              style="width:105px;height:42px"
              @click="cancelHandler"
            >
              {{ td("views.teams.circles.cta.cancel") }}
            </button>
          </div>
        </div>
        <div class="large-content-column col-3">
          <div class="wrapper">
            <div class="top">
              <div class="bloc-content-circle">
                <h3>{{ td("views.teams.circles.company_name") }}</h3>
                <input
                  v-if="editable == 'name'"
                  ref="name"
                  type="text"
                  v-model="circle.name"
                  class="p"
                  @blur="
                    editable = '';
                    testEmptyContent('name');
                  "
                />
                <p
                  class="hoverable"
                  :data-hover="
                    td('views.teams.circles.company_name.placeholder')
                  "
                  v-else
                  @click="
                    setPreviousContent('name');
                    editable = 'name';
                  "
                >
                  {{ circle.name }}
                </p>
              </div>
              <div class="bloc-content-circle set-position-relative">
                <h3>{{ td("views.teams.circles.use_groups") }}</h3>
                <div class="container-radio">
                  <label class="container"
                    >{{ td("views.teams.circles.yes") }}
                    <input
                      type="radio"
                      id="oui"
                      value="1"
                      v-model="circle.params.use_groups"
                      name="oui"
                      @input="
                        setPreviousContent('');
                        editable = '';
                      "
                    />
                    <span class="checkmark karamba"></span>
                  </label>
                  <label class="container"
                    >{{ td("views.teams.circles.no") }}
                    <input
                      type="radio"
                      id="non"
                      value="0"
                      v-model="circle.params.use_groups"
                      name="non"
                      @input="
                        setPreviousContent('');
                        editable = '';
                      "
                    />
                    <span class="checkmark karamba"></span>
                  </label>
                </div>
                <div
                  :class="{ 'overlay-block': circle_selected != null }"
                ></div>
              </div>
            </div>
            <div class="bottom set-position-relative">
              <div class="column-content-circle">
                <div class="bloc-content-circle">
                  <h3 v-if="!ceo.user_token">Créer un directeur</h3>
                  <h3 v-else>{{ ceo.firstname + " " + ceo.lastname }}</h3>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.gender") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.circles.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="ceo.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark karamba"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.circles.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="ceo.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark karamba"></span>
                    </label>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.lastname") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="ceo.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.lastname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ ceo.lastname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.email") }}</h3>
                  <input
                    v-if="editable == 'email'"
                    ref="email"
                    type="text"
                    v-model="ceo.email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.email.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('email');
                      editable = 'email';
                    "
                  >
                    {{ ceo.email }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle"></div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.send_password") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.circles.yes") }}
                      <input
                        type="radio"
                        id="mdp1"
                        value="1"
                        v-model="send_password"
                        name="mdp1"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark karamba"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.circles.no") }}
                      <input
                        type="radio"
                        id="mdp0"
                        value="0"
                        v-model="send_password"
                        name="mdp0"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark karamba"></span>
                    </label>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="ceo.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ ceo.firstname }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.confirm_email") }}</h3>
                  <input
                    v-if="editable == 'confirm_email'"
                    ref="confirm_email"
                    type="text"
                    v-model="ceo.confirm_email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('confirm_email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.confirm_email.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('confirm_email');
                      editable = 'confirm_email';
                    "
                  >
                    {{ ceo.confirm_email }}
                  </p>
                </div>
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle"></div>
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{ languages_options[ceo.language] || "LANGUE"
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{ languages_options[ceo.language] || "LANGUE"
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        ceo.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.function") }}</h3>
                  <input
                    v-if="editable == 'function'"
                    ref="function"
                    type="text"
                    v-model="ceo.function"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('function');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.function.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('function');
                      editable = 'function';
                    "
                  >
                    {{ ceo.function }}
                  </p>
                </div>
                <div class="bloc-content-circle">
                  <h3>{{ td("views.teams.circles.ceo.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="ceo.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyCeoContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.circles.ceo.phone.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousCeoContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ ceo.phone }}
                  </p>
                </div>
              </div>
              <div :class="{ 'overlay-block': circle_selected != null }"></div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import alertBox from "./../../components/alertBoxes/alertBox";
import axios from "axios";
import "vue-select/dist/vue-select.css";
//import { sha256 } from "js-sha256";

// eslint-disable-next-line no-unused-vars
import { valideCircle, valideCeo } from "./../../assets/scripts/circles";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "Circles",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      is_changed: false,
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          elem.id = "toto";
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      circles_options: [],
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      circle_selected: null,
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {},
      circle_init: {
        name: "Nouvelle société",
        params: {
          use_groups: 1
        },
        circle_token: null
      },
      circle: {
        params: {
          use_groups: 1
        }
      },
      ceo_init: {
        firstname: td("views.teams.circles.ceo_init.firstname"),
        lastname: td("views.teams.circles.ceo_init.lastname"),
        email: td("views.teams.circles.ceo_init.email"),
        confirm_email: td("views.teams.circles.ceo_init.confirm_email"),
        function: td("views.teams.circles.ceo_init.function"),
        phone: td("views.teams.circles.ceo_init.phone"),
        gender: td("views.teams.circles.ceo_init.gender"),
        language: td("views.teams.circles.ceo_init.language")
      },
      ceo: {},
      admin_level: "",
      previousContent: {
        circle: {},
        ceo: {}
      }
    };
  },
  methods: {
    cancelHandler() {
      this.is_changed = false;
      this.file_01 = null;
      this.circle_selected = null;
      this.circle = JSON.parse(JSON.stringify(this.circle_init));
      this.ceo = JSON.parse(JSON.stringify(this.ceo_init));
      this.editable = "";
      var output = document.getElementById("circle_logo");
      output.src = this.getCircleLogo();
    },
    setPreviousContent(key) {
      if (this.circle.circle_token) {
        //this.cancelHandler();
        //return;
      }
      this.previousContent.circle[key] = this.circle[key];
      if (this.circle[key] == this.circle_init[key]) {
        this.circle[key] = "";
      }

      setTimeout(() => {
        this.$refs[key].focus();
      }, 250);
    },
    testEmptyContent(key) {
      if (this.circle[key].trim() == "") {
        this.circle[key] = this.previousContent.circle[key];
        return;
      }
      if (key == "name") {
        this.is_changed = true;
      }
    },
    setPreviousCeoContent(key) {
      if (this.circle.circle_token) {
        this.cancelHandler();
        return;
      }
      this.previousContent.ceo[key] = this.ceo[key];
      if (this.ceo[key] == this.ceo_init[key]) {
        this.ceo[key] = "";
      }
      //
      setTimeout(() => {
        this.$refs[key].focus();
      }, 250);
    },
    testEmptyCeoContent(key) {
      if (this.ceo[key].trim() == "") {
        this.ceo[key] = this.previousContent.ceo[key];
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("circle_logo");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
      this.is_changed = true;
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getCircleLogo() {
      if (!this.circle) {
        return "/assets/img/circle_empty_logo.png";
      }
      if (this.circle.pictures) {
        if (this.circle.pictures[0].trim() == "") {
          return "/assets/img/circle_empty_logo.png";
        }
        return axios.defaults.baseURL + "/images/" + this.circle.pictures[0];
      }
      return "/assets/img/circle_empty_logo.png";
    },
    valideSelectedCircle() {
      if (!this.circle_selected) {
        //this.circle_selected = {name:"marcel"}
        this.cancelHandler();
        return;
      }
      this.circle = this.circle_selected;
      this.getCEO();
    },
    connectToCircle() {
      this.$store.commit("circleToken", this.circle.circle_token);
      this.$store.commit("circleData", this.circle);
      this.$router.replace("/teams/list");
    },
    getCircleName() {
      if (this.circle_selected) {
        return this.circle_selected.name;
      }
      return td("views.teams.circles.ceo_init.set_company_name");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },
    getCEO() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token
      };

      let sendData = { credentials, filters };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("======== getCEO() { === /users/by ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.ceo = data.response.users[0];
            context.ceo.confirm_email = context.ceo.email;
            context.ceo.function = context.ceo.functions[0];
            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getCircles() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };

      axios
        .post("/circles/list", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /circles/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.circles_options = data.response.circles;
            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveCEO() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };

      this.ceo.admin_level = "ceo_manager";
      this.ceo.circle_token = this.circle.circle_token;
      this.ceo.circle_name = this.circle.name;
      this.ceo.functions = [this.ceo.function];
      delete this.ceo.confirm_email;
      delete this.ceo.function;

      sendData.user = this.ceo;

      axios
        .post("/users/new/ceo", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.ceo = data.response.user;
            store.commit("authUser", authData);
            store.commit("circleToken", context.circle.circle_token);
            store.commit("circleData", context.circle);
            router.replace("/");
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveCircle() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let sendData = { credentials };

      let filters = { email: this.ceo.email };

      sendData.filters = filters;

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            if (data.response.users.length) {
              context.msg_alert = td(
                "views.teams.circles.ceo_init.email_already_exists"
              );
              context.show_alert_box = true;
              return;
            } else {
              context.doSaveCircle();
            }
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
      // this.doSaveCircle();
    },
    doSaveCircle() {
      if (this.file_01 == null) {
        this.msg_alert = td("views.teams.circles.ceo_init.msg_alert1");
        this.show_alert_box = true;
        return;
      }

      if (valideCircle(this.circle, this.circle_init) == false) {
        this.msg_alert = td("views.teams.circles.ceo_init.msg_alert2");
        this.show_alert_box = true;
        return;
      }

      this.ceo.name = this.ceo.firstname + " " + this.ceo.lastname;

      let err = valideCeo(this.ceo, this.ceo_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }

      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      context;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      const circleJson = JSON.stringify(this.circle);
      formData.append("circle", circleJson);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      }

      axios
        .post("/circles/new", formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.circle = data.response.circle;
            context.file_01 = null;

            store.commit("authUser", authData);

            context.saveCEO();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    updateCircle() {
      /*if (this.file_01 == null) {
        this.msg_alert =
          "Vous devez complèter tous les champs du compte Directeur et insérer un logo avant de valider";
        this.show_alert_box = true;
        return;
      }

      if (valideCircle(this.circle, this.circle_init) == false) {
        this.msg_alert =
          "Vous devez complèter tous les champs du compte Directeur avant de valider";
        this.show_alert_box = true;
        return;
      }

      let err = valideCeo(this.ceo, this.ceo_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }*/

      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      context;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      const circleJson = JSON.stringify(this.circle);
      formData.append("circle", circleJson);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      }

      axios
        .post("/circles/update", formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.response.user
            };

            context.circle = data.response.circle;
            //context.file_01 = null;
            context.is_changed = false;

            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },

  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    this.circle = JSON.parse(JSON.stringify(this.circle_init));
    this.ceo = JSON.parse(JSON.stringify(this.ceo_init));

    this.getCircles();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
