<template>
  <router-view />
</template>
<script>
export default {
  created() {
    const data = JSON.parse(localStorage.getItem("authUser"));
    if (data) {
      this.$store.dispatch("refreshTokenId", { ...data });
    }
  }
};
</script>

<style></style>
