<template>
  <div class="container-alert">
    <div class="transparent-lightbox-bg"></div>
    <div class="bloc-alert">
      <p class="error-content">
        {{ msg }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "alertBox",
  props: {
    msg: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 2000,
    },
    callback: {
      type: Function,
      default: null,
    },
  },
  mounted() {
    if (this.callback) {
      
        
      
      
      
      let context = this;
      if (this.delay && !isNaN(this.delay)) {
        setTimeout(() => {
          context.callback({ type: "end_delay" });
        }, this.delay);
      }
    }
  },
};
</script>
<style scoped>
.container-alert {
  position: absolute;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  right: 0;
  border: solid 0px cyan;
}

.bloc-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 390px;
  height: 225px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}
.error-content {
  display: flex;
  font-family: "Lato", serif;
  font-size: 13px;
  line-height: 1.2rem;
  font-weight: 400;
  padding: 2rem;
  text-align: center;
}
</style>
