<template>
  <div class="body">
    <main class="wrapper">
      <section class="content content-login">
        <div class="content-left">
          <img src="/assets/img/fond_Karamba.png" alt="" class="fond" />
          <!-- <img src="/assets/img/persos_karamba.png" alt="" class="persos" />-->
          <img src="/assets/img/femme.png" alt="" class="persos-femme" />
          <img src="/assets/img/homme.png" alt="" class="persos-homme" />
          <img
            src="/assets/img/Logo_karamba_rose.png"
            alt=""
            class="logo-karamba"
          />
          <div class="confid">
            <h3>{{ td("views.reset.privacy") }}</h3>
            <p>{{ td("views.reset.copyright") }}</p>
          </div>
        </div>
      </section>
      <section class="content content-login">
        <form @submit.prevent="onSubmit">
          <h2>{{ td("views.reset.new_password") }}</h2>
          <p class="error" v-html="td('views.reset.reset_ok')"></p>
          <p class="error" v-html="td('views.reset.error1')"></p>
          <p class="error" v-html="td('views.reset.error2')"></p>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
/*import sha256 from "js-sha256";
import router from "./../router/index";*/
import axios from "axios";
import { td } from "../traductions/traductionsHelpers";
export default {
  name: "reset",
  components: {},
  data: () => {
    return {
      td,
      email: "",
      password: "",
      isPassword: true,
      loginError: false
    };
  },
  mounted() {
    this.resetPassword();
  },
  methods: {
    getIsPassword() {
      if (this.isPassword === true) {
        return "password";
      }
      return "text";
    },
    resetPassword() {
      this.loginError = false;
      let context = this;
      let store = this.$store;
      store;
      context;
      let sendData = {
        link_token: this.$route.params.link_token
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/users/resetpassword", sendData)
          .then(function(result) {
            const data = result.data;
            resolve(data);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}
.content,
main {
  margin: 0;
  padding: 0;
  background: #eee;
  border: solid #000;
  border-width: 0 0 0 1px;
  color: #011427;
}
form,
.bloc-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
main section:first-child {
  position: relative;
  max-width: 53%;
  min-width: 53%;
}
.content-login {
  display: flex;
  max-width: 2000px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background: #fff;
}
.content-login p {
  width: 100%;
}
.content-left {
  position: relative;
  display: flex;
  flex: 1;
  min-height: 100%;
  align-items: end;
  justify-content: center;
  overflow: hidden;
}
.content-left img.fond {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-left img.persos {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-left img.persos-femme {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-left img.persos-homme {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 65%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-left img.logo-karamba {
  position: absolute;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-left div.confid {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  color: white;
}
.content-left div.confid h3 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 1.3rem;
}
.content-left div.confid p {
  font-weight: 400;
  font-size: 0.8rem;
  margin: 1.2rem;
  text-align: center;
}
h2 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 6rem;
  background-color: #fff;
  color: #011427;
}
.bloc-form {
  margin: 0 0 1rem 0;
  width: 90%;
  border-radius: 10px;
}
.lost-password {
  position: relative;
  text-align: right;
  font-size: 0.8rem;
  margin-top: 0.6rem;
  cursor: pointer;
  font-weight: 100;
}
form {
  align-items: center;

  min-width: 340px;
  background: #fff;
}
.input-text,
label,
button {
  font-size: 15px;
  padding: 0.2rem;
  border: 0;
}
input {
  background-color: #fff !important;
}
.input-text-email {
  border: solid #000;
  border-width: 0 0 1px 0;
}
.patern {
  border: solid #000;
  border-width: 0 0 1px 0;
  position: relative;
}
.patern-see {
  position: absolute;
  font-size: 11px;
  bottom: 0.2rem;
  right: 0.1rem;
  cursor: pointer;
  color: #021428;
}
button {
  min-width: 70%;
  height: 2.5rem;
  border-radius: 2rem;
  background: var(--orange);
  font-size: 1rem;
  margin-top: 7rem;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #d4007a;
}
button:hover {
  background-color: #0b81ba;
}
</style>
